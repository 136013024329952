import {Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {CommonModule, isPlatformBrowser, isPlatformServer} from '@angular/common';
import { Meta } from '@angular/platform-browser';
import {ActivatedRoute} from "@angular/router";

import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";

import {MomentModule} from "ngx-moment";

import { environment as ENV } from '../../../environments/environment';

import { DataService } from "../../services/data/data.service";

import {InlineLoadingComponent} from "../../features/inline-loading/inline-loading.component";
import {PdftronComponent} from "../../features/pdftron/pdftron.component";

import {MapToIterablePipe} from "../../pipes/map-to-iterable/map-to-iterable.pipe";
import {SafeHtmlPipe} from "../../pipes/safe-html/safe-html.pipe";

@Component({
  selector: 'iv-summary-livefeed',
  standalone: true,
  imports: [CommonModule, InlineLoadingComponent, MapToIterablePipe, MatCheckboxModule, MatIconModule, MatTooltipModule, MomentModule, PdftronComponent, SafeHtmlPipe],
  templateUrl: './summary-livefeed.component.html',
  styleUrls: ['./summary-livefeed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SummaryLivefeedComponent implements OnInit {

  // private documentSubject: BehaviorSubject<any> = new BehaviorSubject<any>('');
  // public document$: Observable<any> = this.documentSubject.asObservable();

  data: any = null;

  readonly tagIds: Array<string> = ['category', 'subCategory', 'companies', 'stockTickers', 'countries', 'regions', 'basins', 'plays', 'intervals', 'keywords'];

  constructor(private route: ActivatedRoute,
              private dataService: DataService,
              @Inject(PLATFORM_ID) private platformId: object,
              private metaService: Meta,
  ) {
  }

  ngOnInit() {
    let id = this.route.snapshot.params['docId'];
    let obj = this.route.snapshot.data['data'];
    console.log(id);
    console.log(obj);
    if(obj.hasOwnProperty('result')) {
      this.data = obj.result;
      if (this.data) {
        this.combineTags(this.data);

        let url: string = `${ENV.SSR.prependPath}/summary/livefeed/${typeof (id) === 'string' ? id : id.toString()}`;
        console.log(url);

        // note: this will change any time this page is re-loaded (when not cached behind a cdn)
        // otherwise to use the same random image for each article we could do something like:
        // ie: id '82639' -> add ascii values (56+50+54+51+57)= 268 -> MOD(268/(# of images)) = imgs[1] [in range of 0..2]
        const imgs: string[] = ['SharedStory_1.png', 'SharedStory_3.png', 'SharedStory_4.png', 'SharedStory_5.png', 'SharedStory_6.png', 'SharedStory_7.png', 'SharedStory_8.png', 'SharedStory_9.png'];
        let imgndx: number = Math.floor(Math.random() * imgs.length);
        let img: string = `${ENV.SSR.prependPath}/assets/share/${imgs[imgndx]}`;

        // let desc: string = this.data.descriptionText;
        // let descSanitized: string = this.replaceUnicodeQuotes(desc);

        this.metaService.addTag({name: 'robots', content: 'index,follow'});
        this.metaService.addTag({property: 'og:title', content: this.data.title});
        // this.metaService.addTag({property: 'og:description', content: descSanitized });
        this.metaService.addTag({property: 'og:url', content: url});
        this.metaService.addTag({property: 'og:image', content: img});
        this.metaService.addTag({property: 'og:image:width', content: '1200'});
        this.metaService.addTag({property: 'og:image:height', content: '628'});
      }
    } else {
      this.data = obj;
    }
  }

  replaceUnicodeQuotes(input: string) {
    // Replace left and right single quotes
    var output: string = input.replace(/[\u2018\u2019]/g, '\u0027');

    // Replace left and right double quotes
    output = output.replace(/[\u201C\u201D]/g, '\u0022');

    // Replace single prime and double prime
    output = output.replace(/[\u2032\u2033]/g, '\u0027');

    // Replace left and right angle quotes
    output = output.replace(/[\u3008\u3009]/g, '\u0027');

    // use html entities
    output = output.replace(/"/g, '\u0022');
    output = output.replace(/'/g, '\u0027');

    // no workie, "\u0026apos;" still comes out as "&amp;apos;"
    // output = output.replace(/"/g, '\u0026quot;');
    // output = output.replace(/'/g, '\u0026apos;');

    return output;
  }

  //TODO: Fix at data source - requires changes to RSEG publisher and existing data
  private combineTags(data: any) {
    let tagFound = false;
    let tags: any = {
      solutionSet: {title: 'Solution Set', value: null},
      category: {title: 'Category', value: null},
      subCategory: {title: 'Sub Category', value: null},
      companies: {title: 'Companies', value: null},
      stockTickers: {title: 'Stock Tickers', value: null},
      countries: {title: 'Countries', value: null},
      regions: {title: 'Regions', value: null},
      basins: {title: 'Basins', value: null},
      plays: {title: 'Plays', value: null},
      intervals: {title: 'Intervals', value: null},
      keywords: {title: 'Keywords', value: null},
    };

    Object.keys(data).map(k => {
      if (this.tagIds.includes(k)) {
        tagFound = true;
        tags[k].value = data[k];
      }
    });
    data.tags = tagFound ? tags : {};
    return data.tags;
  }

}
