<div id="logobar">
  <div id="logo">
    <a href="https://app.enverus.com/gallery" target="_blank">
      <img src="/assets/enverus-logo-white.svg" /></a>
    <span> &nbsp; | &nbsp; <a href="/dashboard">Intelligence Vault</a></span>
  </div>
</div>
<div class="summary-livefeed">
  <article *ngIf="data && !data.error">
    <h1>{{data?.title}}</h1>
    <p class="date">
      {{data?.series}} | {{data?.publishedDate | amDateFormat:'LLL'}} - {{data.publishedDate | amTimeAgo}}
    <p id="authors" *ngIf="data?.primaryAuthor?.emailAddress || data?.supportingAuthors">
      by:
      <ng-container>
        {{data?.primaryAuthor?.displayName}}
        <ng-container *ngFor="let author of data?.supportingAuthors; let first = first; let last = last;">
          <span> | </span>{{author?.displayName}}
        </ng-container>
      </ng-container>
    </p>
    <section id="description" *ngIf="data?.description">
      <div [innerHTML]="data?.description | safeHtml: 'html'"></div>
      <div class="workbooks">
        <p>
          <a href="{{data?.coreWorkbookLink}}" class="core" *ngIf="data?.coreWorkbookLink" target="_blank">Enverus Core</a>
          <a href="{{data?.dataWorkbookLink}}" class="core-essentials" *ngIf="data?.dataWorkbookLink" target="_blank">Enverus Foundations</a>
          <a href="{{data?.nextWorkbookLink}}" class="next" *ngIf="data?.nextWorkbookLink" target="_blank">Enverus Next</a>
        </p>
      </div>
    </section>
    <section id="company-contact">
      <div class="brand-icon">
        <img src="/assets/enverus-logo-black.svg" />
      </div>
      <div class="content">
        <span>Enverus Intelligence</span><span class="trademark">®</span><span> | Publications offers valuable content like this, providing detailed summaries that cut
        through the noise and repetition found elsewhere.  Our experienced writers add insights and analysis,
        leveraging their deep understanding of the energy industry to help you stay ahead of the curve.  To find out
        more about this offering, contact <a href="mailto:businessdevelopment@enverus.com">businessdevelopment@enverus.com</a></span>
      </div>
    </section>
    <section id="tagging" *ngIf="data?.tags && (data?.tags | json) !='{}'">
      <h2>Tags</h2>
      <ng-container *ngFor="let tags of data?.tags | mapToIterable">
        <ng-container *ngIf="tags?.value?.value?.length > 0">
          <h3>{{tags?.value?.title}}</h3>
          <p>
            <ng-container *ngFor="let tag of tags?.value?.value; let last = last">{{tag}}<ng-container *ngIf="!last">, </ng-container></ng-container>
          </p>
        </ng-container>
      </ng-container>
    </section>
    <section id="disclosures" *ngIf="data?.disclosures" [innerHTML]="data?.disclosures | safeHtml: 'html'">
    </section>
    <p class="meta" *ngIf="data?.id && data?.key">
      {{data?.id}} | {{data?.key}}
    </p>
  </article>
  <article *ngIf="data && data.error">
    <h1>No matching filter url was found: {{data.error}}</h1>
  </article>
</div>
