import {Component, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location, LocationStrategy} from '@angular/common';

@Component({
  standalone: true,
  selector: 'iv-four-o-three-page',
  templateUrl: './four-o-three-page.component.html',
  styleUrls: ['./four-o-three-page.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FourOThreePageComponent {
  public attemptedUrl = '';
  currentDate = new Date().toUTCString();

  constructor(private router: Router, private location: Location, private locationStrategy: LocationStrategy) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.attemptedUrl = event.url;
      }
    });
  }
}
