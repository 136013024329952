import {Component, Input, ViewEncapsulation} from '@angular/core';
import {NgStyle} from '@angular/common';

@Component({
  standalone: true,
  selector: 'iv-inline-loading',
  templateUrl: './inline-loading.component.html',
  imports: [
    NgStyle
  ],
  styleUrls: ['./inline-loading.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class InlineLoadingComponent {
  @Input() text: string | undefined;
  @Input() loaderStyle: any = {};
  @Input() iconStyle: any = {};
  @Input() textStyle: any = {};
}
