import {AfterViewInit, Directive, ElementRef, Input, OnChanges} from '@angular/core';

@Directive({
  selector: '[ivReadOnly]',
  standalone: true
})
export class ReadOnlyDirective implements AfterViewInit, OnChanges {
  @Input('ivReadOnly') isReadOnly = false

  constructor(private elementRef: ElementRef) { }

  ngAfterViewInit(): void {
    this.updateReadOnly();
  }

  private updateReadOnly() {
    this.isReadOnly ?
      this.elementRef.nativeElement.setAttribute('readonly', true) :
      this.elementRef.nativeElement.removeAttribute('readonly');

  }

  ngOnChanges(): void {
    this.updateReadOnly();
  }
}
