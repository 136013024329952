import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';

@Component({
  selector: 'iv-keyword-search-help',
  standalone: true,
    imports: [CommonModule, FormsModule, MatButtonModule, MatInputModule, ReactiveFormsModule],
  templateUrl: './keyword-search-help.component.html',
  styleUrls: ['./keyword-search-help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class KeywordSearchHelpComponent {

}
