import { Component, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

import { interval, Observable, Subscription } from "rxjs";

import { SearchService } from "../../services/search/search.service";

import { LivefeedViewComponent } from "../../views/livefeed-view/livefeed-view.component";
import { ReportsViewComponent } from "../../views/reports-view/reports-view.component";

@Component({
  selector: 'iv-new-research',
  standalone: true,
  imports: [CommonModule, ReportsViewComponent, LivefeedViewComponent],
  templateUrl: './new-research.component.html',
  styleUrls: ['./new-research.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewResearchComponent {
  @Input() store: any;

  // updateStore: Observable<number> = interval(60000);
  // updateSubscription: Subscription = Subscription.EMPTY;

  constructor(
    public searchService: SearchService,
  ) {
  }

  ngOnInit(): void {
    // this.updateSubscription = this.updateStore.subscribe( (val: number) => {
    //   this.searchService.search(this.store.collection);
    // });
  }

  ngOnDestroy() {
    // this.updateSubscription.unsubscribe();
  }

}
