<div class="iv-hallucination">
  <fieldset>
    <legend>Choose a conversation style</legend>
    <div class="iv-hallucination__options-list">
      <ul class="iv-hallucination__options" role="radiogroup">
        <li class="iv-hallucination__option">
          <button role="radio" class="tone-creative"
                  [ngClass]="{'creative' : conversationStyle.value === CONVERSATION_STYLE.CREATIVE}"
                  (click)="allowedFeature && conversationStyle.value !== CONVERSATION_STYLE.CREATIVE && selectOption(CONVERSATION_STYLE.CREATIVE)">
            <span class="iv-hallucination__label">Creative</span>
          </button>
        </li>
        <li class="iv-hallucination__option">
          <button role="radio" class="tone-balanced"
                  [ngClass]="{'balanced' : conversationStyle.value === CONVERSATION_STYLE.BALANCED}"
                  (click)="allowedFeature && conversationStyle.value !== CONVERSATION_STYLE.BALANCED && selectOption(CONVERSATION_STYLE.BALANCED)">
            <span class="iv-hallucination__label">Balanced</span>
          </button>
        </li>
        <li class="iv-hallucination__option">
          <button role="radio" class="tone-precise"
                  [ngClass]="{'precise' : conversationStyle.value === CONVERSATION_STYLE.PRECISE}"
                  (click)="allowedFeature && conversationStyle.value !== CONVERSATION_STYLE.PRECISE && selectOption(CONVERSATION_STYLE.PRECISE)">
            <span class="iv-hallucination__label">Precise</span>
          </button>
        </li>
      </ul>
    </div>
  </fieldset>
</div>
