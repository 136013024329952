// livefeed-nav cannot inject the search.service instance/data from reports-nav as it is not in the hierarchy...
// therefore we have this communication service which livefeed-nav can subscribe to (for active facets, etc...)

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportsNavService {

  private reportsNavAzssDataSubject = new BehaviorSubject<any>(null);
  reportsNavAzssData$ = this.reportsNavAzssDataSubject.asObservable();

  constructor() { }

  setReportsNavAzssData(data: any) {
    this.reportsNavAzssDataSubject.next(data);
  }

}
