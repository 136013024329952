// ----------------------------------------------------------------------------
//  This service is for data sharing / communication between other services
//    & components.
//
//  *** It MUST not have any other project dependencies (other than constants.ts).
//      Observables and getters/setters ONLY. ***
// ----------------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {Observable, BehaviorSubject, ReplaySubject, Subject} from 'rxjs';
import { take } from "rxjs/operators";

// import { WebSocket } from "partysocket";
// import ReconnectingWebSocket from '../../features/chatbot/reconnecting-websocket';

import { Constants } from "../../constants/constants";
const reportsStores: string[] = Constants.ReportsStores;
const livefeedStores: string[] = Constants.LivefeedStores;
const chatbotFiltersStores: string[] = Constants.ChatbotFiltersStores;

import { ActiveFilter, createEmptyActiveFilter } from "../../interface/activefilter";
import {CONVERSATION_STYLE} from "../../features/chatbot/hallucination/conversation-style";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  // --------------------------------------------------------------------------
  // REPORTS PANE
  // --------------------------------------------------------------------------

  // tracks the active tab / collection for Reports pane
  private reportsTabSubject: BehaviorSubject<string> = new BehaviorSubject<string>(reportsStores[0]);
  public reportsTab$: Observable<string> = this.reportsTabSubject.asObservable();

  // tracks the active filter for Reports pane
  private reportsActiveFilterSubject: BehaviorSubject<ActiveFilter> = new BehaviorSubject<ActiveFilter>(
    createEmptyActiveFilter()
  );
  public reportsActiveFilter$: Observable<ActiveFilter> = this.reportsActiveFilterSubject.asObservable();

  // tracks the default filter id for Reports pane
  private reportsDefaultFilterIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public reportsDefaultFilterId$: Observable<number> = this.reportsDefaultFilterIdSubject.asObservable();

  // tracks whether we are loading a saved filter
  private reportsLoadingSavedFilterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public reportsLoadingSavedFilter$: Observable<boolean> = this.reportsLoadingSavedFilterSubject.asObservable();

  // serves dual purpose:
  // on startup: tracks urlkey of saved url that was passed on uri (ie: ?rf=1234567890ABCD)
  // on facets change: tracks the last generated urlkey ([Get Filters Url] pressed)
  private reportsSavedUrlFilterSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  public reportsSavedUrlFilter$: Observable<string> = this.reportsSavedUrlFilterSubject.asObservable();

  private reportsLoadUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public reportsLoadUrl$: Observable<string> = this.reportsLoadUrlSubject.asObservable();

  // pretty text for facets (plain text w linefeeds for use with <title>)
  private reportsFacetsPrettyTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public reportsFacetsPrettyText$: Observable<string> = this.reportsFacetsPrettyTextSubject.asObservable();

  // pretty text for angular material chip (one liner, no keywords, etc...)
  private reportsChipPrettyTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public reportsChipPrettyText$: Observable<string> = this.reportsChipPrettyTextSubject.asObservable();
  // private reportsChipPrettyTextSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  // public reportsChipPrettyText$: Observable<string> = this.reportsChipPrettyTextSubject.asObservable();

  private reportsBlockNextSuggestSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  // --------------------------------------------------------------------------
  // LIVEFEED PANE
  // --------------------------------------------------------------------------

  // tracks the active tab / collection for LiveFeed pane
  private livefeedTabSubject: BehaviorSubject<string> = new BehaviorSubject<string>(livefeedStores[0]);
  public livefeedTab$: Observable<string> = this.livefeedTabSubject.asObservable();

  // tracks the active filter for LiveFeed pane
  private livefeedActiveFilterSubject: BehaviorSubject<ActiveFilter> = new BehaviorSubject<ActiveFilter>(
    createEmptyActiveFilter()
  );
  public livefeedActiveFilter$: Observable<ActiveFilter> = this.livefeedActiveFilterSubject.asObservable();

  // tracks the default filter id for LiveFeed pane
  private livefeedDefaultFilterIdSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public livefeedDefaultFilterId$: Observable<number> = this.livefeedDefaultFilterIdSubject.asObservable();

  // tracks whether we are loading a saved filter
  private livefeedLoadingSavedFilterSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public livefeedLoadingSavedFilter$: Observable<boolean> = this.livefeedLoadingSavedFilterSubject.asObservable();

  // serves dual purpose:
  // on startup: tracks urlkey of saved url that was passed on uri (ie: ?lff=1234567890ABCD)
  // on facets change: tracks the last generated urlkey ([Get Filters Url] pressed)
  private livefeedSavedUrlFilterSubject: ReplaySubject<string> = new ReplaySubject<string>(1);
  public livefeedSavedUrlFilter$: Observable<string> = this.livefeedSavedUrlFilterSubject.asObservable();

  private livefeedLoadUrlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public livefeedLoadUrl$: Observable<string> = this.livefeedLoadUrlSubject.asObservable();

  // pretty text for facets (plain text w linefeeds for use with <title>)
  private livefeedFacetsPrettyTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public livefeedFacetsPrettyText$: Observable<string> = this.livefeedFacetsPrettyTextSubject.asObservable();

  // pretty text for angular material chip (one liner, no keywords, etc...)
  private livefeedChipPrettyTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public livefeedChipPrettyText$: Observable<string> = this.livefeedChipPrettyTextSubject.asObservable();

  private livefeedBlockNextSuggestSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  // --------------------------------------------------------------------------
  // CHATBOT "PANE"
  // --------------------------------------------------------------------------

  // pretty text for facets (plain text w linefeeds for use with <title>)
  private chatbotFacetsPrettyTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public chatbotFacetsPrettyText$: Observable<string> = this.chatbotFacetsPrettyTextSubject.asObservable();

  // pretty text for angular material chip (one liner, no keywords, etc...)
  private chatbotChipPrettyTextSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public chatbotChipPrettyText$: Observable<string> = this.chatbotChipPrettyTextSubject.asObservable();

  private chatbotMessageEventSubject: Subject<MessageEvent> = new Subject<MessageEvent>();
  public chatbotMessageEvent$: Observable<MessageEvent> = this.chatbotMessageEventSubject.asObservable();

  private chatbotWebsocketClosedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public chatbotWebsocketClosed$: Observable<boolean> = this.chatbotWebsocketClosedSubject.asObservable();

  private chatbotWebsocketStatusSubject: BehaviorSubject<string> = new BehaviorSubject<string>('invalid');
  public chatbotWebsocketStatus$: Observable<string> = this.chatbotWebsocketStatusSubject.asObservable();

  // stores the chatbot history metadata
  private chatbotHistorySubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public chatbotHistory$: Observable<any> = this.chatbotHistorySubject.asObservable();

  // mechanism to open or close the chatbot history pane
  private chatbotHistoryOpenSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public chatbotHistoryOpen$: Observable<boolean> = this.chatbotHistoryOpenSubject.asObservable();

  // stores the chatbot conversation details
  private chatbotConversationSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public chatbotConversation$: Observable<any> = this.chatbotConversationSubject.asObservable();

  // tracks whether a chatbot conversation is in the process of loading
  private chatbotConversationLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public chatbotConversationLoading$: Observable<boolean> = this.chatbotConversationLoadingSubject.asObservable();

  // mechanism to clear the current chatbot conversation
  private chatbotClearSelectedSubject: Subject<boolean> = new Subject<boolean>();
  public chatbotClearSelected$: Observable<boolean> = this.chatbotClearSelectedSubject.asObservable();

  // mechanism to set the current chatbot conversation style
  private chatbotConversationStyleSubject: BehaviorSubject<string> = new BehaviorSubject<string>(CONVERSATION_STYLE.PRECISE);
  public chatbotConversationStyle$: Observable<string> = this.chatbotConversationStyleSubject.asObservable();

  // mechanism to load more chatbot history
  private chatbotLoadMoreSubject: Subject<boolean> = new Subject<boolean>();
  public chatbotLoadMore$: Observable<boolean> = this.chatbotLoadMoreSubject.asObservable();

  // tracks whether chatbot history is in the process of loading
  private chatbotHistoryLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public chatbotHistoryLoading$: Observable<boolean> = this.chatbotHistoryLoadingSubject.asObservable();

  // tracks whether chatbot is in maintenance mode
  private chatbotMaintenanceModeSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public chatbotMaintenanceMode$: Observable<string> = this.chatbotMaintenanceModeSubject.asObservable();

  // mechanism to load a chatbot query from a url
  private chatbotLoadQuerySubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public chatbotLoadQuery$: Observable<string> = this.chatbotLoadQuerySubject.asObservable();

  private ws: WebSocket | null = null;

  constructor(private route: ActivatedRoute,
  ) {

    if(typeof(this.route.snapshot.queryParams[Constants.reportsSavedFiltersUrl]) !== 'undefined') {
      this.updateReportsSavedUrlFilter(this.route.snapshot.queryParams[Constants.reportsSavedFiltersUrl]);
    }

    if(typeof(this.route.snapshot.queryParams[Constants.livefeedSavedFiltersUrl]) !== 'undefined') {
      this.updateLivefeedSavedUrlFilter(this.route.snapshot.queryParams[Constants.livefeedSavedFiltersUrl]);
    }

    this.updateReportsTab(Constants.ReportsStores[0]);

  }


  // --------------------------------------------------------------------------
  // REPORTS PANE
  // --------------------------------------------------------------------------

  // set the active tab / collection for Reports pane
  updateReportsTab(tab: string) {
    this.reportsTabSubject.next(tab);
  }

  updateReportsActiveFilter(af: ActiveFilter) {
    this.reportsActiveFilterSubject.next(af);
  }

  updateReportsDefaultFilterId(id: number) {
    this.reportsDefaultFilterIdSubject.next(id);
  }

  updateReportsLoadingSavedFilter(state: boolean) {
    this.reportsLoadingSavedFilterSubject.next(state);
  }

  updateReportsSavedUrlFilter(rf: string) {
    this.reportsSavedUrlFilterSubject.next(rf);
  }

  updateReportsLoadUrl(urlkey: string) {
    this.reportsLoadUrlSubject.next(urlkey);
  }

  updateReportsFacetsPrettyText(text: string) {
    this.reportsFacetsPrettyTextSubject.next(text);
  }

  updateReportsChipPrettyText(text: string) {
    this.reportsChipPrettyTextSubject.next(text);
  }

  dumpReportsActiveFilter() {
    this.reportsActiveFilter$.pipe(
      take(1),
    ).subscribe((activeFilter: ActiveFilter) => {
    });
  }

  updateReportsBlockNextSuggest(flag: boolean) {
    // console.log(`%c shared.service::updateReportsBlockNextSuggest(${flag})`, 'background: red; color: white');
    this.reportsBlockNextSuggestSubject.next(flag);
  }

  getReportsBlockNextSuggest(): any {
    // console.log(`%c shared.service::getReportsBlockNextSuggest(${this.reportsBlockNextSuggestSubject.getValue()})`, 'background: red; color: white');
    return this.reportsBlockNextSuggestSubject.getValue();
  }


  // --------------------------------------------------------------------------
  // LIVEFEED PANE
  // --------------------------------------------------------------------------

  // set the active tab / collection for Livefeed pane
  updateLivefeedTab(tab: string) {
    this.livefeedTabSubject.next(tab);
  }

  updateLivefeedActiveFilter(af: ActiveFilter) {
    this.livefeedActiveFilterSubject.next(af);
  }

  updateLivefeedDefaultFilterId(id: number) {
    this.livefeedDefaultFilterIdSubject.next(id);
  }

  updateLivefeedLoadingSavedFilter(state: boolean) {
    this.livefeedLoadingSavedFilterSubject.next(state);
  }

  updateLivefeedSavedUrlFilter(lff: string) {
    this.livefeedSavedUrlFilterSubject.next(lff);
  }

  updateLivefeedLoadUrl(urlkey: string) {
    this.livefeedLoadUrlSubject.next(urlkey);
  }

  updateLivefeedFacetsPrettyText(text: string) {
    this.livefeedFacetsPrettyTextSubject.next(text);
  }

  updateLivefeedChipPrettyText(text: string) {
    this.livefeedChipPrettyTextSubject.next(text);
  }

  updateLivefeedBlockNextSuggest(flag: boolean) {
    // console.log(`%c shared.service::updateLivefeedBlockNextSuggest(${flag})`, 'background: red; color: white');
    this.livefeedBlockNextSuggestSubject.next(flag);
  }

  getLivefeedBlockNextSuggest(): any {
    // console.log(`%c shared.service::getLivefeedBlockNextSuggest(${this.livefeedBlockNextSuggestSubject.getValue()})`, 'background: red; color: white');
    return this.livefeedBlockNextSuggestSubject.getValue();
  }


  // --------------------------------------------------------------------------
  // CHATBOT "PANE"
  // --------------------------------------------------------------------------

  updateChatbotFacetsPrettyText(text: string) {
    this.chatbotFacetsPrettyTextSubject.next(text);
  }

  updateChatbotChipPrettyText(text: string) {
    this.chatbotChipPrettyTextSubject.next(text);
  }

  // updateChatbotMessageEvent(messageEvent: Subject<MessageEvent>) {
  //   this.chatbotMessageEventSubject.next(messageEvent);
  // }

  // updateChatbotMessageEvent(messageEvent: MessageEvent) {
  //   this.chatbotMessageEventSubject.getValue().next(messageEvent);
  // }

  updateChatbotMessageEvent(messageEvent: MessageEvent) {
    this.chatbotMessageEventSubject.next(messageEvent);
  }

  // clearChatbotMessages() {
  //   this.chatbotMessageEventSubject.getValue().next(null);
  // }

  isChatbotMessageEventInitialized(): boolean {
    return ( !this.chatbotWebsocketClosedSubject.getValue() );
  }

  updateChatbotWebsocketClosed(isClosed: boolean) {
    this.chatbotWebsocketClosedSubject.next(isClosed);
  }

  updateChatbotStatus(status: string) {
    this.chatbotWebsocketStatusSubject.next(status);
  }

  public setWebSocket(ws: WebSocket): void {
    this.ws = ws;
  }

  public getWebSocket(): WebSocket | null {
    return this.ws;
  }

  updateChatbotHistory(history: any) {
    this.chatbotHistorySubject.next(history);
  }

  getChatbotHistory(): any {
    return this.chatbotHistorySubject.getValue();
  }

  updateChatbotHistoryOpen(isOpen: boolean) {
    this.chatbotHistoryOpenSubject.next(isOpen);
  }

  getChatbotHistoryOpenState(): boolean {
    return this.chatbotHistoryOpenSubject.getValue();
  }

  updateChatbotConversation(conversation: any) {
    if(conversation !== this.chatbotConversationSubject.getValue()) {
      this.chatbotConversationSubject.next(conversation);
    }
  }

  updateChatbotConversationLoading(status: boolean) {
    this.chatbotConversationLoadingSubject.next(status);
  }

  clearChatbotSelectedConversation() {
    this.chatbotClearSelectedSubject.next(true);
  }

  updateChatbotConversationStyle(style: string) {
    this.chatbotConversationStyleSubject.next(style);
  }

  loadMoreChatbotHistory(status: boolean) {
    this.chatbotLoadMoreSubject.next(status);
  }

  updateChatbotHistoryLoading(status: boolean) {
    this.chatbotHistoryLoadingSubject.next(status);
  }

  updateChatbotMaintenanceMode(message: string) {
    this.chatbotMaintenanceModeSubject.next(message);
  }

  getChatbotMaintenanceMode(): string {
    return this.chatbotMaintenanceModeSubject.getValue();
  }

  updateChatbotLoadQuery(message: string) {
    this.chatbotLoadQuerySubject.next(message);
  }


  // --------------------------------------------------------------------------
  // COMMON
  // --------------------------------------------------------------------------

  clearActiveFilter(storeKey: string, storeFacets: any = null): void {

    if( reportsStores.indexOf(storeKey) >= 0 ) {

      this.reportsActiveFilter$.pipe(
        take(1),
      ).subscribe((activeFilter: ActiveFilter) => {

        let initActiveFilter: ActiveFilter = createEmptyActiveFilter();
        if(storeFacets) {
          initActiveFilter.facetsDiff = storeFacets;
        }
        this.updateReportsActiveFilter(initActiveFilter);

      });

    } else {

      if (livefeedStores.indexOf(storeKey) >= 0) {

        this.livefeedActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {

          let initActiveFilter: ActiveFilter = createEmptyActiveFilter();
          if(storeFacets) {
            initActiveFilter.facetsDiff = storeFacets;
          }
          this.updateLivefeedActiveFilter(initActiveFilter);

        });

      }

    }

  }

  updateActiveFilter(storeKey: string, activeFilter: ActiveFilter): void {
    if( reportsStores.indexOf(storeKey) >= 0 ) {
        this.updateReportsActiveFilter(activeFilter);

    } else {
      if (livefeedStores.indexOf(storeKey) >= 0) {
          this.updateLivefeedActiveFilter(activeFilter);
      }

    }
  }

  async updateActiveFilterParameter(storeKey: string, parameterKey: string, parameterVal: any): Promise<void> {

    // console.log(`%c search.service::updateActiveFilterParameter(${storeKey}, ${parameterKey}, ${parameterVal})`, 'background: red; color: white');

    return new Promise((resolve, reject) => {

      if (reportsStores.indexOf(storeKey) >= 0) {

        this.reportsActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {
          if (activeFilter.hasOwnProperty(parameterKey)) {
            if(activeFilter[parameterKey] !== parameterVal) {
              activeFilter[parameterKey] = parameterVal;
              this.updateReportsActiveFilter(activeFilter);
            }
          }
          resolve();
        });

      } else {

        if (livefeedStores.indexOf(storeKey) >= 0) {

          this.livefeedActiveFilter$.pipe(
            take(1),
          ).subscribe((activeFilter: ActiveFilter) => {
            if (activeFilter.hasOwnProperty(parameterKey)) {
              if(activeFilter[parameterKey] !== parameterVal) {
                activeFilter[parameterKey] = parameterVal;
                this.updateLivefeedActiveFilter(activeFilter);
              }
            }
            resolve();
          });

        } else {

          if (chatbotFiltersStores.indexOf(storeKey) >= 0) {

            // console.log('updateActiveFilterParameter() chatbotFilters');

            this.livefeedActiveFilter$.pipe(
              take(1),
            ).subscribe((activeFilter: ActiveFilter) => {
              if (activeFilter.hasOwnProperty(parameterKey)) {
                if(activeFilter[parameterKey] !== parameterVal) {
                  activeFilter[parameterKey] = parameterVal;
                  this.updateLivefeedActiveFilter(activeFilter);
                }
              }
              resolve();
            });

          }

        }

      }

    });
  }

  async updateActiveFilterFacetsDiffParameter(storeKey: string, facetsDiffKey: string, parameterKey: string, parameterVal: any): Promise<void> {
    return new Promise((resolve, reject) => {

      if (reportsStores.indexOf(storeKey) >= 0) {

        this.reportsActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {
          const rec = activeFilter.facetsDiff['facets'].find(
            (facet: any) => facet.key === facetsDiffKey
          );
          if (rec) {
            if (rec.hasOwnProperty(parameterKey)) {
              rec[parameterKey] = parameterVal;
              this.updateReportsActiveFilter(activeFilter);
            }
          }
          resolve();
        });

      } else {
        if (livefeedStores.indexOf(storeKey) >= 0) {

          this.livefeedActiveFilter$.pipe(
            take(1),
          ).subscribe((activeFilter: ActiveFilter) => {
            const rec = activeFilter.facetsDiff['facets'].find(
              (facet: any) => facet.key === facetsDiffKey
            );
            if (rec) {
              if (rec.hasOwnProperty(parameterKey)) {
                rec[parameterKey] = parameterVal;
                this.updateReportsActiveFilter(activeFilter);
              }
            }
            resolve();
          });

        }

      }

    });
  }

  isActiveFilterInited(storeKey: string): boolean {
    let isinit: boolean = false;

    if( reportsStores.indexOf(storeKey) >= 0 ) {

      this.reportsActiveFilter$.pipe(
        take(1),
      ).subscribe((activeFilter: ActiveFilter) => {
        isinit = Object.keys(activeFilter.facetsDiff).length > 0;
      });

    } else {

      if (livefeedStores.indexOf(storeKey) >= 0) {

        this.livefeedActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {
          isinit = Object.keys(activeFilter.facetsDiff).length > 0;
        });

      }

    }

    return(isinit);
  }

  getActiveFilterParameter(storeKey: string, parameter: string): any {
    let result: any = null;

    if( reportsStores.indexOf(storeKey) >= 0 ) {

      this.reportsActiveFilter$.pipe(
        take(1),
      ).subscribe((activeFilter: ActiveFilter) => {
        if(activeFilter.hasOwnProperty(parameter)) {
          result = activeFilter[parameter];
        }
      });

    } else {

      if (livefeedStores.indexOf(storeKey) >= 0) {

        this.livefeedActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {
          if(activeFilter.hasOwnProperty(parameter)) {
            result = activeFilter[parameter];
          }
        });

      }

    }

    return(result);
  }

  testActiveFilterModified(storeKey: string, storeFacets: any): void {

    if( (reportsStores.indexOf(storeKey) >= 0) && (storeKey !== 'ReportsNew') ) {

        this.reportsActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {

          // facet counts can change and must not be considered in comparison
          let storeFacetsCleaned = JSON.parse( JSON.stringify( storeFacets ) );
          storeFacetsCleaned.facets.forEach(function (facet: any) {
            facet.values.forEach(function (facetVal: any) {
              if(facetVal.hasOwnProperty('count')) {
                delete facetVal.count;
              }
            });
          });
          let facetsDiffCleaned = JSON.parse( JSON.stringify( activeFilter.facetsDiff ) );
          facetsDiffCleaned.facets.forEach(function (facet: any) {
            facet.values.forEach(function (facetVal: any) {
              if(facetVal.hasOwnProperty('count')) {
                delete facetVal.count;
              }
            });
          });

          let modified: boolean =
            (activeFilter.title !== activeFilter.titleOriginal) ||
            (activeFilter.q !== activeFilter.qOriginal) ||
            (JSON.stringify(storeFacetsCleaned) !== JSON.stringify(facetsDiffCleaned));

          this.updateActiveFilterParameter(storeKey, 'filterModified', modified);
        });

    } else {

      if ( (livefeedStores.indexOf(storeKey) >= 0) && (storeKey !== 'LiveFeedNew') ) {

        this.livefeedActiveFilter$.pipe(
          take(1),
        ).subscribe((activeFilter: ActiveFilter) => {

          // facet counts can change and must not be considered in comparison
          let storeFacetsCleaned = JSON.parse( JSON.stringify( storeFacets ) );
          storeFacetsCleaned.facets.forEach(function (facet: any) {
            facet.values.forEach(function (facetVal: any) {
              if(facetVal.hasOwnProperty('count')) {
                delete facetVal.count;
              }
            });
          });
          let facetsDiffCleaned = JSON.parse( JSON.stringify( activeFilter.facetsDiff ) );
          facetsDiffCleaned.facets.forEach(function (facet: any) {
            facet.values.forEach(function (facetVal: any) {
              if(facetVal.hasOwnProperty('count')) {
                delete facetVal.count;
              }
            });
          });

          let modified: boolean =
            (activeFilter.title !== activeFilter.titleOriginal) ||
            (activeFilter.q !== activeFilter.qOriginal) ||
            (JSON.stringify(storeFacetsCleaned) !== JSON.stringify(facetsDiffCleaned));

          this.updateActiveFilterParameter(storeKey, 'filterModified', modified);
        });

      }

      // BUG: this was causing modified filters to appear as not modified on interval update,
      // does not appear to be needed, not sure why it is here
      // else {
      //   // console.log(`testActiveFilterModified(${storeKey})`, 'filterModified = false');
      //   // this.updateActiveFilterParameter(storeKey, 'filterModified', false);
      // }

    }

  }

  updateDefaultFilterId(storeKey: string, id: number): void {

    if( reportsStores.indexOf(storeKey) >= 0 ) {
      this.updateReportsDefaultFilterId(id);

    } else {

      if (livefeedStores.indexOf(storeKey) >= 0) {
        this.updateLivefeedDefaultFilterId(id);

      }

    }

  }

  updateFacetsPrettyText(storeKey: string, text: string): void {

    // console.log(`shared.service::updateFacetsPrettyText(${storeKey}, ${text})`);

    if( reportsStores.indexOf(storeKey) >= 0 ) {
      this.updateReportsFacetsPrettyText(text);
    } else {
      if (livefeedStores.indexOf(storeKey) >= 0) {
        this.updateLivefeedFacetsPrettyText(text);
      } else {
        if (chatbotFiltersStores.indexOf(storeKey) >= 0) {
          this.updateChatbotFacetsPrettyText(text);
        }
      }
    }
  }

  updateChipPrettyText(storeKey: string, text: string): void {

    // console.log(`shared.service::updateChipPrettyText(${storeKey}, ${text})`);

    if( reportsStores.indexOf(storeKey) >= 0 ) {
      this.updateReportsChipPrettyText(text);
    } else {
      if (livefeedStores.indexOf(storeKey) >= 0) {
        this.updateLivefeedChipPrettyText(text);
      } else {
        if (chatbotFiltersStores.indexOf(storeKey) >= 0) {
          this.updateChatbotChipPrettyText(text);
        }
      }
    }
  }

  setCookie(name: string, value: string, days: number): void {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${name}=${value}; ${expires}; path=/`;
  }

  getCookie(name: string): string | null {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

}
