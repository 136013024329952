import {Injectable, NgZone} from '@angular/core';
import {MatSnackBar, MatSnackBarModule} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  notification: any;

  constructor(public snackBar: MatSnackBar, private zone: NgZone) {

  }

  public open(message: string, action: string = '', duration: number = 3000, panelClass: string = '') {
    this.zone.run(() => {
      this.notification = this.snackBar.open(message, action, {duration: duration, panelClass: [panelClass]});
    });
    return this.notification;
  }
}
