<div class="dialog-title">
  <span>Analyst Access</span>
  <button (click)="closeContactAnalystDialog()">
    <mat-icon>cancel</mat-icon>
  </button>
</div>
<div class="container">
  <form [formGroup]="contactAnalystForm" (ngSubmit)="onSubmit()" *ngIf="!success">
    <section><p>If you are interested in speaking directly with an Analyst about this research presentation, please put any relevant questions in the inquiry field and submit. A member of the Enverus Intelligence® team will reach out to you shortly.</p>
      <hr>
    </section>
    <section>
      <label for="subject">Subject:</label>
      <input id="subject" matInput type="text" formControlName="subject" placeholder="Subject is Required">
    </section>
    <section>
      <label for="description">Inquiry:</label>
      <textarea id="description" matInput formControlName="description" rows="4" placeholder="Detailed information about the inquiry. Your contact information will be automatically included."></textarea>
      <p *ngIf="f.description.invalid" class="error">Required: Please provide a reason for your Analyst Contact request.</p>
    </section>
    <button mat-button type="submit">Submit</button>
    <button mat-button type="button" class="cancel-button" (click)="closeContactAnalystDialog()">Cancel</button>
  </form>
  <section class="error" *ngIf="error">
    <h2>We're Sorry,</h2>
    <p>An error has occurred trying to process your request.</p>
    <p>This error may be temporary so you may wish to retry the submission in a few minutes.</p>
    <p>If the error continues to occur please contact <a href="mailto:EnergyAnalyticsSupport@enverus.com">EnergyAnalyticsSupport@enverus.com</a> directly.</p>
    <p>Thank you.</p>
  </section>
  <section class="success" *ngIf="success">
    <h2>Thank you!</h2>
    <p>We have received your submission and will be processing it shortly. You may receive additional communication regarding this issue in the future if warranted.</p>
  </section>
  <div *ngIf="success || error">
    <button mat-button class="close" (click)="closeContactAnalystDialog()">Close</button>
  </div>
</div>
