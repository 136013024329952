import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'iv-preview-restricted',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './preview-restricted.component.html',
  styleUrls: ['./preview-restricted.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PreviewRestrictedComponent {

}
