// ------------------------------------------------------------------------------------------------
//  AzureSearchService:
//
//  Provides a collection of N stores to search azure indexes with custom search parameters,
//    facets, suggestions, etc... per store.
//
//  Features:
//  - allows N number of search collections
//  - facets stored in an angular compatible way (no MapToIterable conversions)
//
//  TODO:
//  - search_results_processor() allow multiple callbacks like clear_facets_processor_[add|remove]
//  - suggestions_results_processor allow multiple callbacks like clear_facets_processor_[add|remove]
//
// ------------------------------------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import { SearchClient, AzureKeyCredential } from "@azure/search-documents";
import { Constants } from "../../constants/constants";
import { v4 as uuidv4 } from 'uuid';

import {AzssError, AzssStore, AzssBank, createEmptyAzssStore} from '../../interface/azss';

import {DataService} from "../data/data.service";

@Injectable({
  providedIn: 'root'
})
export class AzureSearchService {

  client: any = {};                     // array of clients (can be reused by different stores)
  // store: AzssBank[] = [];              // array of stores (each with independent parameters)
  store: any = {};

  debug_cfn: boolean = false;           // show function call console debug info


  // ----------------------------------------------------------------------
  //   _ _  _ _ ___
  //   | |\ | |  |
  //   | | \| |  |
  //
  // --::INIT::------------------------------------------------------------

  constructor(
    private dataService: DataService
  ) {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::constructor()`, 'background: teal; color: white'); }
  }

  /**
   * Initialize an @azure/search-documents SearchClient(), referenced by clientKey.
   * @param {string} clientKey - The key to reference the SearchClient()
   * @param {string} endpoint - The Azure endpoint to call to perform the search.
   * @param {string} index - The azure index to search.
   * @param {string} queryKey - The API key required to perform the search.
   * @param {string} apiVersion - The Azure search apiVersion to use.
   * @returns {AzssError}
   */
  init_client(clientKey: string, endpoint: string, index: string, queryKey: string, apiVersion: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::init_client(clientKey ${clientKey}, endpoint ${endpoint},
      index ${index}, queryKey ${queryKey}, apiVersion ${apiVersion})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    // ensure this key has not already been init'ed
    if(!this.client.hasOwnProperty(clientKey)) {

      this.client[clientKey] = {
        clientName: clientKey,
        client: new SearchClient( endpoint, index, new AzureKeyCredential(queryKey),
          {
            apiVersion: ENV.SEARCH.version
          }
        )
      }

      // cannot re-init same SearchClient
    } else {
      azssError = {errmsg: `init_client() Trying to init duplicate azss client key "${clientKey}"`} as AzssError;

    }

    return azssError;
  }

  /**
   * Initialize an azss store using pre-inited client, referenced by storeKey.
   * @param {string} storeKey - The storeKey to reference the init'ed() store by.
   * @param {string} clientKey - The clientKey to use for searching on this store - must be pre-init'ed() by init_client().
   * @returns {AzssError}
   */
  init_store(storeKey: string, clientKey: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::init_store(storeKey ${storeKey}, clientKey ${clientKey})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    // ensure client key is valid
    if(!this.client.hasOwnProperty(clientKey)) {
      azssError = {errmsg: `init_store() Client key "${clientKey}" is invalid.`} as AzssError;
      return azssError;
    }

    // ensure storeKey has not been already init'ed
    if(this.store.hasOwnProperty(storeKey)) {
      azssError = {errmsg: `init_store() Store key "${storeKey}" has already been init()ed.`} as AzssError;
      return azssError;
    }

    // initialize the store
    this.store[storeKey] = createEmptyAzssStore(clientKey, storeKey);

    return null;
  }


  // ----------------------------------------------------------------------
  //   ____ ____ _    _    ___  ____ ____ _  _
  //   |    |__| |    |    |__] |__| |    |_/
  //   |___ |  | |___ |___ |__] |  | |___ | \_
  //
  // --::CALLBACK::--------------------------------------------------------

  /**
   * Bind method(s) to execute at end of clear_all_facets().
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} processorKey - The reference key of the callback.
   * @param {Object} processor - The method to bind to process the results after every search.
   * @returns {AzssError}
   */
  clear_facets_processor_add(storeKey: string, processorKey: string, processor: any): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::clear_facets_processor_add(storeKey ${storeKey}, ` +
      `processorKey ${processorKey}, processor ${typeof(processor)})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      if(processorKey && processorKey.length) {

        if (!this.store[storeKey].config.clear_facets_processor.hasOwnProperty(processorKey)) {

          if (typeof (processor) == 'function') {
            this.store[storeKey].config.clear_facets_processor[processorKey] = processor;

          } else {
            azssError = {errmsg: `clear_facets_processor_add() Passed non-function "processor" for Store key "${storeKey}".`} as AzssError;
          }

        } else {
          azssError = {errmsg: `clear_facets_processor_add() Processor key "${processorKey}" already exists.`} as AzssError;
        }

      } else {
        azssError = {errmsg: `clear_facets_processor_add() Processor key must be a non-empty string.`} as AzssError;
      }

    } else {
      azssError = {errmsg: `clear_facets_processor_add() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Remove bound method to execute at end of clear_all_facets().
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} processorKey - The reference key of the callback.
   * @returns {AzssError}
   */
  clear_facets_processor_remove(storeKey: string, processorKey: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::clear_facets_processor_remove(storeKey ${storeKey}, ` +
      `processorKey ${processorKey})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      if(processorKey && processorKey.length) {

        if (this.store[storeKey].config.clear_facets_processor.hasOwnProperty(processorKey)) {
          delete this.store[storeKey].config.clear_facets_processor[processorKey];

        } else {
          azssError = {errmsg: `clear_facets_processor_remove() Processor key "${processorKey}" does not exist.`} as AzssError;
        }

      } else {
        azssError = {errmsg: `clear_facets_processor_remove() Processor key must be a non-empty string.`} as AzssError;
      }

    } else {
      azssError = {errmsg: `clear_facets_processor_remove() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }


  /**
   * Bind a method to execute after searching to post-process the results.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {Object} processor - The method to bind to process the results after every search.
   * @returns {AzssError}
   */
  search_results_processor(storeKey: string, processor: any): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::search_results_processor(storeKey ${storeKey}, processor ${typeof(processor)})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(typeof(processor) == 'function') {
      this.store[storeKey].results.processor = processor;

    } else {
      azssError = {errmsg: `search_results_processor() Passed non-function "processor" for Store key "${storeKey}".`} as AzssError;

    }

    return azssError;
  }

  /**
   * Bind a method to execute after suggestions to post-process the results.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {Object} processor - The method to bind to process the results after every suggestions.
   * @returns {AzssError}
   */
  suggestions_results_processor(storeKey: string, processor: any): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::suggestions_results_processor(storeKey ${storeKey}, processor ${typeof(processor)})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(typeof(processor) == 'function') {
      this.store[storeKey].suggestions.processor = processor;

    } else {
      azssError = {errmsg: `suggestions_results_processor() Passed non-function "processor" for Store key "${storeKey}".`} as AzssError;

    }

    return azssError;
  }


  // ----------------------------------------------------------------------
  //   _  _ ____ ___  _ ____ _   _
  //   |\/| |  | |  \ | |___  \_/
  //   |  | |__| |__/ | |      |
  //
  // --::MODIFY::----------------------------------------------------------

  /**
   * Sets the search term (as typed in the search box).
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} input - The string to set input to.
   * @returns {AzssError}
   */
  set_input(storeKey: string, input: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_input(storeKey ${storeKey}, input ${input})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      this.store[storeKey].parameters.input = input;
    } else {
      azssError = {errmsg: `set_input() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Sets the display page (as selected in the ui).
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {number} page - The page to return results for.
   * @returns {AzssError}
   */
  set_page(storeKey: string, page: number): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_page(storeKey ${storeKey}, page ${page})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      this.store[storeKey].parameters.searchParameters.skip = (page - 1) *
        this.store[storeKey].parameters.searchParameters.top;
    } else {
      azssError = {errmsg: `set_page() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Update the search parameters for a store.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {Object} parameters - The parameters to update (will only replace the vars in this object).
   * @returns {AzssError}
   */
  update_search_parameters(storeKey: string, parameters: Object): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::update_search_parameters(storeKey ${storeKey}, parameters ${JSON.stringify(parameters)})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      Object.entries(parameters).forEach(([key, value]) => {
        // console.log(`update_search_parameters(key [${key}], value [${value}]`)
        // BUG: hacky... user service stores Order By as 'orderby' but @azure/search-documents expects 'orderBy' and as array
        if(key == 'orderby') {
          this.store[storeKey].parameters.searchParameters['orderBy'] = [ value ];
        } else {
          this.store[storeKey].parameters.searchParameters[key] = value;
        }
      });
    } else {
      azssError = {errmsg: `update_search_parameters() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Update the suggestions parameters for a store.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {Object} parameters - The parameters to update (will only replace the vars in this object).
   * @returns {AzssError}
   */
  update_suggestions_parameters(storeKey: string, parameters: Object): AzssError | null {
    const _self = this;
    if(this.debug_cfn) { console.log(`%c azuresearch.service::update_suggestions_parameters(storeKey ${storeKey}, parameters ${JSON.stringify(parameters)})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      for (const property in parameters) {
        switch(property) {
          // BUG: hacky... user service stores Order By as 'orderby' but @azure/search-documents expects 'orderBy' and as array
          case 'orderby':
            this.store[storeKey].parameters.suggestParameters['orderBy'] = [(parameters as any)[property]];
            break;
          // BUG: hacky... select must be an array!
          case 'select':
            if(typeof((parameters as any)[property]) == 'string') {
              this.store[storeKey].parameters.suggestParameters['select'] = [];
              const selectArray = (parameters as any)[property].split(',');
              selectArray.forEach(function (select: any) {
                _self.store[storeKey].parameters.suggestParameters['select'].push( select.trim() );
              });
            }
            break;
          default:
            this.store[storeKey].parameters.suggestParameters[property] = (parameters as any)[property];
            break;
        }
      }
    } else {
      azssError = {errmsg: `update_suggestions_parameters() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Define a checkbox facet.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} facetName - The facet to modify (ie: 'series').
   * @param {string} datatype - 'collection' | 'date' | 'string'.
   * @param {number} count - Maximum results?.
   * @param {string} sort - Field to sort on (ie: 'value').
   * @returns {AzssError}
   */
  add_checkbox_facet(storeKey: string, facetName: string, datatype: string, count: number, sort: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::add_checkbox_facet(storeKey ${storeKey}, ` +
      `facetName ${facetName}, datatype ${datatype}, count ${count}, sort ${sort})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      let facetObj = {
        key: facetName,
        type: "CheckboxFacet",
        dataType: datatype,
        count: count,
        sort: sort,
        filterClause: '',
        facetClause: `${facetName},count:${count},sort:${sort}`,
        facetsCombineUsingAnd: true,
        values: []
      };
      this.store[storeKey].facets.facets.push( facetObj );
      // this.store[storeKey].facets.facetKeys.push( facetName );

    } else {
      azssError = {errmsg: `add_checkbox_facet() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Toggle active state of a checkbox facet.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} facetName - The facet to modify (ie: 'series').
   * @param {string} facetValue - The facet item to toggle (ie: 'Blog Post').
   * @returns {AzssError}
   */
  toggle_checkbox_facet(storeKey: string, facetName: string, facetValue: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::toggle_checkbox_facet(storeKey ${storeKey}, ` +
      `facetName ${facetName}, facetValue ${facetValue})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      // find facet group
      for(let i=0; i < this.store[storeKey].facets.facets.length; i++) {
        if(this.store[storeKey].facets.facets[i].key == facetName) {
          // find facet value in group
          for(let j=0; j < this.store[storeKey].facets.facets[i].values.length; j++) {
            if(this.store[storeKey].facets.facets[i].values[j].value == facetValue) {
              // toggle selected of facet value
              this.store[storeKey].facets.facets[i].values[j].selected =
                !this.store[storeKey].facets.facets[i].values[j].selected;
              break;  // early out on success
            }
          }
          break;  // early out on success
        }
      }

    } else {
      azssError = {errmsg: `toggle_checkbox_facet() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Set active state of a checkbox facet.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} facetName - The facet to modify (ie: 'series').
   * @param {string} facetValue - The facet item to toggle (ie: 'Blog Post').
   * @param {boolean} state - The facet active state to set.
   * @returns {AzssError}
   */
  set_checkbox_facet(storeKey: string, facetName: string, facetValue: string, state: boolean): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_checkbox_facet(storeKey ${storeKey}, ` +
      `facetName ${facetName}, facetValue ${facetValue}, state ${state})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      // find facet group
      for(let i=0; i < this.store[storeKey].facets.facets.length; i++) {
        if(this.store[storeKey].facets.facets[i].key == facetName) {
          // find facet value in group
          for(let j=0; j < this.store[storeKey].facets.facets[i].values.length; j++) {
            if(this.store[storeKey].facets.facets[i].values[j].value == facetValue) {
              // console.log(`found and toggled "${facetName}/${facetValue}"`);
              // toggle selected of facet value
              this.store[storeKey].facets.facets[i].values[j].selected = state;
              break;  // early out on success
            }
          }
          break;  // early out on success
        }
      }

    } else {
      azssError = {errmsg: `set_checkbox_facet() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Set all checkboxes of a facet to a give state.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} facetName - The facet (ie: 'series') to select all checkboxes on.
   * @returns {AzssError}
   */
  set_all_facet_checkboxes(storeKey: string, facetName: string, state: boolean, searchTerm: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_all_facet_checkboxes(storeKey ${storeKey}, ` +
      `facetName ${facetName}, state ${state})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      // find facet group
      for(let i=0; i < this.store[storeKey].facets.facets.length; i++) {
        if(this.store[storeKey].facets.facets[i].key == facetName) {
          for(let j=0; j < this.store[storeKey].facets.facets[i].values.length; j++) {
            // only apply searchTerm filtering to add (state === true); we want to deselect all always
            if((searchTerm !== undefined) && searchTerm.length && state) {
              if(new RegExp(searchTerm, 'gi').test(this.store[storeKey].facets.facets[i].values[j].value)) {
                // console.log(`matched [${this.store[storeKey].facets.facets[i].values[j].value}]`);
                this.store[storeKey].facets.facets[i].values[j].selected = state;
              }
            } else {
              this.store[storeKey].facets.facets[i].values[j].selected = state;
            }
          }
          break;  // early out on success
        }
      }

    } else {
      azssError = {errmsg: `set_all_facet_checkboxes() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }


  /**
   * Define a range facet.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} facetName - The facet group name (ie: 'publishedDate').
   * @param {string} datatype - The data type (ie: 'date');
   * @param {Date} startDate - Starting javascript Date() object.
   * @param {Date} endDate - Ending javascript Date() object.
   * @returns {AzssError}
   */
  add_range_facet_date(storeKey: string, facetName: string, datatype: string, startDate: Date, endDate: Date ): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::add_range_facet_date(storeKey ${storeKey}, ` +
      `facetName ${facetName}, datatype ${datatype}, startDate ${startDate.toISOString()}, endDate ${endDate.toISOString()})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      let facetObj = {
        key: facetName,
        type: 'RangeFacet',
        dataType: datatype,
        min: startDate.toISOString(),
        max: endDate.toISOString(),
        filterLowerBound: startDate.toISOString(),
        filterUpperBound: endDate.toISOString(),
        lowerBucketCount: 0,
        middleBucketCount: 0,
        upperBucketCount: 0,
        filterClause: "",
        facetClause: `${facetName},values:${startDate.toISOString()}|${endDate.toISOString()}`
      };
      this.store[storeKey].facets.facets.push( facetObj );
      // this.store[storeKey].facets.facetKeys.push( facetName );

    } else {
      azssError = {errmsg: `add_range_facet_date() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Define a range facet.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} facetName - The facet group name (ie: 'publishedDate').
   * @param {Date} startDate - Starting javascript Date() object.
   * @param {Date} endDate - Ending javascript Date() object.
   * @returns {AzssError}
   */
  //        this.store.setFacetRange(facet.category, facetValue.startDate, facetValue.endDate);
  set_range_facet_date(storeKey: string, facetName: string, startDate: Date, endDate: Date ): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_range_facet_date(storeKey ${storeKey}, ` +
      `facetName ${facetName}, startDate ${startDate.toISOString()}, endDate ${endDate.toISOString()})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      // console.log(`%c azuresearch.service::set_range_facet_date(storeKey ${storeKey}, ` +
      //   `facetName ${facetName}, startDate ${startDate.toISOString()}, endDate ${endDate.toISOString()})`,
      //   'background: teal; color: white');

      // find facet group
      for(let i=0; i < this.store[storeKey].facets.facets.length; i++) {
        if(this.store[storeKey].facets.facets[i].key == facetName) {

          this.store[storeKey].facets.facets[i].filterLowerBound = startDate.toISOString();
          this.store[storeKey].facets.facets[i].filterUpperBound = endDate.toISOString();

          const beg = new Date( Constants.publishedStartDate );
          this.store[storeKey].facets.facets[i].min = beg.toISOString();
          const now = new Date();
          this.store[storeKey].facets.facets[i].max = now.toISOString();

          this.store[storeKey].facets.facets[i].filterClause =
            `publishedDate ge ${this.store[storeKey].facets.facets[i].filterLowerBound} and publishedDate le ${this.store[storeKey].facets.facets[i].filterUpperBound}`;
          this.store[storeKey].facets.facets[i].facetClause =
            `publishedDate,values:${this.store[storeKey].facets.facets[i].filterLowerBound}|${this.store[storeKey].facets.facets[i].filterUpperBound}`;

          break;  // early out on success
        }
      }

    } else {
      azssError = {errmsg: `set_range_facet_date() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Clear active state of all facets.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {boolean} clearSubscriptions - Clear the subscriptions (Intelligence Type facetGroup).
   * @returns {AzssError}
   */
  clear_all_facets(storeKey: string, clearSubscriptions: boolean = true): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::clear_all_facets(storeKey ${storeKey}, ` +
      `clearSubscriptions ${clearSubscriptions})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      // loop through facet group
      for(let i=0; i < this.store[storeKey].facets.facets.length; i++) {

        // handle checkboxes
        // {
        //   "key": "acl",
        //   "type": "CheckboxFacet",
        //   "dataType": "collection",
        //   "count": 200,
        //   "sort": "value",
        //   "filterClause": "",
        //   "facetClause": "acl,count:200,sort:value",
        //   "values": []
        // }
        if(this.store[storeKey].facets.facets[i].type == 'CheckboxFacet') {
          // loop through facet values in groups
          for (let j = 0; j < this.store[storeKey].facets.facets[i].values.length; j++) {
            // clear selected of facet value
            this.store[storeKey].facets.facets[i].values[j].selected = false;
          }
        }

        // handle facet ranges (date)
        // date range - select 7D - azsearchstore:
        // {
        //   "type": "RangeFacet",
        //   "dataType": "date",
        //   "key": "publishedDate",
        //   "min": "2002-09-01T15:57:04.154Z",
        //   "max": "2022-09-01T15:57:04.154Z",
        //   "filterLowerBound": "2022-08-25T00:00:00.485Z",
        //   "filterUpperBound": "2022-09-01T23:59:59.485Z",
        //   "lowerBucketCount": 0,
        //   "middleBucketCount": 14,
        //   "upperBucketCount": 0,
        //   "filterClause": "publishedDate ge 2022-08-25T00:00:00.485Z and publishedDate le 2022-09-01T23:59:59.485Z",
        //   "facetClause": "publishedDate,values:2022-08-25T00:00:00.485Z|2022-09-01T23:59:59.485Z"
        // }

        // azsearchstore has a bug where after clearing range facets (date) still has
        // facetClause: "publishedDate,values:2022-08-25T00:00:00.985Z|2022-09-01T23:59:59.985Z"
        // which is wrong!
        // (i'm setting to match the startup state ==
        // facetClause: "publishedDate,values:2002-09-01T16:46:34.297Z|2022-09-01T16:46:34.297Z")

        if(this.store[storeKey].facets.facets[i].type == 'RangeFacet') {
          switch( this.store[storeKey].facets.facets[i].dataType )
          {
            case 'date':
              const startDate = new Date( Constants.publishedStartDate );
              const endDate = new Date();
              this.store[storeKey].facets.facets[i].min = startDate.toISOString();
              this.store[storeKey].facets.facets[i].filterLowerBound = startDate.toISOString();
              this.store[storeKey].facets.facets[i].max = endDate.toISOString();
              this.store[storeKey].facets.facets[i].filterUpperBound = endDate.toISOString();
              this.store[storeKey].facets.facets[i].facetClause =
                `publishedDate,values:${this.store[storeKey].facets.facets[i].filterLowerBound}|${this.store[storeKey].facets.facets[i].filterUpperBound}`;
              this.store[storeKey].facets.facets[i].filterClause = '';
              break;
            default:
              console.log(`azuresearch.service::clear_all_facets() unimplemented RangeFacet type ${this.store[storeKey].facets.facets[i].dataType}`);
              break;
          }
        }

      }

      // this also means all 'Intelligence Type' subscription filters are cleared
      if(clearSubscriptions) {
        this.store[storeKey].subscriptionFilters.count = 0;
        if(this.store[storeKey].subscriptionFilters.hasOwnProperty('filters')) {
          this.store[storeKey].subscriptionFilters.filters.forEach(function (item: any) {
            item.active = false;
          });
        }
      }

      // ----------------------------------------------------------------------
      //  clear facets processor callback(s)
      // ----------------------------------------------------------------------

      for (const property in this.store[storeKey].config.clear_facets_processor) {
        if( typeof(this.store[storeKey].config.clear_facets_processor[property] == 'function') ) {
          this.store[storeKey].config.clear_facets_processor[property]();
        }
      }

      // update facetsDiff
      this.diff_facets( storeKey );

      //this.dump_stores();

    } else {
      azssError = {errmsg: `clear_all_facets() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }


  /**
   * Update the global filter(s) for a store.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} filterName - The name of the global filter to modify (on this store).
   * @param {string} filter - The filter to set.
   * @returns {AzssError}
   */
  set_global_filter(storeKey: string, filterName: string, filter: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_global_filter(storeKey ${storeKey}, filterName ${filterName}), filter ${filter}`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      this.store[storeKey].facets.globalFilters[filterName] = filter;

    } else {
      azssError = {errmsg: `set_global_filter() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Set the subscription filters for a store (Intelligence Type facet).
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} subscriptionFilters - The subscriptions filters object to populate.
   * @returns {AzssError}
   */
  set_subscription_filters(storeKey: string, subscriptionFilters: any): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_subscription_filters(storeKey ${storeKey}, subscriptionFilters ${subscriptionFilters})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      // console.log(`%c azuresearch.service::set_subscription_filters(storeKey OVERWRITING subscriptionFilters`, 'background: red; color: white');
      // console.log(subscriptionFilters);

      this.store[storeKey].subscriptionFilters = subscriptionFilters;

      // console.log( JSON.parse( JSON.stringify( this.store[storeKey] )));

    } else {
      azssError = {errmsg: `set_subscription_filters() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Set the filter for an individual subscription in the subscription filters for a store.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} subscriptionName - The name of the subscription to modify.
   * @param {string} filter - The filter object to populate.
   * @returns {AzssError}
   */
  set_subscription_filters_filter(storeKey: string, subscriptionName: string, filter: any): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_subscription_filters_filter(storeKey ${storeKey}, ` +
      `subscriptionName ${subscriptionName}, filter ${filter})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      let obj = this.store[storeKey].subscriptionFilters.filters.find((o: any) => o.key === subscriptionName);
      if(obj != null) {
        // console.log(`%c azuresearch.service::set_subscription_filters_filter(storeKey OVERWRITING subscriptionFilters`, 'background: red; color: white');
        // console.log(filter);
        obj.filter = filter;
      } else {
        azssError = {errmsg: `set_subscription_filters_filter() Store key "${storeKey}" has no subscription filter named ${subscriptionName}.`} as AzssError;
      }

    } else {
      azssError = {errmsg: `set_subscription_filters_filter() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Set the filter count for the selected subscription filters for a store.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {number} count - The filter count to populate or -1 to auto-count / populate;
   * @returns {AzssError}
   */
  set_subscription_filters_count(storeKey: string, count: number): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_subscription_filters_count(storeKey ${storeKey}, ` +
      `count ${count})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      if(count > -1) {
        this.store[storeKey].subscriptionFilters.count = count;

        // update the count of active filters
      } else {
        let count = 0;
        this.store[storeKey].subscriptionFilters.filters.forEach(function(item: any) {
          if(item.active) {
            count++;
          }
        });
        // console.log(`%c azuresearch.service::set_subscription_filters_count(storeKey UPDATING subscriptionFilters.count`, 'background: red; color: white');
        this.store[storeKey].subscriptionFilters.count = count;

      }

    } else {
      azssError = {errmsg: `set_subscription_filters_count() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Toggle the filter active boolean for an individual subscription in the subscription filters for a store.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} subscriptionName - The name of the subscription to modify.
   * @returns {AzssError}
   */
  toggle_subscription_filters_filter_active(storeKey: string, subscriptionName: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::toggle_subscription_filters_filter_active(storeKey ${storeKey}, ` +
      `subscriptionName ${subscriptionName})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      let obj = this.store[storeKey].subscriptionFilters.filters.find((o: any) => o.key === subscriptionName);
      if(obj != null) {
        obj.active = !obj.active;
        // console.log(`%c azuresearch.service::toggle_subscription_filters_filter_active(storeKey toggling filter active`, 'background: red; color: white');

        // update the count of active filters
        let count = 0;
        this.store[storeKey].subscriptionFilters.filters.forEach(function(item: any) {
          if(item.active) {
            count++;
          }
        });
        // console.log(`%c azuresearch.service::toggle_subscription_filters_filters_active(${storeKey} UPDATING subscriptionFilters.count`, 'background: red; color: white');
        this.store[storeKey].subscriptionFilters.count = count;

      } else {
        azssError = {errmsg: `toggle_subscription_filters_filter_active() Store key "${storeKey}" has no subscription filter named ${subscriptionName}.`} as AzssError;
      }

    } else {
      azssError = {errmsg: `toggle_subscription_filters_filter_active() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Reset all facet items to active: false and set total count to zero.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @returns {AzssError}
   */
  subscription_filters_clear_all_active(storeKey: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::subscription_filters_clear_all_active(storeKey ${storeKey}`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      if(this.store[storeKey].subscriptionFilters.hasOwnProperty('filters')) {
        this.store[storeKey].subscriptionFilters.filters.forEach(function (item: any) {
          item.active = false;
          // console.log(`%c azuresearch.service::subscription_filters_clear_all_active(storeKey clearing filter active`, 'background: red; color: white');
        });
      }
      // console.log(`%c azuresearch.service::subscription_filters_clear_all_active(${storeKey} UPDATING subscriptionFilters.count = 0`, 'background: red; color: white');
      this.store[storeKey].subscriptionFilters.count = 0;

    } else {
      azssError = {errmsg: `subscription_filters_clear_all_active() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Replace facetsDiff for a store.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {any} facetsDiff - The facetsDiff object to replace on the store.
   * @returns {AzssError}
   */
  set_facets_diff(storeKey: string, facetsDiff: any): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_facets_diff(storeKey ${storeKey},` +
      ` facetsDiff ${JSON.stringify(facetsDiff)}`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      this.store[storeKey].facetsDiff = JSON.parse( JSON.stringify( facetsDiff ) );

    } else {
      azssError = {errmsg: `set_facets_diff() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Replace facets for a store.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {any} facetsDiff - The facets object to replace on the store.
   * @returns {AzssError}
   */
  set_facets(storeKey: string, facets: any): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_facets(storeKey ${storeKey},` +
      ` facets ${facets}`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      this.store[storeKey].facets = JSON.parse( JSON.stringify( facets ) );

    } else {
      azssError = {errmsg: `set_facets() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Set a facet group flag to either AND or OR the facets in that group together.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} facetName - The facet to modify (ie: 'series').
   * @param {boolean} facetsCombineUsingAnd - If true combine using AND, otherwise combine usring OR.
   * @returns {AzssError}
   */
  set_facets_group_combine_using_and(storeKey: string, facetName: string, facetsCombineUsingAnd: boolean): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_facets_group_combine_using_and(storeKey ${storeKey}, ` +
      `facetName ${facetName}, facetsCombineUsingAnd ${facetsCombineUsingAnd})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      // find facet group
      for(let i=0; i < this.store[storeKey].facets.facets.length; i++) {
        if(this.store[storeKey].facets.facets[i].key == facetName) {
          this.store[storeKey].facets.facets[i].facetsCombineUsingAnd = facetsCombineUsingAnd;
          break;  // early out on success
        }
      }

    } else {
      azssError = {errmsg: `set_facets_group_combine_using_and() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Set all facet groups' flag to either AND or OR the facets in that group together.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {boolean} facetsCombineUsingAnd - If true combine using AND, otherwise combine usring OR.
   * @returns {AzssError}
   */
  set_all_facets_group_combine_using_and(storeKey: string, facetsCombineUsingAnd: boolean): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_all_facets_group_combine_using_and(storeKey ${storeKey}, ` +
      `facetsCombineUsingAnd ${facetsCombineUsingAnd})`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      // find facet group
      for(let i=0; i < this.store[storeKey].facets.facets.length; i++) {
        if(this.store[storeKey].facets.facets[i].hasOwnProperty('facetsCombineUsingAnd')) {
          this.store[storeKey].facets.facets[i].facetsCombineUsingAnd = facetsCombineUsingAnd;
        }
      }

    } else {
      azssError = {errmsg: `set_all_facets_group_combine_using_and() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Update the firstResultsFetched flag for a store (if false, loader will appear on search).
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {boolean} firstResultsFetched - The value to set firstResultsFetched to.
   * @returns {AzssError}
   */
  set_first_results_fetched(storeKey: string, firstResultsFetched: boolean): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::set_first_results_fetched(storeKey ${storeKey}, firstResultsFetched ${firstResultsFetched})}`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      this.store[storeKey].results.firstResultsFetched = firstResultsFetched;

    } else {
      azssError = {errmsg: `set_first_results_fetched() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }


  // ----------------------------------------------------------------------
  //   ____ _  _ ____ ____ _   _
  //   |  | |  | |___ |__/  \_/
  //   |_\| |__| |___ |  \   |
  //
  // --::QUERY::-----------------------------------------------------------

  /**
   * Test that a store exists.
   * @param {string} storeKey - The storeKey of the store to test.
   * @returns {boolean}
   */
  test_store_exists(storeKey: string): boolean {
    // if(this.debug_cfn) { console.log(`%c azuresearch.service::get_subscription_filters_count(storeKey ${storeKey})`,
    //   'background: teal; color: white'); }

    return this.store.hasOwnProperty(storeKey);
  }

  /**
   * Get the search term (as typed in the search box).
   * @param {string} storeKey - The storeKey of the store to query.
   * @returns {string}
   */
  get_input(storeKey: string): string {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::get_input(storeKey ${storeKey})`,
      'background: teal; color: white'); }
    let retQuery: string = '';

    if(this.store.hasOwnProperty(storeKey)) {
      retQuery = this.store[storeKey].parameters.input;
    }

    return retQuery;
  }

  /**
   * Get the subscription filters count for a store (Intelligence Type facet).
   * @param {string} storeKey - The storeKey of the store to query.
   * @returns {number}
   */
  get_subscription_filters_count(storeKey: string): number {
    // if(this.debug_cfn) { console.log(`%c azuresearch.service::get_subscription_filters_count(storeKey ${storeKey})`,
    //   'background: teal; color: white'); }
    let count = 0;

    if(this.store.hasOwnProperty(storeKey)) {
      count = this.store[storeKey].subscriptionFilters.count;
    }

    return count;
  }

  /**
   * Get the subscription filters for a store (Intelligence Type facet) - read only array copy.
   * @param {string} storeKey - The storeKey of the store to query.
   * @returns {array}
   */
  get_subscription_filters_filters(storeKey: string): any {
    // if(this.debug_cfn) { console.log(`%c azuresearch.service::get_subscription_filters_filters(storeKey ${storeKey})`,
    //   'background: teal; color: white'); }
    let sfArr = [];

    if(this.store.hasOwnProperty(storeKey)) {
      if(this.store[storeKey].subscriptionFilters.hasOwnProperty('filters')) {
        sfArr = JSON.parse(JSON.stringify(this.store[storeKey].subscriptionFilters.filters));
      }
    }

    return sfArr;
  }

  /**
   * Get the search parameters for a store - read only object copy.
   * @param {string} storeKey - The storeKey of the store to query.
   * @returns {object}
   */
  get_search_parameters(storeKey: string): any {
    // if(this.debug_cfn) { console.log(`%c azuresearch.service::get_search_parameters(storeKey ${storeKey})`,
    //   'background: teal; color: white'); }
    let spObj = {};

    if(this.store.hasOwnProperty(storeKey)) {
      spObj = JSON.parse( JSON.stringify( this.store[storeKey].parameters.searchParameters ) );
    }

    return spObj;
  }

  /**
   * Get the active facetsDiff for a store - read only object copy.
   * @param {string} storeKey - The storeKey of the store to query.
   * @param {boolean} dropRangeFacets - Drop range facets from result.
   * @returns {object}
   */
  get_facetsdiff(storeKey: string, dropRangeFacets: boolean = false): any {
    // if(this.debug_cfn) { console.log(`%c azuresearch.service::get_facetsdiff(storeKey ${storeKey}, dropRangeFacets ${dropRangeFacets})`,
    //   'background: teal; color: white'); }
    let fdObj: any = null;

    // console.log(`%c azuresearch.service::get_facetsdiff(storeKey ${storeKey}, dropRangeFacets ${dropRangeFacets})`, 'background: red; color: white');

    if(this.store.hasOwnProperty(storeKey)) {
      fdObj = JSON.parse( JSON.stringify( this.store[storeKey].facetsDiff ) );
      if(dropRangeFacets) {
        for(let i = 0; i < fdObj.facets.length; i++) {
          if(fdObj.facets[i].type == 'RangeFacet') {
            fdObj.facets.splice(i,1);
          }
        }
      }
    }

    return fdObj;
  }

  /**
   * Get the facets for a store - read only object copy.
   * @param {string} storeKey - The storeKey of the store to query.
   * @param {boolean} dropRangeFacets - Drop range facets from result.
   * @returns {object}
   */
  get_facets(storeKey: string, dropRangeFacets: boolean = false): any {
    // if(this.debug_cfn) { console.log(`%c azuresearch.service::get_facets(storeKey ${storeKey}, dropRangeFacets ${dropRangeFacets})`,
    //   'background: teal; color: white'); }
    let fdObj: any = null;

    // console.log(`%c azuresearch.service::get_facets(storeKey ${storeKey}, dropRangeFacets ${dropRangeFacets})`, 'background: red; color: white');

    if(this.store.hasOwnProperty(storeKey)) {
      fdObj = JSON.parse( JSON.stringify( this.store[storeKey].facets ) );
      if(dropRangeFacets) {
        for(let i = 0; i < fdObj.facets.length; i++) {
          if(fdObj.facets[i].type == 'RangeFacet') {
            fdObj.facets.splice(i,1);
          }
        }
      }
    }

    return fdObj;
  }

  mapFilterTitle(key: string): string {
    const titlesMap: any = {
      publishedDate:'Date Range',
      projectType:'Intelligence Type',
      acl:'Intelligence Type',
      series:'Report Series',
      researchType:'RS Research Type',
      type:'Class',
      subType:'Sub Class',
      category:'Category',
      subCategory: 'Sub Category',
      solutionSet:'Solution Set',
      modelAuthors:'Authors',
      authors:'Authors',
      companies:'Companies',
      stockTickers:'Stock Tickers',
      countries:'Countries',
      regions:'Regions',
      basins:'Basins',
      plays:'Plays',
      intervals:'Intervals',
      financialView:'Financial View'
    };
    return titlesMap[key] || key;
  }

  get_facets_for_keyword_conversion(storeKey: string): any[] {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::get_facets_for_keyword_conversion(storeKey ${storeKey})`,
      'background: teal; color: white'); }

    let results: any[] = [];

    if(this.store.hasOwnProperty(storeKey)) {

      this.store[storeKey].facets.facets.forEach((facet: any) => {
        if(facet.key !== 'acl') {
          switch (facet.type) {
            // CheckboxFacet
            case 'CheckboxFacet':
              facet.values.forEach(function (value: any) {

                let rec = {
                  value: value.value,
                  key: facet.key
                };
                results.push(rec);

              });
              break;
          }
        }
      });

      results.sort((a, b) => b.value.length - a.value.length);
    }

    return( results );
  }

  /**
   * Get the active facetsDiff in human displayable form.
   * @param {string} storeKey - The storeKey of the store to query.
   * @param {string} delimiter - Delimiter between categories.
   * @param {string} EOL - End of line character.
   * @returns {string}
   */
  get_facetsdiff_pretty_text(storeKey: string, delimiter: string, EOL: string, includeKeywords: boolean = true,
  prepend: string = '', excludeCollection: boolean = false, excludeSubscriptions: boolean = false,
  truncateLength: number = 0, includeDateRange: boolean = false): string {

    if(this.debug_cfn) { console.log(`%c azuresearch.service::get_facetsdiff_pretty_text(storeKey ${storeKey})`,
      'background: teal; color: white'); }

    let additionalFilters = 0;

    let prettyText: string = '';

    if(this.store.hasOwnProperty(storeKey)) {

      // Prepend Text
      if ((truncateLength == 0) || (prettyText.length + prepend.length <= truncateLength)) {
        prettyText += prepend;
      } else {
        additionalFilters += 1;
      }

      // Collection
      if (!excludeCollection) {
        let element: string = `${(prettyText.length ? EOL : '')}`;
        if (includeKeywords) {
          element += 'Search Collection: ';
        }
        element += `${this.store[storeKey].collection}`;
        if ((truncateLength == 0) || (prettyText.length + element.length <= truncateLength)) {
          prettyText += element;
        } else {
          additionalFilters += 1;
        }
      }

      // Facets - checkboxes first
      this.store[storeKey].facetsDiff.facets.forEach((facet: any) => {
        switch (facet.type) {

          // CheckboxFacet
          case 'CheckboxFacet':
            //let facetList = '';
            let facetListCount: number = 0;
            let facetList: string = `${(prettyText.length ? EOL : '')}`;
            if (includeKeywords) {
              facetList += `${this.mapFilterTitle(facet.key)}: `;
            }
            facet.values.forEach(function (value: any) {
              // facetList = facetList + (facetList.length ?
              //   (facet.facetsCombineUsingAnd ? ' & ' : ' | ')
              //   : '') + value.value;
              let element: string = (facetListCount > 0 ?
                (facet.facetsCombineUsingAnd ? ' & ' : ' | ')
                : '') + value.value;
              if ((truncateLength == 0) || (prettyText.length + facetList.length + element.length <= truncateLength)) {
                facetList += element;
                facetListCount += 1;
              } else {
                additionalFilters += 1;
              }
            });
            // if (facetList.length) {
            if (facetListCount > 0) {
              // prettyText += `${(prettyText.length ? EOL : '')}`;
              // if(includeKeywords) {
              //   prettyText += `${this.mapFilterTitle(facet.key)}: `;
              // }
              prettyText += `${facetList}`;
            }
            break;

        }

      });

      // Facets - ranges second (published date)
      if(includeDateRange) {
        this.store[storeKey].facetsDiff.facets.forEach((facet: any) => {
          switch (facet.type) {

            // RangeFacet
            case 'RangeFacet':
              switch (facet.dataType) {

                // date
                case 'date':
                  const dateOptions: any = {year: 'numeric', month: 'numeric', day: 'numeric'}
                  let element: string = `${(prettyText.length ? EOL : '')}`;
                  if (includeKeywords) {
                    element += `${this.mapFilterTitle(facet.key)}: `;
                  }
                  element += `${new Date(facet.filterLowerBound).toLocaleString('en-US', dateOptions)} - ${new Date(facet.filterUpperBound).toLocaleString('en-US', dateOptions)}`;
                  if ((truncateLength == 0) || (prettyText.length + element.length <= truncateLength)) {
                    prettyText += element;
                  } else {
                    additionalFilters += 1;
                  }
                  break;

                default:
                  console.log(`azuresearch.service::get_facetsdiff_pretty_text() unimplemented RangeFacet type ${facet.dataType}`);
                  break;

              }
              break;

          }
        });
      }

      // Subscriptions
      if(!excludeSubscriptions) {
        let subListCount: number = 0;
        if (this.store[storeKey].facetsDiff.subscriptionFilters.hasOwnProperty('filters')) {
          //let subList = '';
          let subList: string = `${(prettyText.length ? EOL : '')}`;
          if (includeKeywords) {
            subList += `Intelligence Type: `;
          }
          this.store[storeKey].facetsDiff.subscriptionFilters.filters.forEach(function (sub: any) {
            if (sub.active) {
              let element: string = `${(subListCount > 0 ? ' | ' : '')}${sub.key}`;
              if ((truncateLength == 0) || (prettyText.length + subList.length + element.length <= truncateLength)) {
                subList += element;
                subListCount += 1;
              } else {
                additionalFilters += 1;
              }
            }
          });
          // if (subList.length) {
          if (subListCount > 0) {
            // prettyText += `${(prettyText.length ? EOL : '')}`;
            // if(includeKeywords) {
            //   prettyText += `Intelligence Type: `;
            // }
            prettyText += `${subList}`;
          }
        }
      }

    }

    if(additionalFilters) {
      prettyText += `; +${additionalFilters} other filter${additionalFilters > 1 ? 's' : ''}...`;
    }

    // console.log(`*** reports-view  prettyText ${truncateLength > 0 ? '4 chip' : '4 title'} = ${prettyText}`);

    return prettyText;
  }

  // ----------------------------------------------------------------------
  //   ____ ____ ____ ____ ____ _  _
  //   [__  |___ |__| |__/ |    |__|
  //   ___] |___ |  | |  \ |___ |  |
  //
  // --::SEARCH::----------------------------------------------------------

  /**
   * Process all facets and store selected ones and subscriptionFilters to facetsDiff
   * @param {string} storeKey - The storeKey of the store to modify.
   * @returns {object}
   */
  diff_facets(storeKey: string): void {
    const _self = this;

    // save the state of all selected facets
    this.store[storeKey].facetsDiff.facets = [];

    this.store[storeKey].facets.facets.forEach(function (facetGroup: any) {

      switch(facetGroup.type) {
        case 'CheckboxFacet':
          facetGroup.values.forEach(function (facet: any) {
            if (facet.selected) {

              let dstObj = _self.store[storeKey].facetsDiff.facets.find((o: any) => o.key === facetGroup.key);

              // if facetsDiff does not have this facet Group then add it
              if (dstObj == null) {

                let dstObj: any = {};
                for (const property in facetGroup) {
                  if (!Array.isArray(facetGroup[property])) {
                    dstObj[property] = facetGroup[property];
                  }
                }
                dstObj['values'] = [];
                dstObj['values'].push(facet);
                _self.store[storeKey].facetsDiff.facets.push(dstObj);

                // otherwise just add to facet group values
              } else {
                dstObj['values'].push(facet);

              }

            }
          });
          break;

        case 'RangeFacet':
          switch(facetGroup.dataType) {
            case 'date':
              let dstObj = _self.store[storeKey].facetsDiff.facets.find((o: any) => o.key === facetGroup.key);

              // if facetsDiff does not have this facet Group then add it
              if (dstObj == null) {
                let dstObj = JSON.parse( JSON.stringify( facetGroup ) );
                _self.store[storeKey].facetsDiff.facets.push(dstObj);

                // otherwise overwrite it
              } else {
                dstObj = JSON.parse( JSON.stringify( facetGroup ) );
              }
              break;
            default:
              console.log(`azuresearch.service::diff_facets() unimplemented RangeFacet type ${facetGroup.dataType}`);
              break;
          }
          break;

      }

    });

    // save the state of 'Intelligence Type' filters
    this.store[storeKey].facetsDiff.subscriptionFilters =
      JSON.parse( JSON.stringify( this.store[storeKey].subscriptionFilters ) );
  }

  /**
   * Build the search filter for Azure search from facets.facets or facetsDiff.facets.
   * @param {string} storeKey - The storeKey of the store to query.
   * @param {any} facets - The facets.facets or facetsDiff.facets to query.
   * @param (boolean} groupsOredTogether - If showing empty filter results then OR the groups together.
   * @param (boolean} facetsOredTogether - Facets should be ORed or ANDed together.
   * @returns {string}
   */
  build_search_filter(storeKey: string, facets: any, groupsOredTogether: boolean = false,
                      facetsOredTogether: boolean = false): string {
    let searchFilter = '';

    if(this.debug_cfn) { console.log(`%c azuresearch.service::build_search_filter(storeKey ${storeKey}, ` +
      `groupsOredTogether ${groupsOredTogether}, facetsOredTogether ${facetsOredTogether}`, 'background: yellow; color: black'); }

    // ----------------------------------------------------------------------
    //  generate search filter
    //  filters are all ANDed together (fgN = facet group, ie: authors), gf# = global filter)
    //  (facets within each fgN are ORed together), ie:
    //         {fgN}                   {fgN}             gf          gf          gf
    //  (A [or B [or C...]] AND (A [or B [or C...]] AND (gf1) [ AND (gf2) [ AND (gf3...)]]
    //  for Enverus vault-portal:
    //    (facetvalA1 OR facetvalA2) AND (facetvalB1 OR facetvalB2)
    //    AND gf('subscription')        == Intelligence Type filter(s)
    //    AND gf('vault')               == collectionFilter
    // ----------------------------------------------------------------------

    // BUG: HACKY, NEED TO FIX THIS PROPERLY!
    let localGroupsOredTogether: boolean = groupsOredTogether;
    // facets.forEach(function (facetGroup: any) {
    //   if (facetGroup.hasOwnProperty('facetsCombineUsingAnd') && !facetGroup.facetsCombineUsingAnd) {
    //     localGroupsOredTogether = true;
    //   }
    // });

    facets.forEach(function (facetGroup: any) {

      // either global facetsOredTogether ([ ] Show all filters), or facetsCombineUsingAnd per facet group
      let groupFacetsOredTogether: boolean = facetsOredTogether;
      if(facetGroup.hasOwnProperty('facetsCombineUsingAnd') && !facetGroup.facetsCombineUsingAnd) {
        groupFacetsOredTogether = true;
      }

      // handle checkbox facets
      switch(facetGroup.type) {
        case 'CheckboxFacet':
          // build facet group
          let facetCount = 0;
          let groupStr = ''
          facetGroup.values.forEach(function (facet: any) {
            if (facet.selected) {
              switch (facetGroup.dataType) {
                // ie: series eq 'Activity Map'
                case 'string':
                  const skey = facetGroup.key;
                  const sval = facet.value;
                  groupStr += `${(facetCount == 0 ? '(' : (groupFacetsOredTogether ? ' or ' : ' and ') )}${skey.replace(/'/g,"''")} eq '${sval.replace(/'/g,"''")}'`;
                  break;
                // ie: authors/any(t: t eq 'Aaron Reimchen')
                case 'collection':
                  const ckey = facetGroup.key;
                  const cval = facet.value;
                  groupStr += `${(facetCount == 0 ? '(' : (groupFacetsOredTogether ? ' or ' : ' and ') )}${ckey.replace(/'/g,"''")}/any(t: t eq '${cval.replace(/'/g,"''")}')`;
                  break;
              }
              facetCount++;
            }
          });
          if (facetCount) {
            groupStr += ')';
          }
          // append groups
          if (groupStr.length) {
            //searchFilter += `${(searchFilter.length ? (groupsOredTogether ? ' or ' : ' and ') : '')}${groupStr}`;
            searchFilter += `${(searchFilter.length ? (localGroupsOredTogether ? ' or ' : ' and ') : '')}${groupStr}`;  // BUG: HACKY, FIX THIS!
          }
          break;

        // handle range facets
        case 'RangeFacet':
          switch(facetGroup.dataType) {

            // (publishedDate ge 2022-08-25T00:00:00.419Z and publishedDate le 2022-09-01T23:59:59.419Z) and (collection/any(t: t eq 'Intelligence')...
            case 'date':
              if(facetGroup.filterClause.length) {
                searchFilter += `${(searchFilter.length ? (localGroupsOredTogether ? ' or ' : ' and ') : '')}(${facetGroup.filterClause})`;
              }
              break;

            default:
              console.log(`azuresearch.service::build_search_filter() unimplemented RangeFacet type ${facetGroup.dataType}`);
              break;

          }
          break;

      }

    });

    if(searchFilter.length) {
      searchFilter = '(' + searchFilter + ')';
    }

    // add all global filters in
    let gfArray = Object.keys(this.store[storeKey].facets.globalFilters).map(key => {
      return this.store[storeKey].facets.globalFilters[key];
    });
    gfArray.forEach(function (filter) {
      if(filter && filter.length) {
        searchFilter += `${(searchFilter.length ? ' and ' : '')}${filter}`;
      }
    });

    // if any additional filters, add them
    if(this.store[storeKey].parameters.searchParameters.additionalFilters) {
      searchFilter += `${(searchFilter.length ? ' and ' : '')}${this.store[storeKey].parameters.searchParameters.additionalFilters}`;
    }

    return searchFilter;
  }

  /**
   * Restore facetsDiff to facets
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {boolean} restoreSubscriptions - Whether to also overwrite the subscriptionFilters.
   */
  facetsDiff_to_facets(storeKey: string, restoreSubscriptions: boolean = false): void {
    const _self = this;
    if(this.debug_cfn) { console.log(`%c azuresearch.service::facetsDiff_to_facets(storeKey ${storeKey}, ` +
      `restoreSubscriptions ${restoreSubscriptions}`, 'background: teal; color: white'); }

    // ----------------------------------------------------------------------
    //  re-apply select facets from facetsDiff
    // ----------------------------------------------------------------------

    // console.log(`azuresearch.service :: facetsDiff_to_facets() facetsDiff are:`);
    // console.log(JSON.parse(JSON.stringify(this.store[storeKey].facetsDiff.facets)));

    this.store[storeKey].facetsDiff.facets.forEach(function (facetGroup: any) {

      // need to also restore "facetsCombineUsingAnd"; allow existing filters to still work if property is missing
      if(facetGroup.hasOwnProperty('facetsCombineUsingAnd')) {
        let dstFacetGroup = _self.store[storeKey].facets.facets.find((o: any) => o.key === facetGroup.key);
        dstFacetGroup.facetsCombineUsingAnd = facetGroup.facetsCombineUsingAnd;
      }

      switch(facetGroup.type) {

        // handle checkbox facets
        case 'CheckboxFacet':
          let dstFacetGroup = _self.store[storeKey].facets.facets.find((o: any) => o.key === facetGroup.key);
          if (dstFacetGroup) {
            facetGroup.values.forEach(function (facet: any) {
              let dstFacetItem = dstFacetGroup.values.find((o: any) => o.value === facet.value);
              if (dstFacetItem) {
                dstFacetItem.selected = true;
              }
              // else {
              //   console.log(`%c azuresearch.service::facetsDiff_to_facets() COULD NOT RESTORE facet ${facet.value} on ${facetGroup.key}`, 'background: yellow; color: black');
              //   console.log(JSON.stringify(dstFacetGroup));
              // }
            });
          }
          break;

        // handle range facets
        case 'RangeFacet':
          switch(facetGroup.dataType) {

            case 'date':
              let dstFacetGroup = _self.store[storeKey].facets.facets.find((o: any) => o.key === facetGroup.key);
              if (dstFacetGroup) {
                dstFacetGroup = JSON.parse( JSON.stringify( facetGroup ) );
              }
              break;

            default:
              console.log(`azuresearch.service::facetsDiff_to_facets() unimplemented RangeFacet type ${facetGroup.dataType}`);
              break;

          }
          break;

      }

      // need to also restore "facetsCombineUsingAnd"; allow existing filters to still work if property is missing
      if(facetGroup.hasOwnProperty('facetsCombineUsingAnd')) {
        let dstFacetGroup = _self.store[storeKey].facets.facets.find((o: any) => o.key === facetGroup.key);
        dstFacetGroup.facetsCombineUsingAnd = facetGroup.facetsCombineUsingAnd;
      }

    });

    // ----------------------------------------------------------------------
    //  calculate selected facet totals
    // ----------------------------------------------------------------------

    let totalFacetCount = 0;
    this.store[storeKey].facetCount = {};
    this.store[storeKey].facets.facets.forEach(function (facetGroup: any) {

      let selectedFacetCount = 0;
      switch(facetGroup.type) {
        case 'CheckboxFacet':
          facetGroup.values.forEach(function (facet: any) {
            if (facet.selected) {
              selectedFacetCount += 1;
            }
          });
          break;
        case 'RangeFacet':
          switch(facetGroup.dataType) {
            case 'date':
              if(facetGroup.filterClause.length) {
                selectedFacetCount += 1;
              }
              break;
            default:
              break;
          }
          break;
      }
      _self.store[storeKey].facetCount[facetGroup.key] = selectedFacetCount;
      totalFacetCount += selectedFacetCount;

    });
    _self.store[storeKey].facetCount['total'] = totalFacetCount;

    // ----------------------------------------------------------------------
    //  restore subscriptions - not needed usually as subscriptions persist
    //  (it is necessary though when loading saved filters)
    // ----------------------------------------------------------------------

    if(restoreSubscriptions) {
      // console.log(`%c azuresearch.service::facetsDiff_to_facets(storeKey OVERWRITING subscriptionFilters`, 'background: red; color: white');
      // console.log(JSON.parse( JSON.stringify( this.store[storeKey].facetsDiff.subscriptionFilters ) ));

      // NOTE: we cannot blindly overwrite the filters as they may have changed since the filter was saved
      // this.store[storeKey].subscriptionFilters =
      //   JSON.parse( JSON.stringify( this.store[storeKey].facetsDiff.subscriptionFilters ) );

      this.subscription_filters_clear_all_active(storeKey);
      this.store[storeKey].facetsDiff.subscriptionFilters.filters.forEach(function (subFilter: any) {
        if(subFilter && subFilter.hasOwnProperty('active') && subFilter.active) {
          let dstFilter = _self.store[storeKey].subscriptionFilters.filters.find((o: any) => o.key === subFilter.key);
          if(dstFilter && dstFilter.hasOwnProperty('active')) {
            dstFilter.active = true;
            // console.log(`%c azuresearch.service::facetsDiff_to_facets(storeKey setting filter active true`, 'background: red; color: white');
          }
        }
      });
      this.set_subscription_filters_count(storeKey, -1);
    }

  }

  /**
   * Executes a search on a store.
   * @param {string} storeKey - The storeKey of the store to search.
   * @param {string} preserveFacets - Save facets to facetsDiff and restore after the search (we don't want this when loading filters!).
   * @param {boolean} showAllFacets - Show all facets - not just the ones returned in the search results (selected/counts are accurate).
   * @returns {Promise<AzssError>}
   */
  async search(storeKey: string, preserveFacets: boolean = true, showAllFacets: boolean = false): Promise<AzssError | null> {
    const _self = this;
    if(this.debug_cfn) { console.log(`%c azuresearch.service::search(storeKey ${storeKey}, preserveFacets ${preserveFacets}, showAllFacets ${showAllFacets})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    // console.log(`%c azuresearch.service::search(storeKey ${storeKey}, preserveFacets ${preserveFacets}, showAllFacets ${showAllFacets})`,
    //   'background: purple; color: white');

    if(this.store.hasOwnProperty(storeKey)) {

      // console.log(`azuresearch.service :: search() TOP OF search()`);
      // console.log( JSON.parse( JSON.stringify( this.store[storeKey] )));

      // ----------------------------------------------------------------------
      //  reset results
      // ----------------------------------------------------------------------

      // do not overwrite store if returning 0 results
      const countSaved: number = this.store[storeKey].results.count;
      const lastUpdatedSaved: any = this.store[storeKey].results.lastUpdated;

      this.store[storeKey].results.count = -1;
      this.store[storeKey].results.isFetching = true;
      this.store[storeKey].results.lastUpdated = 0;
      // this.store[storeKey].results.results = [];

      // ----------------------------------------------------------------------
      //  generate search parameters
      // ----------------------------------------------------------------------

      // searchParameters: {
      //   additionalFilters?: string;                         // additional filters to apply, ie: '(publishedDate ge 2023-06-21T00:00:00.374Z)'
      //   scoringProfile?: string,
      // },

      let searchObj: any = {
        includeTotalCount: true,
        count: true,
        orderBy: [''],
        searchFields: ['title,descriptionText,content,companies,stockTickers,regions,basins,plays,intervals,keywords,series,countries,subCategory'],
        skip: 0,
        top: 20,
        searchMode: 'any',
        queryType: 'simple',
        search: '',
        filter: '',
        featuresMode : "enabled",
        scoringStatistics : "global",
        speller: "lexicon",
        queryLanguage: "en-us",
        scoringProfile: ENV.SEARCH.scoringProfile,
        facets: []
      };

      // ----------------------------------------------------------------------
      //  build array of selected facets
      // ----------------------------------------------------------------------

      if( preserveFacets ) {
        // console.log(`*** preserveFacets ***`);
        // console.log( JSON.parse( JSON.stringify( this.store[storeKey] )));
        this.diff_facets(storeKey);
        // console.log( JSON.parse( JSON.stringify( this.store[storeKey] )));
      }
      // ELSE: do not overwrite facetsDiff with current facets when loading a filter!

      // add in all the facet clauses
      this.store[storeKey].facets.facets.forEach(function (item: any) {
        searchObj.facets.push( item.facetClause );
      });

      // overwrite searchObj parameters with any in parameters.searchParameters
      // console.log(`%c init_stores() this.azureSearchService.search()`, 'background: red; color: white');
      // console.log(this.store[storeKey].parameters.searchParameters);
      for (const property in this.store[storeKey].parameters.searchParameters) {
        if(searchObj.hasOwnProperty(property)) {
          searchObj[property] = this.store[storeKey].parameters.searchParameters[property];
        }
      }

      // console.log(searchObj);

      // ----------------------------------------------------------------------
      //  generate search filter
      // ----------------------------------------------------------------------

      // normal search (not loading a filter) - build search query from facets
      let searchFilter;
      if( preserveFacets ) {
        searchFilter = this.build_search_filter( storeKey, this.store[storeKey].facets.facets, showAllFacets, showAllFacets );

        // ELSE: loading a filter - need to build search query from facetsDiff as we cannot apply facetsDiff to
        //  facets yet as the correct facets don't get loading until after the azure search is complete
      } else {
        searchFilter = this.build_search_filter( storeKey, this.store[storeKey].facetsDiff.facets, showAllFacets, showAllFacets );
      }

      const filterSaved: string = this.store[storeKey].parameters.searchParameters.filter;

      // set/override the search filter
      searchObj.filter = searchFilter;
      this.store[storeKey].parameters.searchParameters.filter = searchFilter;

      // TEMPORARY: override max results on client models collection to prevent
      //  user requesting too many files to be zipped... will be removed in time
      if(storeKey == 'Downloads') {
        searchObj.top = 10;
      }

      let searchResults: any = null;
      if( !Constants.useRestSearchApi ) {

        // ----------------------------------------------------------------------
        //  perform the search - JS SDK
        // ----------------------------------------------------------------------

        // console.log(`*** SEARCH JAVASCRIPT SDK ***`);

        searchResults = await this.client[this.store[storeKey].config.clientKey].client.search(
          this.store[storeKey].parameters.input,      // search text
          searchObj);                                // search options

      } else {

        // ----------------------------------------------------------------------
        //  perform the search - REST API
        // ----------------------------------------------------------------------

        // console.log(`*** SEARCH REST API ***`);

        await this.dataService.azureSearchREST(searchObj).then(result => {
          searchResults = result.result;
        });

      }


      // ----------------------------------------------------------------------
      //  if 0 results, do not overwrite store and destroy current facets, etc
      // ----------------------------------------------------------------------

      this.store[storeKey].results.firstResultsFetched = true;

      if(searchResults.count === 0) {
        this.store[storeKey].results.noResults = true;
        this.store[storeKey].results.count = countSaved;
        this.store[storeKey].results.lastUpdated = lastUpdatedSaved;
        this.store[storeKey].parameters.searchParameters.filter = filterSaved;
        this.store[storeKey].results.isFetching = false;

        azssError = {errmsg: `Search selection (${storeKey}) returns 0 results. Correct your search criteria.`} as AzssError;

      } else {

        if( !Constants.useRestSearchApi ) {

          // console.log(`*** SEARCH JAVASCRIPT SDK ***`);

          // ----------------------------------------------------------------------
          //  store search results - JS SDK
          // ----------------------------------------------------------------------

          this.store[storeKey].results.noResults = false;
          this.store[storeKey].results.results = [];
          for await (const result of searchResults.results) {
            let doc = result.document
            doc['@search.score'] = result.score;
            this.store[storeKey].results.results.push(doc);
          }
          this.store[storeKey].results.count = searchResults.count;
          this.store[storeKey].results.isFetching = false;
          this.store[storeKey].results.lastUpdated = Date.now();
          this.store[storeKey].results.page = (searchObj.skip / searchObj.top) + 1;
          this.store[storeKey].results.perPage = searchObj.top;


          // ----------------------------------------------------------------------
          //  store facets - JS SDK
          // ----------------------------------------------------------------------

          // store names of each facet array (will be lost once facets object is converted to array)
          let facetNames: any = [];
          if (searchResults.hasOwnProperty('facets')) {
            for (const facet in searchResults.facets) {
              facetNames.push(facet);
            }
          }

          // convert the facets return object into an array (index corresponds to facetNames[] above)
          let resultsFacetArray = Object.keys(searchResults.facets).map(key => {
            return searchResults.facets[key];
          });

          // only populate facets in returned result set
          resultsFacetArray.forEach(function (resultsFacet, resultIndex) {

            // locate the corresponding array entry we need to update in the store
            let dstFacetGroup = _self.store[storeKey].facets.facets.find((o: any) => o.key === facetNames[resultIndex]);
            dstFacetGroup.values = [];

            if (dstFacetGroup.type == 'CheckboxFacet') {
              resultsFacet.forEach(function (resultsFacetItem: any) {
                if (resultsFacetItem.hasOwnProperty('value')) {
                  // {"count": 2, "value": "subscription:core"}
                  dstFacetGroup.values.push(resultsFacetItem);
                }
              });
            }
          });

        } else {

          // console.log(`*** SEARCH REST API ***`);

          // ----------------------------------------------------------------------
          //  store search results - REST API
          // ----------------------------------------------------------------------

          this.store[storeKey].results.noResults = false;
          this.store[storeKey].results.results = searchResults.value;
          this.store[storeKey].results.count = searchResults['@odata.count'];
          this.store[storeKey].results.isFetching = false;
          this.store[storeKey].results.lastUpdated = Date.now();
          this.store[storeKey].results.page = (searchObj.skip / searchObj.top) + 1;
          this.store[storeKey].results.perPage = searchObj.top;


          // ----------------------------------------------------------------------
          //  store facets - REST API
          // ----------------------------------------------------------------------

          // store names of each facet array (will be lost once facets object is converted to array)
          let facetNames: any = [];
          if (searchResults.hasOwnProperty('@search.facets')) {
            for (const facet in searchResults['@search.facets']) {
              facetNames.push(facet);
            }
          }

          // convert the facets return object into an array (index corresponds to facetNames[] above)
          let resultsFacetArray = Object.keys(searchResults['@search.facets']).map(key => {
            return searchResults['@search.facets'][key];
          });

          // only populate facets in returned result set
          resultsFacetArray.forEach(function (resultsFacet, resultIndex) {

            // locate the corresponding array entry we need to update in the store
            let dstFacetGroup = _self.store[storeKey].facets.facets.find((o: any) => o.key === facetNames[resultIndex]);
            dstFacetGroup.values = [];

            if (dstFacetGroup.type == 'CheckboxFacet') {
              resultsFacet.forEach(function (resultsFacetItem: any) {
                if (resultsFacetItem.hasOwnProperty('value')) {
                  // {"count": 2, "value": "subscription:core"}
                  dstFacetGroup.values.push(resultsFacetItem);
                }
              });
            }
          });

        }


        // ----------------------------------------------------------------------
        //  update the facetDiffs count(s) to match the actual results.
        // ----------------------------------------------------------------------

        if (preserveFacets) {
          this.store[storeKey].facetsDiff.facets.forEach(function (diffFacetGroup: any, diffIndex: any) {
            let resultFacetGroup = _self.store[storeKey].facets.facets.find((o: any) => o.key === diffFacetGroup.key);
            if (diffFacetGroup.type == 'CheckboxFacet') {
              diffFacetGroup.values.forEach(function (diffFacetItem: any) {
                if (diffFacetItem.hasOwnProperty('value')) {
                  let resultFacetItem = resultFacetGroup.values.find((o: any) => o.value === diffFacetItem.value);
                  if (resultFacetItem) {
                    diffFacetItem.count = resultFacetItem.count;
                  }
                }
              });
            }
          });
        }

        // ----------------------------------------------------------------------
        //  show all facets
        //  if enabled, populate facets array with all missing facets from
        //  facetsMaster array with count = 0
        //  (allows user to expand search/filtering)
        // ----------------------------------------------------------------------

        // if(showAllFacets) {

        // console.log(`*** showAllFacets ***`);

        this.store[storeKey].facets.facets.forEach(function (facet: any, facetIndex: any) {

          // console.log(`*** ${JSON.stringify(facet)}`);

          // either global showAllFacets ([ ] Show all filters), or facetsCombineUsingAnd per facet group
          if (showAllFacets || (facet.hasOwnProperty('facetsCombineUsingAnd') && !facet.facetsCombineUsingAnd)) {

            // console.log(`*** azuresearch.service::search() SHOWING ALL FACETS FOR ${facet.key}`);

            switch (facet.type) {

              // CheckboxFacet
              case 'CheckboxFacet':
                let masterFacetGroup = _self.store[storeKey].facetsMaster.facets.find((o: any) => o.key === facet.key);
                if (masterFacetGroup) {

                  // add back in any zero results facets (excluded from the search)
                  for (let i = 0; i < masterFacetGroup.values.length; i++) {
                    let match = facet.values.find((itmInner: any) => itmInner.value === masterFacetGroup.values[i].value);
                    if (!match) {
                      masterFacetGroup.values[i].selected = false; // none of these facets are selected!
                      facet.values.push(masterFacetGroup.values[i]);
                    }
                  }

                  // sort them so they are in the correct order
                  // facet.values.sort(function (a: any, b: any) {
                  //   if (a.value < b.value) {
                  //     return -1;
                  //   }
                  //   if (a.value > b.value) {
                  //     return 1;
                  //   }
                  //   return 0;
                  // });

                }
                break;
            }
          }

        });

        // }


        // ----------------------------------------------------------------------
        //  re-apply select facets from facetsDiff &&
        //    calculate selected facet totals
        // ----------------------------------------------------------------------

        // console.log(`azuresearch.service :: search() BEFORE this.facetsDiff_to_facets(storeKey)`);
        // console.log( JSON.parse( JSON.stringify( this.store[storeKey] )));

        this.facetsDiff_to_facets(storeKey);

        // console.log(`azuresearch.service :: search() AFTER to calling store.client.search`);
        // console.log( JSON.parse( JSON.stringify( this.store[storeKey] )));


        // ----------------------------------------------------------------------
        //  search results processor callback
        // ----------------------------------------------------------------------

        if (this.store[storeKey].results.hasOwnProperty('processor') &&
          typeof (this.store[storeKey].results.processor == 'function')) {
          this.store[storeKey].results.processor(storeKey, this.store[storeKey].results.results);
        }

        this.store[storeKey].facets.facets.forEach(function (facet: any, facetIndex: any) {
          facet.values.sort((a: any, b: any) => {
            if (a.selected !== b.selected) {
              return a.selected ? -1 : 1;
            }
            return a.value.localeCompare(b.value, undefined, {sensitivity: 'case', caseFirst: 'upper'});
          });
        });

        // this.store[storeKey].results.firstResultsFetched = true;

        // BUG: test angular change detection - poor for performance (needs Optimization)
        // should be no longer needed - passing Observables instead of [store] object
        // let newStore = JSON.parse(JSON.stringify( this.store[storeKey] ) );
        // newStore.results.processor = this.store[storeKey].results.processor;
        // newStore.config.clear_facets_processor = this.store[storeKey].config.clear_facets_processor;
        // this.store[storeKey] = newStore;

        // console.log(`azss::search(${storeKey})`);
        // this.dump_stores(storeKey);

      } // if 0 results, do not overwrite store and destroy current facets, etc

    } else {
      azssError = {errmsg: `search() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Update master facets (search with no facets) for use by Show All Filters.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @returns {AzssError}
   * @desc Note that callbacks are not fired for this search.
   */
  async update_master_facets_gf_subscription(storeKey: string): Promise<AzssError | null> {
    if (this.debug_cfn) {
      console.log(`%c azuresearch.service::update_master_facets_gf_subscription(storeKey ${storeKey}`, 'background: teal; color: white');
    }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      // only query facetsMaster if facetsMaster.facets is empty or globalfilter('subscription') has changed
      if(!this.store[storeKey].facetsMaster.facets.length ||
        ( JSON.stringify(this.store[storeKey].facets.globalFilters) != JSON.stringify(this.store[storeKey].facetsMaster.globalFilters) ) ) {

        // create temporary store
        let tmpStoreKey = uuidv4();
        this.store[tmpStoreKey] = JSON.parse(JSON.stringify(this.store[storeKey]));   // clone store

        // clear callbacks
        this.store[tmpStoreKey].config.clear_facets_processor = {};
        if (this.store[tmpStoreKey].results.hasOwnProperty('processor')) {
          delete this.store[tmpStoreKey].results.processor;
        }
        if (this.store[tmpStoreKey].suggestions.hasOwnProperty('processor')) {
          delete this.store[tmpStoreKey].suggestions.processor;
        }

        // clear facets but not subscriptions
        this.clear_all_facets(tmpStoreKey, false);

        // need to also clear any keywords
        this.set_input(tmpStoreKey, '');

        // perform the search, updated facetsMaster, delete temporary store
        await this.search(tmpStoreKey, false, false).then(() => {
          this.store[storeKey].facetsMaster.facets = JSON.parse(JSON.stringify(this.store[tmpStoreKey].facets.facets));
          this.store[storeKey].facetsMaster.globalFilters = JSON.parse(JSON.stringify(this.store[tmpStoreKey].facets.globalFilters));

          // add selected: false to all facetsMaster.facets checkbox values
          this.store[storeKey].facetsMaster.facets.forEach(function (facet: any, facetIndex: any) {
            switch(facet.type) {

              // CheckboxFacet
              case 'CheckboxFacet':
                facet.values.forEach(function (facetValue: any) {
                  facetValue['selected'] = false;
                  facetValue['count'] = 0;
                  // delete facetValue['count'];
                });
                break;
            }
          });

          delete this.store[tmpStoreKey];
        });

      }

    } else {
      azssError = {errmsg: `subscription_filters_clear_all_active() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  // ----------------------------------------------------------------------
  //   ____ _  _ ____ ____ ____ ____ ___
  //   [__  |  | | __ | __ |___ [__   |
  //   ___] |__| |__] |__] |___ ___]  |
  //
  // --::SUGGEST::---------------------------------------------------------

  /**
   * Queries the store for suggestions.
   * @param {string} storeKey - The storeKey of the store to search.
   * @returns {Promise<AzssError>}
   */
  async suggest(storeKey: string): Promise<AzssError | null> {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::search(storeKey ${storeKey}`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      if(this.store[storeKey].parameters.input.length) {

        // ----------------------------------------------------------------------
        //  reset suggestions
        // ----------------------------------------------------------------------

        this.store[storeKey].suggestions.isFetching = true;
        this.store[storeKey].suggestions.lastUpdated = 0;
        this.store[storeKey].suggestions.suggestions = [];

        // ----------------------------------------------------------------------
        //  generate suggestion parameters
        // ----------------------------------------------------------------------

        // let searchObj: any = {
        //   includeTotalCount: true,
        //   count: true,
        //   orderBy: [''],
        //   searchFields: ['title,descriptionText,content,companies,stockTickers,regions,basins,plays,intervals,keywords,series,countries,subCategory'],
        //   skip: 0,
        //   top: 20,
        //   searchMode: 'any',
        //   queryType: 'simple',
        //   search: '',
        //   filter: '',
        //   featuresMode : "enabled",
        //   scoringStatistics : "global",
        //   speller: "lexicon",
        //   queryLanguage: "en-us",
        //   scoringProfile: ENV.SEARCH.scoringProfile,
        //   facets: []
        // };

        let suggestObj: any = {};
        if(storeKey !== 'Downloads') {
          suggestObj = {
            filter: '',
            fuzzy: false,
            highlightPostTag: '</strong>',
            highlightPreTag: '<strong>',
            orderBy: ['publishedDate desc'],
            // searchFields: ['title,descriptionText,content,companies,stockTickers,regions,basins,plays,intervals,keywords,series,countries,subCategory,id'],
            searchFields: [],
            select: ['title', 'authors', 'descriptionText', 'acl', 'companies', 'series', 'id', 'publishedDate', 'category', 'subCategory', 'countries', 'regions'],
            scoringProfile: ENV.SEARCH.scoringProfile,
            suggesterName: 'sg',
            // select: ['title,descriptionText,content,companies,stockTickers,regions,basins,plays,intervals,keywords,series,countries,subCategory'],
            top: Constants.suggestTotal
          };
        } else {
          suggestObj = {
            filter: '',
            fuzzy: false,
            highlightPostTag: '</strong>',
            highlightPreTag: '<strong>',
            orderBy: ['publishedDate desc'],
            // searchFields: ['title,descriptionText,content,companies,stockTickers,regions,basins,plays,intervals,keywords,series,countries,subCategory,id'],
            searchFields: [],
            select: ['title', 'authors', 'acl', 'companies', 'series', 'id', 'publishedDate', 'category', 'subCategory', 'countries', 'regions', 'parentId', 'filename', 'filesize'],
            scoringProfile: ENV.SEARCH.scoringProfile,
            suggesterName: 'sg',
            // select: ['title,descriptionText,content,companies,stockTickers,regions,basins,plays,intervals,keywords,series,countries,subCategory'],
            top: Constants.suggestTotal
          };
        }

        // Option 1 (choose only 1): apply 'vault' (collection) globalFilter if present
        // if(this.store[storeKey].facets.globalFilters.hasOwnProperty('vault')) {
        //   if(this.store[storeKey].facets.globalFilters.vault.length) {
        //     suggestObj.filter = this.store[storeKey].facets.globalFilters.vault;
        //   }
        // }

        // Option 2 (choose only 1): apply all globalFilters and facets
        suggestObj.filter = this.build_search_filter( storeKey, this.store[storeKey].facets.facets );

        // overwrite suggestObj parameters with any in parameters.suggestParameters
        // console.log('overwrite parameters:', this.store[storeKey].parameters.suggestParameters);
        // for (const property in this.store[storeKey].parameters.suggestParameters) {
        //   if (suggestObj.hasOwnProperty(property)) {
        //     suggestObj[property] = this.store[storeKey].parameters.suggestParameters[property];
        //   }
        // }

        // ----------------------------------------------------------------------
        //  request the suggestions
        //  POST: https://rs-vault-dev.search.windows.net/indexes('rs-vault-dev')/docs/search.post.suggest?api-version=2020-06-30
        // ----------------------------------------------------------------------

        // console.log(JSON.stringify(suggestObj,null,2));

        const suggestResults = await this.client[this.store[storeKey].config.clientKey].client.suggest(
          this.store[storeKey].parameters.input,                                  // search term
          this.store[storeKey].parameters.suggestParameters.suggesterName,    // suggester name
          suggestObj);                                                            // suggest options

        // ----------------------------------------------------------------------
        //  store suggestions results
        // ----------------------------------------------------------------------

        this.store[storeKey].suggestions.isFetching = false;
        this.store[storeKey].suggestions.lastUpdated = Date.now();
        this.store[storeKey].suggestions.suggestions = [];
        for await (const result of suggestResults.results) {
          let doc = result.document
          doc['@search.text'] = result.text;
          this.store[storeKey].suggestions.suggestions.push( doc );
        }

        // ----------------------------------------------------------------------
        //  suggestions results processor callback
        // ----------------------------------------------------------------------

        if(this.store[storeKey].suggestions.hasOwnProperty('processor') &&
          typeof(this.store[storeKey].suggestions.processor == 'function') ) {
          this.store[storeKey].suggestions.processor( this.store[storeKey].suggestions.suggestions );
        }

      } else {
        azssError = {errmsg: `suggest() Cannot call suggest without a search term.`} as AzssError;
      }

    } else {
      azssError = {errmsg: `suggest() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }


  /**
   * Clear all active suggestions
   * @param {string} storeKey - The storeKey of the store to modify.
   * @returns {AzssError}
   */
  clear_suggestions(storeKey: string): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::clear_suggestions(storeKey ${storeKey})`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {

      if(this.store[storeKey].suggestions.hasOwnProperty('isFetching')) {
        this.store[storeKey].suggestions.isFetching = false;
      }

      if(this.store[storeKey].suggestions.hasOwnProperty('suggestions')) {
        this.store[storeKey].suggestions.suggestions = [];
      }

    } else {
      azssError = {errmsg: `clear_suggestions() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }


  // ----------------------------------------------------------------------
  //   ____ _  _ ____ ___ ____ _  _
  //   |    |  | [__   |  |  | |\/|
  //   |___ |__| ___]  |  |__| |  |
  //
  // --::CUSTOM::----------------------------------------------------------

  /**
   * Set a custom variable on a store.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} key - The key to reference the custom var by.
   * @param {any} val - The value to store.
   * @returns {AzssError}
   */
  custom_var_set( storeKey: string, key: string, val: any ): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::custom_var_set(storeKey ${storeKey}, ` +
      `key ${key}, val ${val}`, 'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      if(key && key.length) {
        this.store[storeKey].custom[key] = val;

      } else {
        azssError = {errmsg: `custom_var_set() Reference key must be a non-empty string.`} as AzssError;
      }

    } else {
      azssError = {errmsg: `custom_var_set() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  /**
   * Get a custom variable from a store.
   * @param {string} storeKey - The storeKey of the store to query.
   * @param {string} key - The key to reference the custom var by.
   * @returns {any} The custom var value.
   */
  custom_var_get( storeKey: string, key: string ): any {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::custom_var_get(storeKey ${storeKey}, key ${key}`,
      'background: teal; color: white'); }
    let retVal: any = null;

    if(this.store.hasOwnProperty(storeKey)) {
      if(key && key.length) {
        if( this.store[storeKey].custom.hasOwnProperty(key) ) {
          retVal = this.store[storeKey].custom[key];
        }
      }
    }

    return retVal;
  }

  /**
   * Remove a custom variable from a store.
   * @param {string} storeKey - The storeKey of the store to modify.
   * @param {string} key - The key to reference the custom var by.
   * @returns {AzssError}
   */
  custom_var_remove( storeKey: string, key: string ): AzssError | null {
    if(this.debug_cfn) { console.log(`%c azuresearch.service::custom_var_remove(storeKey ${storeKey}, key ${key}`,
      'background: teal; color: white'); }
    let azssError: AzssError | null = null;

    if(this.store.hasOwnProperty(storeKey)) {
      if(key && key.length) {
        if( this.store[storeKey].custom.hasOwnProperty(key) ) {
          delete this.store[storeKey].custom[key];

        } else {
          azssError = {errmsg: `custom_var_remove() Store key "${key}" to remove not found.`} as AzssError;
        }

      } else {
        azssError = {errmsg: `custom_var_remove() Store key to remove not provided..`} as AzssError;
      }

    } else {
      azssError = {errmsg: `custom_var_remove() Store key "${storeKey}" is invalid.`} as AzssError;
    }

    return azssError;
  }

  // ----------------------------------------------------------------------
  //   ___  ____ ___  _  _ ____
  //   |  \ |___ |__] |  | | __
  //   |__/ |___ |__] |__| |__]
  //
  // --::DEBUG::-----------------------------------------------------------

  /**
   * Enable/disable function name console logging.
   * @param {boolean} enable_debug_cfn - Enable or disable.
   */
  set_debug_cfn(enable_debug_cfn: boolean) {
    this.debug_cfn = enable_debug_cfn;
  }

  /**
   * Dump client(s) to console.
   */
  dump_clients(): void {
    // if(this.debug_cfn) { console.log(`%c azuresearch.service::dump_clients()`, 'background: orange; color: white'); }

    console.log( this.client );
  }

  /**
   * Dump store(s) to console (serialized to prevent post-log changing).
   * @param {string} storeKey - The storeKey of the store to dump (optional); else all.
   * @param {boolean} expanded - Whether to expand the output with JSON.stringify() (optional).
   * @desc Note that callbacks do not appear in serialized output!
   */
  dump_stores(storeKey?: string, expanded?: boolean): void {
    // if(this.debug_cfn) { console.log(`%c azuresearch.service::dump_stores()`, 'background: orange; color: white'); }

    if((storeKey !== 'undefined') && this.store.hasOwnProperty(storeKey)) {
      if(expanded) {
        console.log( JSON.stringify( this.store[storeKey!], null, 2) );
      } else {
        console.log( JSON.parse( JSON.stringify( this.store[storeKey!] ) ) );
      }

      // show callbacks unserialized
      // console.log(`dump_stores() this.store[${storeKey}].config.clear_facets_processor:` );
      // console.log(this.store[storeKey].config.clear_facets_processor);

    } else {
      console.log( JSON.parse( JSON.stringify( this.store ) ) );
    }

  }

  dump_search_kws(): void {
    // console.log(this.store);
    // this.store.forEach(function (store: any) {
    Object.keys(this.store).forEach((key: string) => {
      let store = this.store[key];
      console.log(`${store.collection}: q == [${store.parameters.input}]`);
    });
  }

  get_store(key: string): any
  {
    // if(this.debug_cfn) { console.log(`%c azuresearch.service::get_store()`, 'background: teal; color: white'); }
    return this.store[key];
  }

}
