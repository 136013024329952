import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../../services/data/data.service';
import {UserService} from '../../services/user/user.service';
import {LogService} from '../../services/log/log.service';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'iv-contact-analyst-dialog',
  standalone: true,
  templateUrl: './contact-analyst-dialog.component.html',
  styleUrls: ['./contact-analyst-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatIconModule
  ]
})
export class ContactAnalystDialogComponent {
  contactAnalystForm = new FormGroup({
    id: new FormControl(this.data.id),
    subject: new FormControl({value: this.data.title, disabled: true}, [Validators.required]),
    key: new FormControl(this.data.key),
    authorName: new FormControl(null),
    authorEmail: new FormControl(null),
    description: new FormControl(null, [Validators.required])
  });

  error = false;
  success = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ContactAnalystDialogComponent>, private dataService: DataService, private userService: UserService, private logService: LogService) {
    if (this.data.primaryAuthor) {
      if (this.data.primaryAuthor.displayName) {
        this.contactAnalystForm.patchValue({
          authorName: this.data.primaryAuthor.displayName,
        });
      }
      if (this.data.primaryAuthor.emailAddress) {
        this.contactAnalystForm.patchValue({
          authorEmail: this.data.primaryAuthor.emailAddress,
        });
      }
    }
  }

  get f() {
    return this.contactAnalystForm.controls;
  }

  onSubmit() {
    if (this.contactAnalystForm.valid) {
      this.dataService.contactAnalyst(this.contactAnalystForm.getRawValue()).subscribe({
        next: (v) => {

          this.logService.track('contact_analyst_requested', false, {
            article_id: this.data.id,
            article_key: this.data.key,
            article_title: this.data.title,
            article_series: this.data.series,
            article_type: this.data.projectType,
            article_collection: this.data.collection,
            article_primary_author: (this.data.primaryAuthor) ? (this.data.primaryAuthor.displayName) : '',
            article_supporting_authors: this.data.authors,
            article_published_date: this.data.publishedDate,
          });

          this.logService.logPendo('Contact Analyst', {
            id: this.data.id,
            key: this.data.key,
            title: this.data.title,
            series: this.data.series,
            type: this.data.projectType,
            collection: this.data.collection,
            primaryAuthor: (this.data.primaryAuthor) ? (this.data.primaryAuthor.displayName) : '',
            authors: this.data.authors,
            publishedDate: this.data.publishedDate,
          });

          this.error = false;
          this.success = true;
        },
        error: (e) => {
          this.success = false;
          this.error = true;
        },
      });
    }
  }

  closeContactAnalystDialog() {
    this.dialogRef.close();
  }
}

