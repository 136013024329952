<div class="iv-prompt-input">
  <div class="iv-prompt-input__form-row">
    <div class="iv-prompt-input__form-wrapper">
      <form autocomplete="off">
        <label>
        <textarea ivAutoExpand
                  [formControl]="textareaControl"
                  class="iv-prompt-input__text-area" rows="1" enterkeyhint="send"
                  autocapitalize="off" autocomplete="off" maxlength="4000"
                  placeholder="Type something..." autofocus=""
                  [ivReadOnly]="!allowedFeature"
                  (keyup.enter)="allowedFeature && !lockInput && textareaControl.value && logInput(textareaControl.value) && emitPrompt.emit(textareaControl.value)"></textarea>
        </label>
      </form>
    </div>
    <mat-icon [matTooltip]="lockInput ? 'Character limit exceeded - shorten your question.' : 'Send message'" [class.iv-prompt-input__action-button]="!lockInput" [class.iv-prompt-input__action-button-locked]="lockInput" (click)="allowedFeature && !lockInput && textareaControl.value && emitPrompt.emit(textareaControl.value)">play_circle</mat-icon>
  </div>
  <ng-content></ng-content>
</div>
