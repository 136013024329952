import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from "@angular/material/icon";
import {MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {LogService} from "../../services/log/log.service";

@Component({
  standalone: true,
  selector: 'iv-chatbot-disclosure',
  templateUrl: './chatbot-disclosure.component.html',
  styleUrls: ['./chatbot-disclosure.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatIconModule
  ]
})
export class ChatbotDisclosureComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<ChatbotDisclosureComponent>,
              private logService:LogService) {

  }

  closeDisclosureDialog() {
    this.dialogRef.close();
  }

}
