import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LivefeedNavService {

  private livefeedNavAzssDataSubject = new BehaviorSubject<any>(null);
  livefeedNavAzssData$ = this.livefeedNavAzssDataSubject.asObservable();

  constructor() { }

  setLivefeedNavAzssData(data: any) {
    this.livefeedNavAzssDataSubject.next(data);
  }

}
