import { EventEmitter, Injectable, Output } from '@angular/core';
import merge from 'deepmerge'

import jwtDecode from "jwt-decode";

import {DataService} from "../data/data.service";
import {Observable, ReplaySubject} from "rxjs";

import {Favorite} from "../../interface/favorite";
import {LogService} from '../log/log.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  favoritesDb: any = null;
  favoritesInited: boolean = false;

  preferences: any = {
    id: 0,
    favorites: [],
    recentlyViewed: [],
    theme: 'dark',
    search:
      {
        collection: "Research",
        searchMode: "any",
        top: "20",
        queryType: "simple",
        orderby: "",
        fields: {
          "Title": {field: "title", checked: true},
          "Description": {field: "descriptionText", checked: true},
          "PDF Content": {field: "content", checked: true},
          'Tags': {field: 'companies,stockTickers,regions,basins,plays,intervals,keywords', checked: true}
        }
      },
    searchFiltersOpen: true,
    notice: {
      date: null,
      viewed: false
    }
  };

  private userFavoritesSubject: ReplaySubject<Favorite[]> = new ReplaySubject<Favorite[]>(1);
  userFavorites$: Observable<Favorite[]> = this.userFavoritesSubject.asObservable();

  @Output() userProfile: EventEmitter<any> = new EventEmitter();
  @Output() userPreferences: EventEmitter<any> = new EventEmitter();

  // ------------------------------------------------------------------------
  //  debug settings
  // ------------------------------------------------------------------------

  debug_cfn: boolean = false;        // show function call console debug info

  constructor(private dataService: DataService, private logService:LogService) {
    if (this.debug_cfn) { console.log(`%c user.service::constructor()`, 'background: aqua; color: black'); }

    try {
      this.loadUserPreferences();
    } catch (e) {
    }
  }

  public initFavorites() {
    const _self = this;
    if (this.debug_cfn) { console.log(`%c user.service::initFavorites()`, 'background: aqua; color: black'); }

    // {
    //   "tsModified": 1664991594,
    //   "userId": "samlp|Enverus-IDP|Lorne.Chartier@drillinginfo.com",
    //   "title": "Enverus Intelligence Podcast | Ukraine War",
    //   "id": "61514",
    //   "favorite_id": 20,
    //   "collection": "Podcast"
    // }
    try {
      this.dataService.getFavorites().then(function (favorites) {
        favorites.result.favorites.forEach(function (favorite: any) {
          favorite.favorite_id = favorite.id;
          favorite.settings = JSON.parse( favorite.settings );
          for(const key of Object.keys(favorite.settings)){
            favorite[key] = favorite.settings[key];
          }
          delete favorite.settings;
        });

        // sort by title
        favorites.result.favorites.sort(function(a: any, b: any) {
          if ( a.title < b.title ){
            return -1;
          }
          if ( a.title > b.title ){
            return 1;
          }
          return 0;
        });

        _self.favoritesDb = favorites.result.favorites;
        _self.userFavoritesSubject.next(_self.favoritesDb);
      });
    } catch (e) {
    }

  }

  public setUserProfile(profile: any) {
    if (this.debug_cfn) { console.log(`%c user.service::setUserProfile()`, 'background: aqua; color: black'); }
    sessionStorage.setItem('profile', JSON.stringify(profile));
  }

  public getUserProfile(): any {
    if (this.debug_cfn) { console.log(`%c user.service::getUserProfile()`, 'background: aqua; color: black'); }
    try {
      return JSON.parse(sessionStorage.getItem('profile') || '{}');
    } catch (e) {
    }
  }

  public updateUserProfile() {
    if (this.debug_cfn) { console.log(`%c user.service::updateUserProfile()`, 'background: aqua; color: black'); }
    this.dataService.authorize().subscribe(authorize => {
      this.setUserProfile(authorize.authorization.profile);
    });
  }

  public setUserPermissions(permissions: any) {
    if (this.debug_cfn) { console.log(`%c user.service::setUserPermissions()`, 'background: aqua; color: black'); }

    // DEBUG: set only specific permissions for Lorne.Chartier@drillinginfo.com
    // if(permissions.user.azId === 'samlp|Enverus-IDP|Lorne.Chartier@drillinginfo.com') {
    //   permissions.permissions = permissions.permissions = ['acl:intelligence-publications','acl:activity-reports','GENAI_CHATBOT','GENAI_CHATBOT_INTERNAL','GENAI_CHATBOT_SYNAPSE_APP'];
    //   permissions.user.permissions = permissions.user.permissions = ['acl:intelligence-publications','acl:activity-reports','GENAI_CHATBOT','GENAI_CHATBOT_INTERNAL','GENAI_CHATBOT_SYNAPSE_APP'];
    // }

    // DEBUG: turn off chatbot permissions for Lorne.Chartier@drillinginfo.com
    // if(permissions.user.azId === 'samlp|Enverus-IDP|Lorne.Chartier@drillinginfo.com') {
    //   const removeAcls: string[] = ['GENAI_CHATBOT', 'GENAI_CHATBOT_INTERNAL', 'GENAI_CHATBOT_SYNAPSE_APP'];
    //   permissions.permissions = permissions.permissions.filter((item: any) => !removeAcls.includes(item));
    //   permissions.user.permissions = permissions.user.permissions.filter((item: any) => !removeAcls.includes(item));
    // }

    sessionStorage.setItem('permissions', JSON.stringify(permissions));
  }

  public getUserPermissions() {
    if (this.debug_cfn) { console.log(`%c user.service::getUserPermissions()`, 'background: aqua; color: black'); }
    try {
      return JSON.parse(sessionStorage.getItem('permissions') || '{}');
    } catch (e) {
    }
  }

  public getAcl() {
    if (this.debug_cfn) { console.log(`%c user.service::getAcl()`, 'background: aqua; color: black'); }
    return this.getUserPermissions().permissions;
  }

  public getResearchUser() {
    if (this.debug_cfn) { console.log(`%c user.service::getResearchUser()`, 'background: aqua; color: black'); }
    const researchAcls = ['acl:na-onshore-research', 'acl:power-research', 'acl:global-research', 'acl:macro-research']
    const acl = this.getAcl();
    return researchAcls.some(i => acl.includes(i));
  }

  public getPreferences() {
    if (this.debug_cfn) { console.log(`%c user.service::getPreferences()`, 'background: aqua; color: black'); }
    const userProfile = this.getUserProfile();
    if (userProfile && userProfile.email) {
      const email: string = userProfile.email;
      if (JSON.parse(localStorage.getItem(`${email}-v01`) || '{}')) {
        const overwriteMerge = (destinationArray: any, sourceArray: any, options: any) => sourceArray;
        this.preferences = merge(this.preferences, JSON.parse(localStorage.getItem(`${email}-v01`) || '{}'), {arrayMerge: overwriteMerge});

        //legacy remove in future
        if (this.preferences.collection) {
          this.preferences.search.collection = this.preferences.collection;
          delete this.preferences.collection;
        }
        if (this.preferences.orderBy) {
          this.preferences.search.orderby = (this.preferences.orderBy === 'relevance') ? '' : this.preferences.orderBy;
          delete this.preferences.orderBy;
        }
        if (this.preferences.search) {
          if (this.preferences.search.searchMode === 'any' && (this.preferences.notice && !this.preferences.notice.date)) {
            this.preferences.search.searchMode = 'all';
            this.preferences.notice.date = new Date();
          }
          if (this.preferences.search.fields.Tags.field === 'companies') {
            this.preferences.search.fields.Tags.field = 'companies,stockTickers,regions,basins,plays,intervals,keywords';
          }
        }

        this.setPreferences();
        this.userPreferences.emit(this.preferences);

        if(!this.favoritesInited) {
          this.favoritesInited = true;
          this.initFavorites();
        }

        return this.preferences;
      } else {
        this.userPreferences.emit(this.preferences);
        return this.preferences;
      }
    } else {
      this.userPreferences.emit(this.preferences);
      return this.preferences;
    }
  }

  public setPreferences(preferences?: any) {
    if (this.debug_cfn) { console.log(`%c user.service::setPreferences()`, 'background: aqua; color: black'); }
    if (preferences) {
      const overwriteMerge = (destinationArray: any, sourceArray: any, options: any) => sourceArray;
      this.preferences = merge(this.preferences, preferences, {arrayMerge: overwriteMerge});
    }

    const userProfile = this.getUserProfile();
    if (userProfile && userProfile.email) {
      const email = userProfile.email;
      localStorage.setItem(email + '-v01', JSON.stringify(this.preferences));
      this.userPreferences.emit(this.preferences);
    }
  }

  public loadUserPreferences() {
    if (this.debug_cfn) { console.log(`%c user.service::loadUserPreferences()`, 'background: aqua; color: black'); }
    this.getPreferences();
  }

  public toggleFavorite(favorite: any) {
    if (this.debug_cfn) { console.log(`%c user.service::toggleFavorite()`, 'background: aqua; color: black'); }
    const _self = this;
    if (!this.favoritesDb.find((fav: any) => fav.id == favorite.id)) {
      this.dataService.saveFavorite(favorite.id, favorite.title, {"id": favorite.id, "collection": favorite.collectionType}).then(function (saved) {
        _self.favoritesDb.push({id: favorite.id, favorite_id: saved.result.id, title: favorite.title, collection: favorite.collectionType, tsModified: Date.now()});

        // sort by title
        _self.favoritesDb.sort(function(a: any, b: any) {
          if ( a.title < b.title ){
            return -1;
          }
          if ( a.title > b.title ){
            return 1;
          }
          return 0;
        });

        _self.userFavoritesSubject.next(_self.favoritesDb);
      });

      this.logService.article = favorite;
      if(!favorite.hasOwnProperty('favorite.primaryAuthor') || !favorite.primaryAuthor.length) {
        this.logService.track("bookmark_saved", true,{ authors: favorite.authors });
      } else {
        this.logService.track("bookmark_saved", true,{});
      }
      // console.log(JSON.stringify(favorite,null,2));

      this.logService.logPendo('Bookmark Saved', {
        id: favorite.id,
        key: favorite.key,
        title: favorite.title,
        series: favorite.series,
        type: favorite.projectType,
        collection: favorite.collection,
        primaryAuthor: (favorite.primaryAuthor) ? (favorite.primaryAuthor.displayName) : '',
        authors: favorite.authors,
        publishedDate: favorite.publishedDate
      });
    } else {
      let index = this.favoritesDb.findIndex((fav: any) => fav.id == favorite.id);
      this.dataService.deleteFavorite(this.favoritesDb[index].favorite_id).then(function () {
        _self.favoritesDb.splice(index, 1);
        _self.userFavoritesSubject.next(_self.favoritesDb);
      });

      this.logService.article = favorite;
      if(!favorite.hasOwnProperty('favorite.primaryAuthor') || !favorite.primaryAuthor.length) {
        this.logService.track("bookmark_deleted", true,{ authors: favorite.authors });
      } else {
        this.logService.track("bookmark_deleted", true,{});
      }

      this.logService.logPendo('Bookmark Deleted', {
        id: favorite.id,
        key: favorite.key,
        title: favorite.title,
        series: favorite.series,
        type: favorite.projectType,
        collection: favorite.collection,
        primaryAuthor: (favorite.primaryAuthor) ? (favorite.primaryAuthor.displayName) : '',
        authors: favorite.authors,
        publishedDate: favorite.publishedDate
      });
    }
  }

  public addRecentlyViewed(recent: any) {
    if (this.debug_cfn) { console.log(`%c user.service::addRecentlyViewed()`, 'background: aqua; color: black'); }
    if (!this.preferences.recentlyViewed.find((rec: any) => rec.id == recent.id)) {
      this.preferences.recentlyViewed.unshift({id: recent.id, title: recent.title, collectionType: recent.collectionType});
      this.preferences.recentlyViewed.length = Math.min(this.preferences.recentlyViewed.length, 5)
    }
    this.setPreferences();
  }

  // Favorites array:
  // [
  //   {
  //     "id": "68649",
  //     "title": "Notes from Tall City",
  //     "collectionType": "Research"
  //     "favoritesId": "99"  <-- add this, the mysql db id for the record!
  //   }
  // ]

  public isFavorite(id: number) {
    if (this.debug_cfn) { console.log(`%c user.service::isFavorite()`, 'background: aqua; color: black'); }
    if(this.favoritesDb !== null) {
      if (this.favoritesDb.find((fav: any) => fav.id == id)) {
        return true;
      }
    }
  }

  public isApplicationAvailable(securityGroup: any) {
    if (this.debug_cfn) { console.log(`%c user.service::isApplicationAvailable()`, 'background: aqua; color: black'); }
    const permissionsObject = this.getUserPermissions();

    let permissions = [];

    if (permissionsObject && permissionsObject.permissions) {
      permissions = permissionsObject.permissions;
    }

    let access = false;
    if (securityGroup === null) {
      access = true;
    }

    if (securityGroup && permissions && permissions.length > 0) {
      access = permissions.some(function (permission: any) {
        if (permission.product === securityGroup) {
          return true;
        }
      });
    }
    return access;
  }

  public getFavorites() {
    if (this.debug_cfn) { console.log(`%c user.service::getFavorites()`, 'background: aqua; color: black'); }
    return( this.favoritesDb );
  }

  public getIdTokenParam(param: string): string {
    if (this.debug_cfn) { console.log(`%c user.service::getIdTokenParam()`, 'background: aqua; color: black'); }
    let result: string = '';
    let accessToken : string | null = sessionStorage.getItem('id_token');
    if(accessToken !== null) {
      const jwtdecoded: any = jwtDecode(accessToken);
      if(jwtdecoded.hasOwnProperty(param)) {
        result = jwtdecoded[param];
      }
    }
    return(result);
  }

}
