import {Component, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DataService} from '../../services/data/data.service';
import {DashboardService} from '../../services/dashboard/dashboard.service';
import {SearchService} from '../../services/search/search.service';
import {UserService} from '../../services/user/user.service';
import {LogService} from '../../services/log/log.service';

@Component({
  selector: 'iv-help',
  standalone: true,
  imports: [CommonModule, MatIconModule, MatTooltipModule],
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpComponent {

  constructor(
    private router: Router,
    private logService: LogService
  ) {
  }

  public routeToDashboard(): void {
    this.router.navigate(['/dashboard'], {});
  }
}


