<div class="iv-rating" *ngIf="isOpen">
  <div class="iv-rating__panel">
    <div class="iv-rating__title">
      Why did you choose this rating? (optional)
      <mat-icon (click)="closePanel()" class="iv-chatbot-rating-close">close</mat-icon>
    </div>
    <div class="iv-rating__bubble-options">
      <ng-container *ngFor="let option of ratingOptions.optionList">
        <div class="iv-rating__bubble" [ngClass]="{ 'selected': selectedBubbles.has(option) }" (click)="toggleOptionSelected(option)">
          {{ option }}
        </div>
      </ng-container>
    </div>
    <textarea placeholder="Provide any additional feedback here"
              class="iv-chatbot-rating-textarea"
              [(ngModel)]="comment"
    ></textarea>
    <div class="iv-chatbot-rating-warning">
      Please be aware that all feedback submitted can be seen by the
      Enverus support team so should not contain any private or
      confidential information
    </div>
    <button class="iv-chatbot-rating-submit" (click)="submitRating(message)">
      Submit
    </button>
  </div>
</div>
