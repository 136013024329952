import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {inject} from '@angular/core';
import {DataService} from '../../services/data/data.service';
import {catchError, of} from 'rxjs';

export const InteractiveReportResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot
) => {
  const id = route.paramMap.get('docId');
  return inject(DataService).getInteractiveReport(id).pipe(
    catchError((error) => {
      let errorPage = '/error';
      switch (error.status) {
        case 404:
          errorPage = '/not-found';
          break;
        case 403:
          errorPage = '/unauthorized';
          break;
        default:
          break;
      }
      inject(Router).navigate([errorPage], {skipLocationChange: true});
      return of(error);
    })
  );
};
