import {Component, Input} from '@angular/core';
import {TokenIndicatorPipe} from "./token-indicator.pipe";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {Constants} from "../../../constants/constants";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'iv-token-tracker',
  templateUrl: './token-tracker.component.html',
  styleUrls: ['./token-tracker.component.scss'],
  standalone: true,
  imports: [TokenIndicatorPipe, MatProgressBarModule, MatIconModule]
})
export class TokenTrackerComponent {
  _usedToken = 0;
  maxToken = Constants.chatbotMaxChars;
  usedTokenPercentage = 0;

  @Input() set usedToken(usedToken: number) {
    this._usedToken = usedToken
    this.usedTokenPercentage = this._usedToken / this.maxToken * 100;
  }
}
