import { Directive } from '@angular/core';
import {Message} from "../chat.service";
import {ChatbotComponent} from "../chatbot.component";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[prompt-actions]',
  standalone: true,
  exportAs: 'd'
})
export class PromptActionsDirective {
  retryPrompt(chatbotComponent: ChatbotComponent, promptId: string) {
    const { messageList } = chatbotComponent;
    const position = messageList.findIndex(({prompt_id}) => prompt_id === promptId);
    const previousMessage = messageList[position - 1];
    if (previousMessage) {
      const { user_input } = previousMessage;
      chatbotComponent.sendUserMessage(user_input);
    }
  }

}
