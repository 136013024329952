// NOTE: not really feasible to make app.component a standalone module in conjunction with Angular Universal
//    in Angular 15 without doing some hacky stuff...
// https://dev.to/zenika/angular-universal-with-standalone-component-206c
// https://stackoverflow.com/questions/75639838/rendering-the-angular-appcomponent-as-standalone

import {Component, Inject, OnInit, OnDestroy, PLATFORM_ID, ViewEncapsulation} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {AuthService, GenericError} from '@auth0/auth0-angular';
import {Router, NavigationEnd} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';

import {MatIconRegistry} from '@angular/material/icon';

import {Subscription} from 'rxjs';
import {filter, mergeMap} from 'rxjs/operators';

import {ResearchStore} from './store/research-store/research.store';
import {ScriptService} from './services/script/script.service';
import {environment} from '../environments/environment';
import {LogService} from './services/log/log.service';

@Component({
  selector: 'intelligence-vault',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ResearchStore, MatIconRegistry],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  shouldRenderNavbar: boolean = true;
  private authErrorSubscription: Subscription = Subscription.EMPTY;
  private routerSubscription: Subscription = Subscription.EMPTY;

  constructor(iconReg: MatIconRegistry,
              sanitizer: DomSanitizer,
              @Inject(PLATFORM_ID) private platformId: object,
              private router: Router,
              private auth: AuthService,
              private script: ScriptService,
              private logService: LogService) {

    iconReg.addSvgIcon(
      'rseg',
      sanitizer.bypassSecurityTrustResourceUrl('assets/enverus-logo-white.svg'));

    iconReg.addSvgIcon(
      'back',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/back.svg'));

    iconReg.addSvgIcon(
      'download',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/download.svg'));

    iconReg.addSvgIcon(
      'calendar',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/calendar.svg'));

    iconReg.addSvgIcon(
      'relevance',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/relevance.svg'));

  }

  ngOnInit() {
    if (!isPlatformServer(this.platformId)) {
      // this.authErrorSubscription = this.auth.error$.pipe(
      //   filter((e) => e instanceof GenericError && e.error === 'login_required'),
      //   mergeMap(() => this.auth.loginWithRedirect())
      // ).subscribe();

      if (!environment.production) {
        this.script.load('jirafeedback').then(data => {
          // console.log('script loaded ', data);
        }).catch(error => console.log(error));
      }
    }

    // Check if the current environment is the server-side rendering (SSR) environment
    if (isPlatformServer(this.platformId)) {
      // This is SSR, so set shouldRenderNavbar to false
      this.shouldRenderNavbar = false;
    }

    // Use router events to check for navigation to SSR routes on the client-side
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Check the current route's URL to determine if it's an SSR route
        const currentRoute: string = this.router.routerState.snapshot.url;
        if (currentRoute.startsWith('/summary/livefeed/')) {
          this.shouldRenderNavbar = false; // Hide the navbar for SSR route
        } else {
          this.shouldRenderNavbar = true; // Show the navbar for other routes
        }
        this.logService.page(event.url);
      }
    });

  }

  ngOnDestroy() {
    if (this.authErrorSubscription !== Subscription.EMPTY) {
      this.authErrorSubscription.unsubscribe();
    }
    if (this.routerSubscription !== Subscription.EMPTY) {
      this.routerSubscription.unsubscribe();
    }
  }

}
