
interface FacetsDiff {
  facets: any[];                            // Define the type of facets as needed
}
export interface ActiveFilter {
  id:                       number;         // id in database (if editing saved filter, else 0)
  title:                    string;         // title (tied to ui input box)
  titleOriginal:            string;         // title before any edits
  filterModified:           boolean;        // filter has edits (facets or title)
  collection:               string;         // the collection the filter applies to
  facetsDiff:               FacetsDiff;     // facets at time of creation
  prettyText?:              string;         // facets applied in human readable form
  isDefault?:               boolean;        // is this filter the default for the pane?
  q?:                       string;         // the saved keyword parameter for the filter
  qOriginal?:               string;         // the keyword parameter before any edits
  active?:                  boolean;
  dateRangeEdited?:         boolean;        // user has edited the date range facet
  userId?:                  string;         // user who owns the filter
  isGlobal?:                boolean;        // is a global filter
  [key: string]:            any;            // Index signature to allow dynamic keys of type string
}

export function createEmptyActiveFilter(): ActiveFilter {
  return {
    id: 0,
    title: '',
    titleOriginal: '',
    filterModified: false,
    collection: '',
    facetsDiff: {
      facets: []
    },
    prettyText: '',
    isDefault: false,
    q: '',
    qOriginal: '',
    active: false,
    dateRangeEdited: false,
    userId: '',
    isGlobal: false,
  };
}
