import { Directive } from '@angular/core';
import {Message} from "../chat.service";
import {MatSnackBar} from "@angular/material/snack-bar";

const COPIED_MESSAGE_DURATION = 1500;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[message-clipboard]',
  standalone: true,
  exportAs: 'd'
})
export class MessageClipboardDirective {
  constructor(
    private snackBar: MatSnackBar
  ) { }

  copyMessage(message: Message) {
    const { user_input } = message;
    navigator.clipboard
      .writeText(user_input)
      .then(() => this.snackBar.open('Copied to clipboard', undefined, {
        duration: COPIED_MESSAGE_DURATION,
        panelClass: 'iv-clipboard-icon__message'
      }))
      .catch((error) => console.error('Unable to copy text to clipboard', error));
  }

}
