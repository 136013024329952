// ------------------------------------------------------------------------------------------------
//  SavedFiltersService:
//
//  Provides methods to load, save, clone filter (facet) collections per store.
//
// ------------------------------------------------------------------------------------------------

import { EventEmitter, Injectable, Input, Output } from '@angular/core'; // OnInit

import {BehaviorSubject, Observable, ReplaySubject, Subscription, toArray} from "rxjs";
import { map, take } from "rxjs/operators";
import {v4 as uuidv4} from 'uuid';

import {environment as ENV} from '../../../environments/environment';
import { Constants } from "../../constants/constants";
const livefeedStores: string[] = Constants.LivefeedStores;
const reportsStores: string[] = Constants.ReportsStores;

import { AzureSearchService } from "../azuresearch/azuresearch.service";
import { DataService } from "../data/data.service";
import { LogService } from "../log/log.service";
import { SearchService } from "../search/search.service";
import { SharedService } from "../shared/shared.service";

import {ActiveFilter, createEmptyActiveFilter} from "../../interface/activefilter";
import { LoadedFilters } from "../../interface/loadedfilters";
import { Research } from "../../interface/research";
import { ResearchStore } from "../../store/research-store/research.store";

// most functions return this object on error, else null ?
interface SfError {
  errmsg: string;                       // the error message
}

@Injectable({
  providedIn: 'root'
})
export class SavedFiltersService { //  implements OnInit
  savedFiltersSub: Subscription = Subscription.EMPTY;
  clearSavedFiltersSub: Subscription = Subscription.EMPTY;

  private filtersLoadedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public filtersLoaded$: Observable<boolean> = this.filtersLoadedSubject.asObservable();

  public researchStore: ResearchStore;

  // loaded filters from db array -- lives in search-filters.component
  // private loadedFiltersSubject: BehaviorSubject<LoadedFilters[]> = new BehaviorSubject<LoadedFilters[]>([]);
  // loadedFilters$: Observable<LoadedFilters[]> = this.loadedFiltersSubject.asObservable();

  private loadedFiltersSubject: ReplaySubject<LoadedFilters[]> = new ReplaySubject<LoadedFilters[]>(1);
  loadedFilters$: Observable<LoadedFilters[]> = this.loadedFiltersSubject.asObservable();

  // @Input() loadedFilters: LoadedFilters[] = [
  //   //    {
  //   //      id: #,                                          // id in database
  //   //      title: '',                                      // title
  //   //      store: Object,                                  // the full store copy of the saved filter -- NOT NEEDED!
  //   //      tsModified: timestamp modified,                 // when filter was last modified (live or saved?)
  //   //    },                                                // object per loaded filter
  // ];
  filtersWereLoaded: boolean  = false;

  // @Output() savedFilterState: EventEmitter<any> = new EventEmitter();             // mechanism for search-filters.component to update active filter and update angular (ie: on filter load)

  debug_cfn: boolean = false;                              // show function call console debug info

  constructor(private azureSearchService: AzureSearchService,
              private dataService: DataService,
              private logService: LogService,
              public searchService: SearchService,
              private _researchStore: ResearchStore,
              private sharedService: SharedService,
  ) {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::constructor()`, 'background: aqua; color: black'); }

    this.researchStore = _researchStore;

    // this is where the saved filter list is loaded
    // @ts-ignore
    this.loadFilters();

    // must come before this.searchService.init_stores() otherwise subscriptions are not received
    // this.savedFiltersSub = this.searchService.savedFiltersInit.subscribe((storeKey: string) => {
    //   this.initActiveFilter( storeKey );
    // });

    // this.clearSavedFiltersSub = this.searchService.clearSavedFilters.subscribe((storeKey: string) => {
    //   this.clearFilters(storeKey);
    //   this.searchService.activeFilter = this.initActiveFilter(storeKey);
    // });

    // this.initActiveFilter(Constants.SavedFiltersStore);

    // saved filters are tracked in the Research store - Constants.SavedFiltersStore activeStoreKey (as using global filters)
    // let cnt = 1;
    // this.savedFilterSubscription = this.searchService.research$.pipe(
    //   map(
    //     research => research.find(data => data.activeStoreKey === Constants.SavedFiltersStore)
    //   )
    // ).subscribe((savedFilters) => {
    //
    //   if(savedFilters) {
    //     this.savedFilters = savedFilters;
    //   }
    //
    //   cnt += 1;
    //
    // });

  }

  async loadFilters(): Promise<any> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::loadFilters()`, 'background: aqua; color: black'); }
    const _self = this;
    // return new Promise((resolve, reject) => {

    if(!this.filtersWereLoaded) {

      let _loadedFilters: LoadedFilters[] = [];

      let userFilters = await this.dataService.getFilters();

      let globalFilters = await this.dataService.getFiltersGlobal();
      // delete any filters from global you already have (ie: you are the owner of them)
      globalFilters.result.filters = globalFilters.result.filters.filter((obj2: any) => !userFilters.result.filters.some((obj1 : any) => obj1.id === obj2.id));

      // let alerts: any = await this.dataService.getAlerts();

      let alerts: any = {};
      if (!ENV.preview) {
        alerts = await this.dataService.getAlerts();
      }

      // alerts.result.items  <-- process into loadedFilters[]
      // ie:
      // {
      //     "id": "dbeb78fa-7465-4fd9-bb93-9ae4c95fd623",
      //     "name": "test2",
      //     "createdAt": "2023-11-21T18:43:22.508Z",
      //     "updatedAt": "2023-11-21T18:43:22.508Z",
      //     "type": "UserAlertDefinition",
      //     "userId": "e5978971-82ba-411b-b706-dabff4276193",
      //     "email": "lorne.chartier@enverus.com",
      //     "frequency": "m",
      //     "push": false,
      //     "globalFilters": [],
      //     "dataSources": {
      //         "intelligencePublications": {
      //             "metadata": {
      //                 "saved_filter_id": 426
      //             },
      //             "filters": [
      //                 {
      //                     "type": "List",
      //                     "value": [
      //                         "Activity Map"
      //                     ],
      //                     "logic": "all",
      //                     "exclude": false,
      //                     "definition": {
      //                         "type": "column",
      //                         "column": "series"
      //                     }
      //                 }
      //             ],
      //             "trackingColumns": [
      //                 "publishedDate"
      //             ]
      //         }
      //     }
      // }

      // let loadFilterId = 0;
      // let loadFilterSettings = null;
      userFilters.result.filters.forEach(function (filter: any) {

        const settings = JSON.parse(filter.settings);
        filter.settings = settings;  // decode json sub-object
        filter.prettyText = settings.prettyText;

        // if the filter has the 'isDefault' set to true (> 0) then make it the default
        if ((settings.hasOwnProperty('isDefault') && settings.isDefault)) {

          //   // BUG: this needs to update the appropriate Research store
          //
          //   _self.savedFilters.defaultFilterId = filter.id;
          //   _self.researchStore.updateResearchByActiveStoreKey({
          //     activeStoreKey: Constants.SavedFiltersStore,
          //     research: _self.savedFilters
          //   });

          _self.researchStore.selectResearchByActiveStoreKey(settings.collection).pipe(
            take(1)
          ).subscribe((rec: any) => {
            if (rec) {
              if(rec.defaultFilterId !== filter.id) {
                rec.defaultFilterId = filter.id;
                _self.researchStore.updateResearchByActiveStoreKey({
                  activeStoreKey: settings.collection,
                  research: rec
                });
              }
            }
          });

          // BUG: need to update per pane once the nav components are loaded!

          // DO NOT AUTO-LOAD DEFAULT FILTER HERE!
          // _self.loadFilter(filter.settings.collection, filter.id);
          // _self.sharedService.updateDefaultFilterId(filter.settings.collection, filter.id);

        }

        // if the filter has an associated alert then add it to the filter object
        if (alerts.hasOwnProperty('result')) {
          alerts.result.items.forEach(function (alert: any) {
            if(alert && alert.hasOwnProperty('dataSources') &&
              alert.dataSources.hasOwnProperty('intelligencePublications') &&
              alert.dataSources.intelligencePublications.hasOwnProperty('metadata') &&
              alert.dataSources.intelligencePublications.metadata.hasOwnProperty('saved_filter_id') )
            {
              if (alert.dataSources.intelligencePublications.metadata.saved_filter_id === filter.id) {
                filter.alert = alert;
              }
            }
          });
        }

        // make sure old incompatible filters (collection == 'Research' for instance aren't added as they will break the site
        if( (reportsStores.indexOf(filter.settings.collection) >= 0) || (livefeedStores.indexOf(filter.settings.collection) >= 0) ) {
          _loadedFilters.push(filter);
        }

      });

      globalFilters.result.filters.forEach(function (filter: any) {
        const settings = JSON.parse(filter.settings);
        filter.settings = settings;  // decode json sub-object
        filter.prettyText = settings.prettyText;
        // make sure old incompatible filters (collection == 'Research' for instance aren't added as they will break the site
        if( (reportsStores.indexOf(filter.settings.collection) >= 0) || (livefeedStores.indexOf(filter.settings.collection) >= 0) ) {
          _loadedFilters.push(filter);
        }
      });

      this.filtersWereLoaded = true;

      this.sortAndSetFilters(_loadedFilters);

      this.filtersLoadedSubject.next(true);

      // if(retObj.loadFilterId > 0) {
      //   this.loadFilter(retObj.loadFilterSettings.collection, retObj.loadFilterId);
      // }
      // BUG: why is this necessary?
      // else {
      //   this.searchService.ss_set_collection(this.searchService.ss_active_storeKey, false, true);
      // }

    }
    // else {
    //
    //   const defaultFilterId = this.getDefaultFilterId();
    //   if(defaultFilterId > 0) {
    //     const settings = this.getDefaultFilterSettings();
    //
    //     // BUG - need collection here too!
    //     // if( reportsStores.indexOf(this.store.collection) >= 0 ) {
    //     //   this.sharedService.updateReportsLoadingSavedFilter(true);
    //     // } else {
    //     //   if( livefeedStores.indexOf(this.store.collection) >= 0 ) {
    //     //     this.sharedService.updateLivefeedLoadingSavedFilter(true);
    //     //   }
    //     // }
    //
    //     this.loadFilter(settings.collection, defaultFilterId);
    //
    //     // BUG - need collection here too!
    //     // if( reportsStores.indexOf(this.store.collection) >= 0 ) {
    //     //   this.sharedService.updateReportsLoadingSavedFilter(false);
    //     // } else {
    //     //   if( livefeedStores.indexOf(this.store.collection) >= 0 ) {
    //     //     this.sharedService.updateLivefeedLoadingSavedFilter(false);
    //     //   }
    //     // }
    //
    //   }
    //   // BUG: why is this necessary?
    //   // else {
    //   //   this.searchService.ss_set_collection(this.searchService.ss_active_storeKey, false, true);
    //   // }
    //
    // }

  }

  public initActiveFilter(storeKey: string = ''): ActiveFilter {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::initActiveFilter(${storeKey})`, 'background: aqua; color: black'); }
    let activeFilter: ActiveFilter = createEmptyActiveFilter();
    if(storeKey !== '') {
      activeFilter.collection = storeKey;
      activeFilter.facetsDiff = this.azureSearchService.get_facetsdiff(storeKey,Constants.facetsModifiedIgnoreDates || false);
      this.azureSearchService.custom_var_set(storeKey, Constants.activeFilterKey, activeFilter);
    }
    return( activeFilter );
  }

  // clear active flag on all saved filters
  public clearFilters(storeKey: string): void {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::clearFilters(${storeKey})`, 'background: aqua; color: black'); }
    // if(this.azureSearchService.test_store_exists(storeKey)) {
    //   for(let i = 0; i < this.loadedFilters.length; i++ ) {
    //     this.loadedFilters[i].active = false;
    //   }
    // }
    // if (this.azureSearchService.test_store_exists(storeKey)) {
    //   this.loadedFiltersSubject.next(this.loadedFiltersSubject.value.map(filter => ({
    //     ...filter,
    //     active: false
    //   })));
    // }
    if (this.azureSearchService.test_store_exists(storeKey)) {
      this.loadedFiltersSubject.pipe(take(1)).subscribe((filters) => {
        // const updatedFilters = filters.map((filter) => ({
        //   ...filter,
        //   active: false,
        // }));
        // const relevantStores = this.getRelevantStores(storeKey);
        const updatedFilters = filters.map((filter) => {
          //if (relevantStores.includes(filter.settings.collection)) {
          if(storeKey === filter.settings.collection) {
            return { ...filter, active: false };
          } else {
            return filter;
          }
        });
        this.loadedFiltersSubject.next(updatedFilters);
      });
    }
    // this.azureSearchService.custom_var_remove( storeKey, Constants.activeFilterKey);
  }

  public setActiveFilter(storeKey: string, id: number): void {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::setActiveFilter(${storeKey}, id ${id})`, 'background: aqua; color: black'); }
    const loadIndex$ = this.loadedFiltersSubject.pipe(take(1));
    loadIndex$.subscribe((filters: LoadedFilters[]) => {
      const relevantStores = this.getRelevantStores(storeKey);
      const loadIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
      if (loadIndex >= 0) {
        // const updatedFilters = filters.map((filter: LoadedFilters) => ({
        //   ...filter,
        //   // active: loadIndex === i
        //   // active: (filter.id === id) && relevantStores.includes(filter.settings.collection)
        //   active: filter.id === id ? relevantStores.includes(filter.settings.collection) : filter.active
        // }));
        const updatedFilters = filters.map((filter: LoadedFilters) => {
          if (filter.id === id) {

            // filter.settings.id = filter.id;
            // filter.settings.filterModified = false;
            // filter.settings.titleOriginal = filter.settings.title;
            // filter.settings.active = true;
            //
            // if( reportsStores.indexOf(storeKey) >= 0 ) {
            //   this.sharedService.updateReportsActiveFilter(filter.settings);
            // } else {
            //   if( livefeedStores.indexOf(storeKey) >= 0 ) {
            //     this.sharedService.updateLivefeedActiveFilter(filter.settings);
            //   }
            // }

            return { ...filter, active: true };
            // } else if (relevantStores.includes(filter.settings.collection)) {
          } else if (storeKey === filter.settings.collection) {
            return { ...filter, active: false };
          } else {
            return filter;
          }
        });
        this.loadedFiltersSubject.next(updatedFilters);
      }
    });
  }

  public getActiveFilterId(storeKey: string): number {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getActiveFilterId(${storeKey})`, 'background: aqua; color: black'); }
    let id = 0;
    const loadIndex$ = this.loadedFiltersSubject.pipe(take(1));
    loadIndex$.subscribe((filters: LoadedFilters[]) => {
      filters.forEach((filter: any) => {
        if((filter.settings.collection === storeKey) && (filter.active === true)) {
          id = filter.id;
        }
      });
    });
    return id;
  }

  public getDefaultFilterId(storeKey: string): number {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getDefaultFilterId(${storeKey})`, 'background: aqua; color: black'); }
    let retId = 0;
    this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {
      for (const filter of filters) {
        //const settings = JSON.parse(filter.settings);
        if ((filter.settings.collection === storeKey) && filter.settings.hasOwnProperty('isDefault') && filter.settings.isDefault) {
          retId = filter.id;
          break;
        }
      }
    });
    return retId;
  }


  // public makeFilterForStoreActive(storeKey: string): void {
  //   if (this.azureSearchService.test_store_exists(storeKey)) {
  //     this.loadedFiltersSubject.pipe(take(1)).subscribe((filters) => {
  //       const relevantStores = this.getRelevantStores(storeKey);
  //       const updatedFilters = filters.map((filter) => {
  //         if (storeKey === filter.settings.collection) {
  //           return { ...filter, active: true };
  //         } else {
  //           if (relevantStores.includes(filter.settings.collection)) {
  //             return {...filter, active: false};
  //           } else {
  //             return filter;
  //           }
  //         }
  //       });
  //       this.loadedFiltersSubject.next(updatedFilters);
  //     });
  //   }
  // }

  async saveFilter(storeKey: string, activeFilter: any, q: string, isDefault: boolean = false): Promise<any> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::save_filter(${storeKey},${JSON.stringify(activeFilter)})`, 'background: aqua; color: black'); }
    const _self = this;

    // const _self = this;
    //   const filters = this.searchService.selectedFacets2Json();

    this.azureSearchService.diff_facets(storeKey);
    activeFilter.collection = storeKey;
    activeFilter.facetsDiff = this.azureSearchService.get_facetsdiff(storeKey, Constants.facetsModifiedIgnoreDates || !activeFilter.dateRangeEdited);
    activeFilter.prettyText = this.azureSearchService.get_facetsdiff_pretty_text( storeKey, ' | ', '\n' );  // this.searchService.ss_active_storeKey
    activeFilter.isDefault = isDefault;
    activeFilter.filterModified = false;
    activeFilter.q = q;

    // console.log(JSON.stringify(activeFilter,null,2));

    let loadedFilter = this.getLoadedFilter(activeFilter.id);

    // if there is a prism alert we must update it
    if(loadedFilter && loadedFilter.alert) {

      const alertId: string = loadedFilter.alert.id;

      // console.log(JSON.stringify(activeFilter,null,2));

      let selectedOption = loadedFilter.alert.frequency;
      let notificationObj = this.FilterToNotification(activeFilter, selectedOption, activeFilter.facetsDiff);  // THIS IS WHAT IS FAILING ON PROD!

      delete notificationObj.createdByApplication;
      delete notificationObj.frequency;
      delete notificationObj.globalFilters;
      delete notificationObj.push;
      delete notificationObj.type;

      this.dataService.updateAlert(alertId, notificationObj).then((result: any) => {
        this.addOrUpdateFilterAlert(activeFilter.id, result.result);
      }, err => {
        // this.notificationService.open('An error occurred updating the email alert.', '', 5000, 'success');
        //this.logService.log(event: string, message: string, level: string, options: any);  // TODO
      });

    }

    // so where do we get id and title from?
    await this.dataService.saveFilter(activeFilter.id, activeFilter.title, activeFilter).then(result => {

      // console.log('saveFilter', JSON.stringify(result, null, 2));

      const profile = JSON.parse(sessionStorage.getItem('profile') || '{}');

      activeFilter.id = result.result.id;
      activeFilter.titleOriginal = activeFilter.title;
      activeFilter.qOriginal = activeFilter.q;
      activeFilter.active = true;
      activeFilter.userId = profile.user_id;

      // this.logService.log('save_filter', 'saved_filters', 'info', null).subscribe();
      // this.searchService.setCollection(this.searchService.storeState[this.searchService.activeState].saveFilter.filterJson.Preferences.collection);

      this.clearFilters( storeKey );
      this.azureSearchService.custom_var_set( storeKey, Constants.activeFilterKey, activeFilter );  // this.searchService.ss_active_storeKey

      // update loadedFilters
      //const saveIndex = this.loadedFilters.findIndex((x: any) => x.id === activeFilter.id);
      //const saveIndex = this.loadedFiltersSubject.value.findIndex((x: LoadedFilters) => x.id === activeFilter.id);
      // const saveIndex = this.loadedFiltersSubject.pipe(take(1)).value.findIndex((x: LoadedFilters) => x.id === activeFilter.id);

      // const saveIndex = this.loadedFiltersSubject.pipe(take(1), toArray()).toPromise()
      //   .then((filters: LoadedFilters[]) => filters.findIndex((x: LoadedFilters) => x.id === activeFilter.id))
      //   .catch((error) => {
      //     // Handle the error, if needed.
      //     console.error(error);
      //     return -1; // or any default value to handle the case when the observable throws an error.
      //   });

      let saveIndex: number = 0;
      const saveIndex$ = this.loadedFiltersSubject.pipe(take(1));
      saveIndex$.subscribe((filters: LoadedFilters[]) => {
        saveIndex = filters.findIndex((x: LoadedFilters) => x.id === activeFilter.id);

        // if new filter, append it
        if (saveIndex === -1) {
          const filterData: LoadedFilters = {
            active: activeFilter.active,
            id: activeFilter.id,
            prettyText: activeFilter.prettyText,  // BUG?
            //settings: JSON.stringify(activeFilter),
            settings: activeFilter,
            title: activeFilter.title,
            tsModified: Date.now(),
            userId: profile.user_id
          };
          // this.loadedFiltersSubject.next([...this.loadedFiltersSubject.value, filterData]);
          //this.loadedFiltersSubject.next([...filters, filterData]);
          this.sortAndSetFilters([...filters, filterData]);
        } else {
          // otherwise update it
          const updatedFilters = filters.map(filter => {
            if (filter.id === activeFilter.id) {
              return {
                ...filter,
                active: activeFilter.active,
                title: activeFilter.title,
                titleOriginal: activeFilter.title,
                //settings: JSON.stringify(activeFilter),
                settings: activeFilter,
                tsModified: Date.now()
              };
            }
            return filter;
          });

          // console.log(updatedFilters);

          // this.loadedFiltersSubject.next(updatedFilters);
          this.sortAndSetFilters(updatedFilters);
        }

      });

      if( reportsStores.indexOf(storeKey) >= 0 ) {
        this.sharedService.updateReportsActiveFilter(activeFilter);
      } else {
        if( livefeedStores.indexOf(storeKey) >= 0 ) {
          this.sharedService.updateLivefeedActiveFilter(activeFilter);
        }
      }

      // // if new filter append it
      // if (saveIndex > 0) {
      //   this.loadedFilters[saveIndex] = JSON.parse( JSON.stringify ( activeFilter ) );
      //   // otherwise update it
      // } else {
      //   const filterData = {
      //     active: activeFilter.active,
      //     id: activeFilter.id,
      //     prettyText: activeFilter.prettyText,
      //     settings: JSON.stringify(activeFilter),
      //     title: activeFilter.title,
      //     tsModified: Date.now()
      //   };
      //   this.loadedFilters.push( filterData );
      // }

      this.logService.track("filter_saved", false,{
        filter_name: activeFilter.title,
        filter_selection: activeFilter.prettyText
      });

      this.logService.logPendo('Filter Saved', {
        title: activeFilter.title,
        filters: activeFilter.prettyText
      });

    })

    return activeFilter;
  }

  async loadFilter(storeKey: string, id: number): Promise<SfError | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::loadFilter(${storeKey}, ${id})`, 'background: aqua; color: black'); }
    let sfError: SfError | null = null;

    // preserve current state of facets
    // let activeFilter = this.azureSearchService.custom_var_get( this.searchService.ss_active_storeKey, Constants.activeFilterKey );
    // activeFilter.facetsDiff = this.azureSearchService.get_facetsdiff( this.searchService.ss_active_storeKey, true );
    // this.azureSearchService.custom_var_set( this.searchService.ss_active_storeKey, Constants.activeFilterKey, activeFilter );

    await this.dataService.loadFilter(id).then(result => {

      // const settings = JSON.parse(result.result.filter.settings);
      // console.log('loadFilter', JSON.stringify(settings, null, 2));

      // IMPORTANT: block facets change detection while switching filters / collections
      // BUG: why is this necessary?
      // this.searchService.ignore_facets_changed_events = true;

      // recover the activeFilter
      let activeFilter: ActiveFilter = JSON.parse(result.result.filter.settings);
      activeFilter.id = result.result.filter.id;
      activeFilter.filterModified = false;
      activeFilter.titleOriginal = activeFilter.title;
      activeFilter.qOriginal = activeFilter.q;
      activeFilter.active = true;
      activeFilter.userId = result.result.filter.userId;
      activeFilter.isGlobal = result.result.filter.isGlobal;

      // console.log(JSON.stringify(activeFilter, null, 2));

      // need to clear UI elements only if remaining on same tab/collection
      const q: string = activeFilter && (activeFilter.q !== undefined) ? activeFilter.q : '';
      this.searchService.setQuery(activeFilter.collection, q); // BUG:  this is why keyword is getting nuked
      this.searchService.setChipContents(activeFilter.collection, '');
      this.searchService.setPage(activeFilter.collection, 1);

      // set active flag on the matching filter in this.loadedFilters
      // const loadIndex = this.loadedFilters.findIndex((x: any) => x.id === id);
      // if (loadIndex > 0) {
      //   for(let i = 0; i < this.loadedFilters.length; i++ ) {
      //     this.loadedFilters[i].active = ( loadIndex == i );
      //   }
      // }

      // set active flag on the matching filter in this.loadedFilters
      // const loadIndex = this.loadedFiltersSubject.value.findIndex((x: LoadedFilters) => x.id === id);
      // if (loadIndex >= 0) {
      //   const updatedFilters = this.loadedFiltersSubject.value.map((filter, i) => ({
      //     ...filter,
      //     active: loadIndex === i
      //   }));
      //   this.loadedFiltersSubject.next(updatedFilters);
      // }

      // set active flag on the matching filter in this.loadedFilters
      const loadIndex$ = this.loadedFiltersSubject.pipe(take(1));
      loadIndex$.subscribe((filters: LoadedFilters[]) => {
        const relevantStores = this.getRelevantStores(storeKey);
        const loadIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
        if (loadIndex >= 0) {
          // const updatedFilters = filters.map((filter: LoadedFilters) => ({
          //   ...filter,
          //   // active: loadIndex === i
          //   // active: (filter.id === id) && relevantStores.includes(filter.settings.collection)
          //   active: filter.id === id ? relevantStores.includes(filter.settings.collection) : filter.active
          // }));
          const updatedFilters = filters.map((filter: LoadedFilters) => {
            if (filter.id === id) {
              return { ...filter, active: true };
              // } else if (relevantStores.includes(filter.settings.collection)) {
            } else if (activeFilter.collection === filter.settings.collection) {
              return { ...filter, active: false };
            } else {
              return filter;
            }
          });
          // console.log(updatedFilters);
          this.loadedFiltersSubject.next(updatedFilters);
        }
      });

      // BUG: should no longer be needed
      // this.searchService.ss_active_storeKey = activeFilter.collection;

      if( reportsStores.indexOf(storeKey) >= 0 ) {
        this.sharedService.updateReportsLoadingSavedFilter(true);
      } else {
        if( livefeedStores.indexOf(storeKey) >= 0 ) {
          this.sharedService.updateLivefeedLoadingSavedFilter(true);
        }
      }

      if( reportsStores.indexOf(storeKey) >= 0 ) {
        this.sharedService.updateReportsTab(activeFilter.collection);
      } else {
        if( livefeedStores.indexOf(storeKey) >= 0 ) {
          this.sharedService.updateLivefeedTab(activeFilter.collection);
        }
      }

      if( reportsStores.indexOf(storeKey) >= 0 ) {
        this.sharedService.updateReportsActiveFilter(activeFilter);
      } else {
        if( livefeedStores.indexOf(storeKey) >= 0 ) {
          this.sharedService.updateLivefeedActiveFilter(activeFilter);
        }
      }

      if( reportsStores.indexOf(storeKey) >= 0 ) {
        this.sharedService.updateReportsLoadingSavedFilter(false);
      } else {
        if( livefeedStores.indexOf(storeKey) >= 0 ) {
          this.sharedService.updateLivefeedLoadingSavedFilter(false);
        }
      }


      this.researchStore.selectResearchByActiveStoreKey(activeFilter.collection).pipe(
        take(1)
      ).subscribe(rec => {
        if (rec) {
          if(activeFilter.q) {
            rec.activeFilterId = activeFilter.id;
            rec.q = activeFilter.q;
            rec.chipContents = activeFilter.q;
          }
          // rec.transactionId = uuidv4();
          this.researchStore.updateResearchByActiveStoreKey({
            activeStoreKey: activeFilter.collection,
            research: rec
          });
        }
      });


      // set the facets to the loaded filter
      this.azureSearchService.clear_all_facets( activeFilter.collection, false );
      this.azureSearchService.set_facets_diff( activeFilter.collection, activeFilter.facetsDiff );
      this.searchService.ss_set_subscription( activeFilter.collection, '', false );

      // this.savedFilterState.emit( activeFilter );  // BUG: just update the state?


      this.azureSearchService.facetsDiff_to_facets( activeFilter.collection, true );
      this.searchService.ss_set_collection( activeFilter.collection, true );

      if( reportsStores.indexOf(storeKey) >= 0 ) {
        this.searchService.clearQueryParams(Constants.reportsSavedFiltersUrl);
        this.searchService.removeQueryParam(Constants.reportsSavedFiltersUrl);
      } else {
        if (livefeedStores.indexOf(storeKey) >= 0) {
          this.searchService.clearQueryParams(Constants.livefeedSavedFiltersUrl);
          this.searchService.removeQueryParam(Constants.livefeedSavedFiltersUrl);
        }
      }

      // BUG: why is this necessary?
      // this.searchService.ignore_facets_changed_events = false;
    });

    return sfError;
  }

  private getRelevantStores(storeKey: string): string[] {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getRelevantStores(${storeKey})`, 'background: aqua; color: black'); }
    // Choose the relevant stores based on the storeKey
    if (Constants.ReportsStores.includes(storeKey)) {
      return Constants.ReportsStores;
    } else if (Constants.LivefeedStores.includes(storeKey)) {
      return Constants.LivefeedStores;
    } else {
      // Default to an empty array if the storeKey is not found in any of the stores
      return [];
    }
  }

  // applyStoredFilter(storeKey: string): SfError | null {
  //   if(this.debug_cfn) { console.log(`%c savedfilters.service::applyStoredFilter(${storeKey})`, 'background: aqua; color: black'); }
  //   let sfError: SfError | null = null;
  //
  //   // preserve current state of facets
  //   // let activeFilter = this.azureSearchService.custom_var_get( this.searchService.ss_active_storeKey, Constants.activeFilterKey );
  //   // activeFilter.facetsDiff = this.azureSearchService.get_facetsdiff( this.searchService.ss_active_storeKey, true );
  //   // this.azureSearchService.custom_var_set( this.searchService.ss_active_storeKey, Constants.activeFilterKey, activeFilter );
  //
  //   if(this.azureSearchService.test_store_exists(storeKey)) {
  //
  //     // this.azureSearchService.dump_stores();
  //
  //     // IMPORTANT: block facets change detection while switching filters / collections
  //     // this.searchService.ignore_facets_changed_events = true;  // BUG
  //
  //     // this.searchService.ss_active_storeKey = storeKey;  // BUG - needed?
  //
  //     // old facets
  //     let oldFacets = this.azureSearchService.get_facetsdiff(storeKey, false);
  //
  //     let activeFilter = this.azureSearchService.custom_var_get(storeKey, Constants.activeFilterKey);
  //     if(!activeFilter) {
  //       // activeFilter = this.initActiveFilter( storeKey );  // BUG - is this clearing the saved filter?
  //       // this.azureSearchService.custom_var_set(storeKey, Constants.activeFilterKey, activeFilter);
  //     }
  //     //this.azureSearchService.dump_stores( storeKey );
  //
  //     // set active flag on the matching filter in this.loadedFilters, else make all inactive
  //     // const loadIndex = this.loadedFilters.findIndex((x: any) => x.id === activeFilter.id);
  //     // if (loadIndex > 0) {
  //     //   for (let i = 0; i < this.loadedFilters.length; i++) {
  //     //     this.loadedFilters[i].active = (loadIndex == i);
  //     //   }
  //     // } else {
  //     //   this.clearFilters( storeKey );
  //     // }
  //
  //     // set active flag on the matching filter in this.loadedFilters, else make all inactive
  //     // const loadIndex = this.loadedFiltersSubject.value.findIndex((x: LoadedFilters) => x.id === activeFilter.id);
  //     // if (loadIndex >= 0) {
  //     //   const updatedFilters = this.loadedFiltersSubject.value.map((filter, i) => ({
  //     //     ...filter,
  //     //     active: loadIndex === i
  //     //   }));
  //     //   this.loadedFiltersSubject.next(updatedFilters);
  //     // } else {
  //     //   this.clearFilters(storeKey);
  //     // }
  //
  //     // set active flag on the matching filter in this.loadedFilters, else make all inactive
  //     const loadIndex$ = this.loadedFiltersSubject.pipe(take(1));
  //     loadIndex$.subscribe((filters: LoadedFilters[]) => {
  //       const loadIndex = filters.findIndex((x: LoadedFilters) => x.id === activeFilter.id);
  //       if (loadIndex >= 0) {
  //         const updatedFilters = filters.map((filter, i) => ({
  //           ...filter,
  //           active: loadIndex === i
  //         }));
  //         this.loadedFiltersSubject.next(updatedFilters);
  //       } else {
  //         this.clearFilters(storeKey);
  //       }
  //     });
  //
  //     // set the facets to the loaded filter
  //     this.azureSearchService.clear_all_facets( storeKey, false );
  //     //this.azureSearchService.set_facets_diff( storeKey, activeFilter.facetsDiff ); // without this no facets are loaded!, but also makes filter not edited!
  //     this.azureSearchService.set_facets_diff( storeKey, oldFacets ); // without this no facets are loaded!, but also makes filter not edited!
  //     this.searchService.ss_set_subscription( storeKey, '', false );
  //     // this.savedFilterState.emit( activeFilter );  // BUG: updates activeFilter and UI (this is reason change detection fails on restored activeFilter)  // BUG
  //     this.azureSearchService.facetsDiff_to_facets( storeKey, true );
  //     this.searchService.ss_set_collection( storeKey, true);
  //
  //     //this.searchService.ignore_facets_changed_events = false;  // BUG
  //
  //   } else {
  //     sfError = {errmsg: `applyStoredFilter() Store key "${storeKey}" is invalid.`} as SfError;
  //   }
  //
  //   return sfError;
  // }


  async deleteFilter(storeKey: string, id: number): Promise<SfError | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::deleteFilter(${storeKey}, ${id})`, 'background: aqua; color: black'); }
    let sfError: SfError | null = null;
    const _self = this;

    const filterToDelete = this.getLoadedFilter(id);
    if(filterToDelete && filterToDelete.hasOwnProperty('alert')) {
      await this.dataService.deleteAlert(filterToDelete.alert.id);
    }

    await this.dataService.deleteFilter(id).then(result => {

      // const delIndex = this.loadedFilters.findIndex((x: any) => x.id === id);
      // if (delIndex > 0) {
      //   this.loadedFilters.splice(delIndex, 1);
      //   // this.logService.log('deleteFilter', 'saved_filters', 'info', null).subscribe();
      //
      //   // TODO: need to handle case where filter we are deleted is the active one
      //
      //   //   this.searchService.setSaveFilterId(0);
      //   //   this.clearFilter();
      //   //   this.searchService.search(this.searchState.parameters.input, 1, true);
      //   //   this.resetStateFacets();
      //   //   this.cdr.detectChanges();
      // }

      // const delIndex = this.loadedFiltersSubject.value.findIndex((x: LoadedFilters) => x.id === id);
      // if (delIndex >= 0) {
      //   const updatedFilters = this.loadedFiltersSubject.value.filter((filter, i) => i !== delIndex);
      //   this.loadedFiltersSubject.next(updatedFilters);
      // }

      const delIndex$ = this.loadedFiltersSubject.pipe(take(1));
      delIndex$.subscribe((filters: LoadedFilters[]) => {
        const delIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
        if (delIndex >= 0) {
          const updatedFilters = filters.filter((filter: LoadedFilters, i: number) => i !== delIndex);
          this.loadedFiltersSubject.next(updatedFilters);
        }
      });

    })

    return sfError;
  }

  // delete alert sub-object from loaded filter in memory
  async deleteFilterAlert(id: number): Promise<SfError | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::deleteFilterAlert(${id})`, 'background: aqua; color: black'); }
    let sfError: SfError | null = null;
    const _self = this;

    // const currentFilters = this.loadedFiltersSubject.getValue();
    // const index = currentFilters.findIndex((filter: LoadedFilters) => filter.id === id);

    const delIndex$ = this.loadedFiltersSubject.pipe(take(1));
    delIndex$.subscribe((filters: LoadedFilters[]) => {
      const delIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
      if (delIndex >= 0) {
        const updatedFilter = {...filters[delIndex]};
        delete updatedFilter.alert;
        const updatedFilters = [...filters];
        updatedFilters[delIndex] = updatedFilter;
        this.loadedFiltersSubject.next(updatedFilters);
      }
    });
    return sfError;
  }

  // add alert sub-object to loaded filter in memory
  async addOrUpdateFilterAlert(id: number, alert: any): Promise<SfError | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::addOrUpdateFilterAlert(${id})`, 'background: aqua; color: black'); }
    let sfError: SfError | null = null;
    const _self = this;

    // const currentFilters = this.loadedFiltersSubject.getValue();
    // const index = currentFilters.findIndex((filter: LoadedFilters) => filter.id === id);

    const addIndex$ = this.loadedFiltersSubject.pipe(take(1));
    addIndex$.subscribe((filters: LoadedFilters[]) => {
      const addIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
      if (addIndex >= 0) {
        const updatedFilter = {...filters[addIndex]};
        updatedFilter.alert = alert;
        const updatedFilters = [...filters];
        updatedFilters[addIndex] = updatedFilter;
        this.loadedFiltersSubject.next(updatedFilters);
      }
    });
    return sfError;
  }

  // get alert sub-object from a loaded filter in memory
  async getFilterAlert(id: number): Promise<any | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getFilterAlert(${id})`, 'background: aqua; color: black'); }
    let retAlert: any | null = null;
    const _self = this;

    // const currentFilters = this.loadedFiltersSubject.getValue();
    // const index = currentFilters.findIndex((filter: LoadedFilters) => filter.id === id);

    const getIndex$ = this.loadedFiltersSubject.pipe(take(1));
    getIndex$.subscribe((filters: LoadedFilters[]) => {
      const getIndex = filters.findIndex((x: LoadedFilters) => x.id === id);
      if (getIndex >= 0) {
        retAlert = JSON.parse( JSON.stringify( filters[getIndex].alert ) );
      }
    });
    return retAlert;
  }

  async publishFilter(id: number, isGlobal: boolean): Promise<SfError | null> {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::publishFilter(id ${id}, isGlobal ${isGlobal})`, 'background: aqua; color: black'); }
    let sfError: SfError | null = null;
    // const _self = this;

    await this.dataService.publishFilter(id, isGlobal).then(result => {

      let saveIndex: number = 0;
      const saveIndex$ = this.loadedFiltersSubject.pipe(take(1));
      saveIndex$.subscribe((filters: LoadedFilters[]) => {
        saveIndex = filters.findIndex((x: LoadedFilters) => x.id === id);

        if (saveIndex >= 0) {
          const updatedFilters = filters.map(filter => {
            if (filter.id === id) {
              return {
                ...filter,
                isGlobal: isGlobal,
                tsModified: Date.now()
              };
            }
            return filter;
          });
          // this.loadedFiltersSubject.next(updatedFilters);
          this.sortAndSetFilters(updatedFilters);
        }

      });

    })

    return sfError;
  }

  // WAS COMMENTED
  // async setFilterDefault(storeKey: string, id: number, isDefault: boolean): Promise<SfError> {
  //   if(this.debug_cfn) { console.log(`%c savedfilters.service::makeFilterDefault(${storeKey},id ${id})`, 'background: aqua; color: black'); }
  //   let sfError: SfError = null;
  //   const _self = this;
  //
  //   const updIndex = this.loadedFilters.findIndex(x => x.id === id);
  //   if (updIndex > 0) {
  //     this.loadedFilters[updIndex].isDefault = isDefault;
  //
  //     this.azureSearchService.diff_facets(storeKey);
  //     this.loadedFilters[updIndex].facetsDiff = this.azureSearchService.get_facetsdiff(storeKey, true);
  //     this.loadedFilters[updIndex].prettyText = this.azureSearchService.get_facetsdiff_pretty_text( this.searchService.ss_active_storeKey );
  //
  //     await this.dataService.saveFilter(this.loadedFilters[updIndex].id, this.loadedFilters[updIndex].title, this.loadedFilters[updIndex], isDefault).then(result => {
  //
  //       this.loadedFilters[updIndex].id = result.result.id;
  //       //this.loadedFilters[updIndex].titleOriginal = this.loadedFilters[updIndex].title;
  //       //activeFilter.filterModified = false;
  //       //activeFilter.active = true;
  //
  //     }).catch(function (error) {
  //       _self.logService.log('delete_saved_filter', 'saved_filters', 'error', {'meta.error': JSON.stringify(error)}).subscribe();
  //     });
  //
  //   }
  //
  //   return sfError;
  // }

  // async setFilterDefault(storeKey: string, id: number, isDefault: boolean): Promise<SfError | null> {
  //   if(this.debug_cfn) { console.log(`%c savedfilters.service::makeFilterDefault(${storeKey},id ${id})`, 'background: aqua; color: black'); }
  //   let sfError: SfError | null = null;
  //   const _self = this;
  //
  //   const updIndex = this.loadedFilters.findIndex((x: any) => x.id === id);
  //   if (updIndex > 0) {
  //     // console.log(`update array index ${updIndex}`);
  //     // this.loadedFilters[updIndex].isDefault = isDefault;
  //     // this.azureSearchService.diff_facets(storeKey);
  //     // this.loadedFilters[updIndex].facetsDiff = this.azureSearchService.get_facetsdiff(storeKey, true);
  //     // this.loadedFilters[updIndex].prettyText = this.azureSearchService.get_facetsdiff_pretty_text( this.searchService.ss_active_storeKey );
  //
  //     // console.log(`modified filter:`);
  //     // console.log(this.loadedFilters[updIndex]);
  //
  //   }
  //
  //   return sfError;
  // }

  // getLoadedFilter(id: number): any {
  //   const updIndex = this.loadedFilters.findIndex((x: any) => x.id === id);
  //   return updIndex > 0 ? this.loadedFilters[updIndex] : null;
  // }

  // IS THIS STILL USEFUL?
  // public getLoadedFilter(id: number): LoadedFilters | null {
  //   const updIndex = this.loadedFiltersSubject.value.findIndex((x: LoadedFilters) => x.id === id);
  //   return updIndex >= 0 ? this.loadedFiltersSubject.value[updIndex] : null;
  // }

  public getLoadedFilter(id: number): LoadedFilters | null {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getLoadedFilter(${id})`, 'background: aqua; color: black'); }
    let filter: LoadedFilters | null = null;
    this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {
      filter = filters.find((x: LoadedFilters) => x.id === id) || null;
    });
    return filter;
  }

  public getLoadedFilterByTitle(title: string): LoadedFilters | null {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getLoadedFilterByTitle(${title})`, 'background: aqua; color: black'); }
    let filter: LoadedFilters | null = null;
    this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {
      filter = filters.find((x: LoadedFilters) => x.title === title) || null;
    });
    return filter;
  }

  // public updateFilterActive(id: number, isDefault: boolean): void {
  //   // console.log('-------------------------------------------------------------------------');
  //   // console.log(`updateFilterActive(id ${id}, isDefault ${isDefault})`);
  //   // console.log(JSON.parse(JSON.stringify(this.loadedFilters)));
  //   for(let filter of this.loadedFilters) {
  //     if(filter.id === id) {
  //       // console.log(`savedfilters.service::updateFilterActive(${id},${isDefault})`);
  //       let settings = JSON.parse( filter.settings );
  //       settings.isDefault = isDefault;
  //       filter.settings = JSON.stringify( settings );
  //     }
  //   }
  // }

  // public updateFilterActive(id: number, isDefault: boolean): void {
  //   const updatedFilters = this.loadedFiltersSubject.value.map(filter => {
  //     if (filter.id === id) {
  //       const settings = JSON.parse(filter.settings);
  //       settings.isDefault = isDefault;
  //       return {
  //         ...filter,
  //         settings: JSON.stringify(settings)
  //       };
  //     }
  //     return filter;
  //   });
  //   this.loadedFiltersSubject.next(updatedFilters);
  // }

  // public updateFilterActive(id: number, isDefault: boolean): void {
  //   this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {
  //     const updatedFilters = filters.map(filter => {
  //       if (filter.id === id) {
  //         filter.settings.isDefault = isDefault;
  //         return {
  //           ...filter,
  //           settings: filter.settings
  //         };
  //       }
  //       return filter;
  //     });
  //     this.loadedFiltersSubject.next(updatedFilters);
  //   });
  // }

  public updateFilterActive(storeKey: string, id: number, isDefault: boolean): void {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::updateFilterActive(${storeKey}, ${id}, ${isDefault})`, 'background: aqua; color: black'); }

    // const activeFilter = this.getLoadedFilter(id);
    // console.log('***', activeFilter);

    // if(activeFilter !== null) {
    this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {
      // const relevantStores = this.getRelevantStores(storeKey);

      const updatedFilters = filters.map((filter) => {
        if (filter.id === id) {
          if (filter.settings.isDefault !== isDefault) {
            filter.settings.isDefault = isDefault;
            this.dataService.updateFilterSettings(id, {'isDefault': isDefault});
            // console.log(`*** isDefault=${isDefault} for ${filter.title}`);
          }
          return {
            ...filter,
            settings: filter.settings
          };
          //} else if (relevantStores.includes(filter.settings.collection)) {
        } else if (storeKey === filter.settings.collection) {
          if (filter.settings.isDefault !== false) {
            filter.settings.isDefault = false;
            this.dataService.updateFilterSettings(filter.id, {'isDefault': false});
            // console.log(`*** isDefault=false for ${filter.title}, storekey ${storeKey}, filter.settings.collection ${filter.settings.collection}`);
          }
          return {
            ...filter,
            settings: filter.settings
          };
        }
        return filter;
      });

      this.loadedFiltersSubject.next(updatedFilters);
    });
    // }
  }

  // public getDefaultFilterId(): number {
  //   let retId = 0;
  //   for(let filter of this.loadedFilters) {
  //     const settings = JSON.parse( filter.settings );
  //     if( ( settings.hasOwnProperty('isDefault') && settings.isDefault ) ) {
  //       retId = filter.id;
  //       break;
  //     }
  //   }
  //   return retId;
  // }

  // public getDefaultFilterId(): number {
  //   let retId = 0;
  //   for (const filter of this.loadedFiltersSubject.value) {
  //     const settings = JSON.parse(filter.settings);
  //     if (settings.hasOwnProperty('isDefault') && settings.isDefault) {
  //       retId = filter.id;
  //       break;
  //     }
  //   }
  //   return retId;
  // }

  // public getDefaultFilterIdByStore(storeKeys: string[]): number {
  //   let retId = 0;
  //   console.log(JSON.parse(JSON.stringify(this.loadedFilters)));
  //   //for(let filter of this.loadedFilters) {
  //   this.loadedFilters.forEach(function (filter: any) {
  //     console.log(filter);
  //     const settings = JSON.parse( filter.settings );
  //     console.log(settings);
  //     if( ( settings.hasOwnProperty('isDefault') && settings.isDefault ) ) {
  //       if(storeKeys.indexOf(settings.collection) >= 0 ) {
  //         retId = filter.id;
  //         // break;
  //       }
  //     }
  //   });
  //   return retId;
  // }

  // public getDefaultFilterSettings(): any {
  //   let retSettings = null;
  //   for(let filter of this.loadedFilters) {
  //     const settings = JSON.parse( filter.settings );
  //     if( ( settings.hasOwnProperty('isDefault') && settings.isDefault ) ) {
  //       retSettings = settings;
  //       break;
  //     }
  //   }
  //   return retSettings;
  // }

  // public getDefaultFilterSettings(): any {
  //   let retSettings = null;
  //   for (const filter of this.loadedFiltersSubject.value) {
  //     const settings = JSON.parse(filter.settings);
  //     if (settings.hasOwnProperty('isDefault') && settings.isDefault) {
  //       retSettings = settings;
  //       break;
  //     }
  //   }
  //   return retSettings;
  // }

  public getDefaultFilterSettings(): any {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getDefaultFilterSettings()`, 'background: aqua; color: black'); }
    let retSettings = null;
    this.loadedFiltersSubject.pipe(take(1)).subscribe((filters: LoadedFilters[]) => {
      for (const filter of filters) {
        //const settings = JSON.parse(filter.settings);
        if (filter.settings.hasOwnProperty('isDefault') && filter.settings.isDefault) {
          retSettings = filter.settings;
          break;
        }
      }
    });
    return retSettings;
  }

  public getDefaultFilterLoaded(storeKey: string): boolean {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::getDefaultFilterLoaded(${storeKey})`, 'background: aqua; color: black'); }
    let filterLoaded: boolean = false;
    this.researchStore.selectResearchByActiveStoreKey(storeKey).pipe(
      take(1)
    ).subscribe(rec => {
      if (rec) {
        filterLoaded = rec.defaultFilterLoaded;
      }
    });
    return filterLoaded;
  }

  public setDefaultFilterLoaded(storeKey: string): void {
    if(this.debug_cfn) { console.log(`%c savedfilters.service::setDefaultFilterLoaded(${storeKey})`, 'background: aqua; color: black'); }
    this.researchStore.selectResearchByActiveStoreKey(storeKey).pipe(
      take(1)
    ).subscribe(rec => {
      // console.log(rec);
      if (rec) {
        // console.log(JSON.parse(JSON.stringify(rec)));
        if(!rec.defaultFilterLoaded) {
          rec.defaultFilterLoaded = true;
          this.researchStore.updateResearchByActiveStoreKey({
            activeStoreKey: storeKey,
            research: rec
          });
        }
      }
    });
  }

  private sortAndSetFilters(filters: any): void {
    filters.sort(function (a: any, b: any) {
      const indexAReports: number = reportsStores.indexOf(a.settings.collection);
      const indexBReports: number = reportsStores.indexOf(b.settings.collection);
      const indexALivefeed: number = livefeedStores.indexOf(a.settings.collection);
      const indexBLivefeed: number = livefeedStores.indexOf(b.settings.collection);

      // Compare collections based on custom order
      if (indexAReports !== -1 && indexBReports !== -1) {
        // Both 'a' and 'b' are in ReportsStores
        if (indexAReports < indexBReports) {
          return -1;
        } else if (indexAReports > indexBReports) {
          return 1;
        } else {
          // Collections are the same, compare titles alphabetically
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      } else if (indexALivefeed !== -1 && indexBLivefeed !== -1) {
        // Both 'a' and 'b' are in LivefeedStores
        if (indexALivefeed < indexBLivefeed) {
          return -1;
        } else if (indexALivefeed > indexBLivefeed) {
          return 1;
        } else {
          // Collections are the same, compare titles alphabetically
          if (a.title.toLowerCase() < b.title.toLowerCase()) {
            return -1;
          } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      } else if (indexAReports !== -1 && indexBLivefeed !== -1) {
        // 'a' is in ReportsStores, 'b' is in LivefeedStores
        return -1; // ReportsStores come before LivefeedStores
      } else if (indexALivefeed !== -1 && indexBReports !== -1) {
        // 'a' is in LivefeedStores, 'b' is in ReportsStores
        return 1; // LivefeedStores come after ReportsStores
      }

      // If not found in either array, compare collections alphabetically
      if (a.settings.collection < b.settings.collection) {
        return -1;
      } else if (a.settings.collection > b.settings.collection) {
        return 1;
      } else {
        // Collections are the same, compare titles alphabetically
        if (a.title.toLowerCase() < b.title.toLowerCase()) {
          return -1;
        } else if (a.title.toLowerCase() > b.title.toLowerCase()) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    // console.log(`storing filters internally`, filters);

    this.loadedFiltersSubject.next(filters);
  }

  extractAclStrings(inputString: string, outputArray: string[]): void {
    const regex = /acl\/any\(t: t eq '([^']+?)'\)/g;
    let match;
    while ((match = regex.exec(inputString)) !== null) {
      outputArray.push(match[1]);
    }
  }

  uniqueAndSortArray(inputArray: string[]): string[] {
    const uniqueArray: string[] = Array.from(new Set(inputArray));
    uniqueArray.sort();
    return uniqueArray;
  }

  public FilterToNotification(filter: any, freq: string, facetsDiff: any): any {
    const _self = this;
    let retObj: any = null;

    if(filter && facetsDiff && (['d','w','m'].indexOf(freq.charAt(0)) >= 0) ) {

      // console.log( JSON.parse( JSON.stringify( filter )));
      // console.log( JSON.parse( JSON.stringify( facetsDiff )));

      // primary alert definition
      retObj =
        {
          "name": filter.title,
          "type": "UserAlertDefinition",
          "createdByApplication": "intelligenceVault",
          "frequency": freq.charAt(0),
          "push": false,
          "globalFilters": [],
          "dataSources": {
            "intelligencePublications": {
              "metadata": {
                filter_deeplink: filter.url,
                saved_filter_id: filter.id
              },
              "filters": [],
              "trackingColumns": [
                "publishedDate"
              ]
            }
          }
        };

      // console.log('initial', JSON.parse(JSON.stringify(retObj)));

      // add in acls
      let aclList: string[] = [];
      // filter.settings.facetsDiff.subscriptionFilters.filters.forEach(function (acl: any) {
      facetsDiff.subscriptionFilters.filters.forEach(function (acl: any) {
        if(acl.active) {
          // console.log(`add in acl ${acl.name}`);
          // aclList.push(acl.filter);
          _self.extractAclStrings(acl.filter, aclList);
        }
      });
      if(aclList.length) {
        const uniqueArray: string[] = this.uniqueAndSortArray(aclList);
        let filterObj: any =
          {
            "type": "List",
            "value": uniqueArray,
            "logical": "any",
            "exclude": false,
            "definition": {
              "type": "column",
              "column": "acl"
            }
          };
        retObj.dataSources.intelligencePublications.filters.push( filterObj );
      }

      // console.log('with acls', JSON.parse(JSON.stringify(retObj)));

      let collection: string = '';
      if(filter.hasOwnProperty('settings')) {
        collection = filter.settings.collection;
      } else {
        collection = filter.collection;
      }

      // add 'collection' filter
      if (reportsStores.indexOf(collection) >= 0) {

        let filterObj: any =
          {
            "type": "List",
            "value":  [collection === 'Reports' ? 'Intelligence' : 'Operator Profiles'],
            "logical": "all",
            "exclude": false,
            "definition": {
              "type": "column",
              "column": "collection"
            }
          };
        retObj.dataSources.intelligencePublications.filters.push( filterObj );

      } else {

        if (livefeedStores.indexOf(collection) >= 0) {
          let filterObj: any =
            {
              "type": "List",
              "value":  ["Live Feed"],
              "logical": "any",
              "exclude": false,
              "definition": {
                "type": "column",
                "column": "collection"
              }
            };
          retObj.dataSources.intelligencePublications.filters.push( filterObj );
        }

      }

      // console.log('with collection', JSON.parse(JSON.stringify(retObj)));

      // add in facets
      // filter.settings.facetsDiff.facets.forEach(function (facet: any) {
      facetsDiff.facets.forEach(function (facet: any) {
        // console.log(`facet key ${facet.key}`);
        // console.log(JSON.stringify(facet));

        let filterObj: any =
          {
            "type": "List",
            "value": [],
            "logical": facet.facetsCombineUsingAnd ? "all" : "any",
            "exclude": false,
            "definition": {
              "type": "column",
              "column": facet.key
            }
          };

        if(facet.dataType === 'string') {
          delete filterObj.logical;
        }

        facet.values.forEach(function (valueRec: any) {
          if(valueRec.selected === true) {
            filterObj.value.push( valueRec.value );
          }
        });

        retObj.dataSources.intelligencePublications.filters.push( filterObj );

      });

      // console.log('final with filters', JSON.parse(JSON.stringify(retObj)));

    }

    // console.log(JSON.stringify(retObj,null,2));
    return retObj;
  }

}
