import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {lastValueFrom, firstValueFrom} from 'rxjs';
import {environment as ENV} from '../../../environments/environment';
import {AuthService} from '@auth0/auth0-angular';
import {DataService} from '../data/data.service';
import {LogService} from '../log/log.service';
import {UserService} from '../user/user.service';
import {Constants} from '../../constants/constants';

@Injectable({
  providedIn: 'root'
})
export class IvauthService {
  authorize: any;
  profile: any;
  permissions: any;

  constructor(public router: Router, private auth: AuthService, private dataService: DataService, private logService: LogService, private userService: UserService) {
  }

  public async authorizeUser(): Promise<boolean> {
    try {
      const user = await firstValueFrom(this.dataService.authorize());
      let profile = null;
      let permissions = null;

      if (user && user.authorization && user.authorization.profile && user.authorization.auth0_permissions && user.authorization.auth0_permissions.permissions && user.authorization.auth0_permissions.permissions.length > 0) {
        profile = user.authorization.profile;
        this.userService.setUserProfile(profile);
        permissions = user.authorization.auth0_permissions;
        this.userService.setUserPermissions(permissions);
        this.logService.setProfileAndUser(profile, permissions.user);
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  public async getAccessToken(): Promise<string> {
    try {
      const authResult$ = this.auth.getAccessTokenSilently({detailedResponse: true});
      const authResult = await lastValueFrom(authResult$);
      return authResult.access_token;
    } catch (error) {
      // await this.login();
      return '';
    }
  }

  public logout() {
    sessionStorage.removeItem('pendo');
    sessionStorage.removeItem('profile');
    sessionStorage.removeItem('permissions');
    sessionStorage.removeItem(Constants.chatbotStorageKey);
    this.auth.logout();
  }
}
