<div mat-dialog-title class="title">
  Search Filters Help
</div>
<div mat-dialog-content class="content">
  <p>The Enverus Intelligence Vault now offers two different filter states to help suit your needs. Please read below to understand the key differences between the two states:</p>
  <h3>Show All Filters - Unchecked - Default - Standard Filtering</h3>
  <p>When the show all filters checkbox is <strong>unchecked</strong> this state is similar to a key word search where ALL the terms are added together with the AND clause. In this state every filter after the first one you have selected will remove options in the other filter selections that have no matching results.</p>
  <p>Use Case: You will want you use this filter state when you are trying to find very specific matching fields.</p>
  <p>Example: You want to find Research in Capital Markets written by a certain author. You would first select Research in the Report Series Field - This will remove all other filters that do not match with Research. Then you would select the Capital Markets filter in the Category field, then your preferred author. In this filter state, all of those terms must apply to the results using the AND clause.</p>
  <h3>Show All Filters - Checked - Advanced Filtering</h3>
  <p>When the show all filters checkbox is <strong>checked</strong> this state is similar to a key word search where ANY of the terms can apply together with the OR clause. In this state, all the filter options are available at all times. Selecting a zero state filter will apply that filter and bring in more results.</p>
  <p>Use Case: You will want you use this filter state when you are broadly searching for some related terms, want to explore all the filter options in real time, or have set preferred filters you would like to see together.</p>
  <p>Example A: First you would select Research as in the first example; however, now when you go to Category you can see the additional empty fields that were not available in the other state. Here you could select Capital Markets as before, but you could also select the Carbon Category. The search results will update with the terms Research, Capital Markets, OR Carbon. Note however Carbon will pull in other types of reports besides Research.</p>
  <p>Example B: In this example, let us say you have a set of preferred filters that you like to see on a regular basis but you aren't necessarily concerned about how they are related; For instance, you are primarily concerned with articles written in the Rockies and Alaska regions, but you also like to read articles by a specific author regardless if they are in those regions. You would select the Rockies and Alaska regions in the region field, then select your favorite author(s) in the authors field. This will show all results related to the Rockies and Alaska regions OR articles written by who you select in the author field.</p>
</div>
