import {inject, Inject, Injectable, makeStateKey, PLATFORM_ID} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, Router} from '@angular/router';
import {DataService} from '../services/data/data.service'; // Replace with the actual import path
import {catchError, Observable, of} from "rxjs";
import {IvauthService} from "../services/ivauth/ivauth.service";
import {take} from "rxjs/operators";

export const summaryMetadataResolver: ResolveFn<any> = (
  route: ActivatedRouteSnapshot
) => {
  const id = route.paramMap.get('docId');
  const router = inject(Router);
  const ivauth = inject(IvauthService);
  return inject(DataService).getSummaryMetadata(id).pipe(
    catchError((error) => {
      console.log(error);
      let errorPage = '/error';
      switch (error.status) {
        case 404:
          errorPage = '/404';
          break;
        case 401:
        case 403:
          errorPage = '/403';
          break;
        default:
          break;
      }
      router.navigate([errorPage], {skipLocationChange: true});
      return of(error);
    })
  );
};
